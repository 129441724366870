import React from "react";
import Dashboard from "../../pages/Dashboard/Dashboard";
import Product from "../../pages/Products/Product";
import Buyer from "../../pages/Buyer/Buyer";
import BuyerAdd from "../../pages/Buyer/BuyerAdd";
import BuyerDetail from "../../pages/Buyer/BuyerDetail";
import ProductAdd from "../../pages/Products/ProductAdd";
import ProductDetail from "../../pages/Products/ProductDetail";
import UserRequest from "../../pages/User/UserRequest";
import Incompleteusers from "../../pages/User/incompleteusers";
import UserAdd from "../../pages/User/UserAdd";
import UserDetail from "../../pages/User/UserDetail";
import Seller from "../../pages/Seller/Seller";
import BuyerOrder from "../../pages/Buyer-order/BuyerOrder";
import SellerOrder from "../../pages/Seller-order/SellerOrder";
import SellerAdd from "../../pages/Seller/SellerAdd";
import SellerDetail from "../../pages/Seller/SellerDetail";
import Orderdetail from "../../pages/Order/Orderdetail";
import Category from "../../pages/Category/Category";
import CategoryModule from "../../pages/Category/CategoryModule";
import State from "../../pages/Location/State";
import AddState from "../../pages/Location/AddState";
import AddTransport from "../../pages/Transportation/AddTransport";
import Transport from "../../pages/Transportation/Transport";
import City from "../../pages/Location/City";
import AddCity from "../../pages/Location/AddCity";
import BuyerOrderdetail from "../../pages/Buyer-order/BuyerOrderDetails";
import SellerOrderdetail from "../../pages/Seller-order/SellerOrderDetails";
import { Routes, Route } from "react-router-dom";
import ProductReqDetail from "../../pages/Products/ProductReqDetails";
import Invoice from "../../pages/Seller-order/invoice";
import BuyerInvoice from "../../pages/Buyer-order/buyerInvoice";
import Profile from "../../pages/Login/Profile";
import AddTerms from "../../pages/Add Terms/Add terms";
import Tds from "../../pages/TDS/Tds";
import POdetails from "../../pages/Seller-order/poDetails";
import WhatshappMessageSend from "../../pages/Whatshapp/WhatshappMessageSend";
import AddCompany from "../../pages/Buyer/AddCompany";

function Main() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/product" element={<Product />} />
        <Route path="/buyer" element={<Buyer />} />
        <Route path="/addcompany" element={<AddCompany />} />
        <Route path="/buyerEdit" element={<BuyerAdd />} />

        <Route path="/buyerOrder" element={<BuyerOrder />} />
        <Route path="/buyerAdd" element={<BuyerAdd />} />
        <Route path="/buyerDetail" element={<BuyerDetail />} />

        <Route path="/productDetail" element={<ProductDetail />} />
        <Route path="/productReqDetail" element={<ProductReqDetail />} />

        <Route path="/productAdd" element={<ProductAdd />} />
        <Route path="/productEdit" element={<ProductAdd />} />
        <Route path="/userRequest" element={<UserRequest />} />
        <Route path="/incomplete" element={<Incompleteusers />} />
        <Route path="/userAdd" element={<UserAdd />} />
        <Route path="/userDetail" element={<UserDetail />} />
        <Route path="/sellerOrder" element={<SellerOrder />} />
        <Route path="/sellerEdit" element={<SellerAdd />} />
        <Route path="/sellerOrderdetail" element={<SellerOrderdetail />} />
        <Route path="/seller" element={<Seller />} />
        <Route path="/podetails" element={<POdetails />} />

        <Route path="/sellerAdd" element={<SellerAdd />} />
        <Route path="/sellerDetail" element={<SellerDetail />} />

        <Route path="/orderdetail" element={<Orderdetail />} />
        <Route path="/buyerOrderdetail" element={<BuyerOrderdetail />} />

        <Route path="/category" element={<Category />} />
        <Route path="/categoryModule" element={<CategoryModule />} />
        <Route path="/categoryEdit" element={<CategoryModule />} />
        <Route path="/state" element={<State />} />
        <Route path="/addState" element={<AddState />} />
        <Route path="/transport" element={<Transport />} />
        <Route path="/addtransport" element={<AddTransport />} />
        <Route path="/city" element={<City />} />
        <Route path="/addCity" element={<AddCity />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/buyerinvoice" element={<BuyerInvoice />} />
        <Route path="/profilepage" element={<Profile />} />
        <Route path="/terms" element={<AddTerms />} />
        <Route path="/tds" element={<Tds />} />
        <Route path="/whatshappmessage" element={<WhatshappMessageSend />} />
      </Routes>
    </div>
  );
}

export default Main;
