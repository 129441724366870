import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../theme";
import { InputBase } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Typography from "@mui/material/Typography";
import {
  Box,
  Divider,
  Grid,
  Tooltip,
  Drawer,
  Pagination,
  FormLabel,
  TextField,
} from "@mui/material";
import { useForm } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { GrView } from "react-icons/gr";
import { Space } from "antd";
import { useNavigate } from "react-router";
import {
  buyerStatusChange,
  getUserRequestList,
} from "../../state/action/userAction";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";

const initialValues = {
  userName: "",
  comapnyName: "",
  email: "",
  mobile: "",
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    color: #4f46e5 !important;
    border-color: #4f46e5;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 14,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },
  "&:nth-of-type(even)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(UserName, CompanyName, Email, PhoneNumber, Status, Action) {
  return { UserName, CompanyName, Email, PhoneNumber, Status, Action };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [userList, setUserList] = useState([]);
  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [notify, setNotify] = useState({});
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [switchId, setSwitchId] = useState(null);

  const [search, setSearch] = useState("");
  const [flagName, setflagName] = useState(false);
  const [flagMobile, setflagMobile] = useState(false);
  const [flagEmail, setflagEmail] = useState(false);
  const [flagCompany, setflagCompany] = useState(false);
  const [fieldName, setFieldName] = useState("");

  const [sortFlag, setSortFlag] = useState(false);

  // const [page, setPage] = useState(1);

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    if (fieldName == "userName") {
      UserRequestList(newPage, fieldName, flagName);
    } else if (fieldName == "email") {
      UserRequestList(newPage, fieldName, flagEmail);
    } else if (fieldName == "mobile") {
      UserRequestList(newPage, fieldName, flagMobile);
    } else if (fieldName == "companyName") {
      UserRequestList(newPage, fieldName, flagCompany);
    } else {
      UserRequestList(newPage, "", "");
    }
    setPage(newPage);
  };
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  useEffect(() => {
    UserRequestList(page, "", "");
  }, [refresh, search, filterData]);

  const UserRequestList = async (page, name = "", flag) => {
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}${string}&sortBy=${name}&sortFlag=${flag}`;

    const res = await getUserRequestList(queryString);
    if (res.status === true) {
      setUserList(res.data);
      setTotalRecords(res.totalUser);
      setTotalPages(Math.ceil(res.totalUser / pagePerSize));
      setFilter(false);
    } else {
      setUserList([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
  };

  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      status: 4,
      reason: values?.reason,
    };
    const res = await buyerStatusChange(switchId, payload);

    if (res && res.status === true) {
      if (fieldName == "userName") {
        UserRequestList(page, fieldName, flagName);
      } else if (fieldName == "email") {
        UserRequestList(page, fieldName, flagEmail);
      } else if (fieldName == "mobile") {
        UserRequestList(page, fieldName, flagMobile);
      } else if (fieldName == "companyName") {
        UserRequestList(page, fieldName, flagCompany);
      } else {
        UserRequestList(page, "", "");
      }
      handleCloseSwitch();
      setRefresh(!refresh);
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const dataShorting = (name) => {
    if (name === "userName") {
      setflagName(!flagName);
      UserRequestList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }
    if (name === "email") {
      setflagEmail(!flagEmail);
      UserRequestList(page, name, !flagEmail);
    } else {
      setflagEmail(false);
      setSortFlag(!sortFlag);
    }

    if (name === "mobile") {
      setflagMobile(!flagMobile);
      UserRequestList(page, name, !flagMobile);
    } else {
      setflagMobile(false);
      setSortFlag(!sortFlag);
    }

    if (name === "companyName") {
      setflagCompany(!flagCompany);
      UserRequestList(page, name, !flagCompany);
    } else {
      setflagCompany(false);
      setSortFlag(!sortFlag);
    }
    setFieldName(name);
  };

  return (
    <>
      <Box sx={{ backgroundColor: "rgb(241, 245, 249)" }}>
        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                User Request
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box
                sx={{
                  display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                  marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" },
                }}
                justifyContent="flex-end"
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "0px", sm: "0", md: "0", lg: "0" } }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", backgroundColor: "rgb(241, 245, 249)" }}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              border: 1,
              borderColor: "#eaeaea",
              maxHeight: "485px",
              overflow: "scroll",
            }}
          >
            <TableContainer component={Paper} sx={{ boxShadow: "0" }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow hover>
                    <StyledTableCell onClick={() => dataShorting("userName")}>
                      User Name
                      {flagName ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      onClick={() => dataShorting("companyName")}
                    >
                      Company Name
                      {flagName ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>

                    <StyledTableCell onClick={() => dataShorting("email")}>
                      Email
                      {flagEmail ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell onClick={() => dataShorting("mobile")}>
                      Mobile Number
                      {flagMobile ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>

                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.map((row, index) => (
                    <StyledTableRow
                      hover
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/userDetail", {
                          state: row.userId,
                        });
                      }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.userName}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.companyName}
                      </StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>
                      <StyledTableCell>{row.mobile}</StyledTableCell>
                      <StyledTableCell>
                        {moment(row.createdAt).format("MM-DD-YYYY LT")}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Space size="middle">
                          <Tooltip placement="bottom" title="View Product">
                            <button
                              className="action-button view"
                              onClick={() => {
                                navigate("/userDetail", {
                                  state: row.userId,
                                });
                              }}
                            >
                              <GrView />
                            </button>
                          </Tooltip>
                          <Controls.Button
                            text="Accept"
                            sx={{
                              fontSize: "11px",
                              fontWeight: "500",
                              color: "#fff",
                              backgroundColor: "rgb(0,128,0)",
                              ":hover": { bgcolor: "rgb(0,128,0)" },
                              textTransform: "none",
                              borderRadius: "50px",
                              boxShadow: 0,
                            }}
                            onClick={() => {
                              navigate("/userDetail", {
                                state: row.userId,
                              });
                            }}
                          />
                          <Controls.Button
                            text="Reject"
                            sx={{
                              fontSize: "11px",
                              fontWeight: "500",
                              color: "#fff",
                              backgroundColor: "rgb(239, 83, 80)",
                              ":hover": { bgcolor: "rgb(239, 83, 80)" },
                              textTransform: "none",
                              borderRadius: "50px",
                              boxShadow: 0,
                            }}
                            onClick={(e) => {
                              handleSwitchModel();
                              setSwitchId(row.userId);
                            }}
                          />
                        </Space>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
      {userList && userList.length === 0 ? (
        <div className="noDataFound"> No Data Found</div>
      ) : (
        <Box
          display="flex"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            mx: 3,
            mt: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            }}
          >
            {" "}
            Showing{" "}
            {page * pagePerSize > totalRecords
              ? totalRecords
              : page * pagePerSize}{" "}
            of {totalRecords} Results
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>
      )}

      <Modal
        // keepMounted
        open={openSwitchModel}
        onClose={handleCloseSwitch}
      >
        <Box sx={styles}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Reason
          </Typography>

          <Grid container style={{ marginTop: "2rem" }}>
            <Box sx={{ marginTop: "-25px" }}>
              <Controls.Input
                text="Add New"
                variant="outlined"
                color="primary"
                label="Reason"
                name="reason"
                value={values.reason}
                onChange={handleInputChange}
                error={errors.reason}
                helperText={errors.reason}
                fullWidth
              />
            </Box>

            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{ backgroundColor: "#fff", color: "#000" }}
                text="Cancel"
                onClick={handleCloseSwitch}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Submit"
                onClick={() => handleChangeStatus(switchId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
        <form
          onSubmit={handleSubmit}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
              padding: "10px 20px",
            }}
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
              >
                Filter
              </Typography>

              <AiOutlineCloseCircle
                className="close-button"
                onClick={closeFilterDrawer1}
              />
            </Stack>

            <Box
              sx={{
                // marginLeft: "8px",
                marginTop: "8px",

                // marginRight: "8px",
              }}
            >
              <Divider sx={{ mt: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />
              <StyledFormLabel htmlFor="name">User Name</StyledFormLabel>

              <WhiteBorderTextField
                className="input"
                // placeholder="Enter Product Name"
                fullWidth
                id="userName"
                name="userName"
                value={values.userName}
                onChange={handleInputChange}
                sx={{ mt: 0 }}
              />
            </Box>

            <Box
              sx={{
                // marginLeft: "8px",
                marginTop: "8px",

                // marginRight: "8px",
              }}
            >
              <StyledFormLabel htmlFor="name">Company Name</StyledFormLabel>

              <WhiteBorderTextField
                className="input"
                // placeholder="Enter Product Name"
                fullWidth
                id="companyName"
                name="companyName"
                value={values.companyName}
                onChange={handleInputChange}
                sx={{ mt: 0 }}
              />
            </Box>
            <Box>
              <StyledFormLabel htmlFor="taxRate">Email</StyledFormLabel>

              <WhiteBorderTextField
                className="input"
                // placeholder="Enter Tax Rate"
                fullWidth
                id="email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
              />
              <StyledFormLabel htmlFor="taxRate">Phone Number</StyledFormLabel>

              <WhiteBorderTextField
                className="input"
                // placeholder="Enter Tax Rate"
                fullWidth
                id="mobile"
                name="mobile"
                value={values.mobile}
                onChange={handleInputChange}
              />
            </Box>
            <Box></Box>
          </Box>
          <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
            <button className="cancel-filter" onClick={closeFilterDrawer}>
              Reset
            </button>
            <button className="order-filter-button" type="submit">
              Filter
            </button>
          </Stack>
        </form>
      </Drawer>
    </>
  );
}
