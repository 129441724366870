import React, { useEffect, useState } from "react";
import {
  Typography,
  InputBase,
  Pagination,
  Tooltip,
  Divider,
  Drawer,
  Stack,
  styled,
  FormLabel,
  TableRow,
  tableCellClasses,
  TextField,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Modal,
  Slider,
  Autocomplete,
} from "@mui/material";
import Controls from "../../Component/controls/Controls";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router";
import ProductRequestList from "./ProductRequestList";
import ProductList from "./ProductList";
import {
  getProduct,
  productStatusChange,
  deleteProduct,
  getProductList,
} from "../../state/action/productAction";
import { useForm } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { GrView } from "react-icons/gr";
import { Space } from "antd";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import { getCategoryDropdown } from "../../state/action/categoryAction";
import Search from "@mui/icons-material/Search";

const initialValues = {
  productName: "",
  status: "",
  margin: "",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 14,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    color: #4f46e5 !important;
    border-color: #4f46e5;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const Product = () => {
  const [value, setValue] = useState("1");
  const [deleteValue, setDeleteValue] = useState(false);
  const [switchId, setSwitchId] = useState(null);
  const [checked, setChecked] = useState(true);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [status, setStatus] = useState();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [search, setSearch] = useState("");
  const [flagName, setflagName] = useState(false);
  const [flagPrice, setflagPrice] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState([]);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [productList, setProductList] = useState([]);
  const [sortFlag, setSortFlag] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [notify, setNotify] = useState({});
  const [fieldName, setFieldName] = useState("");
  const [range, setRange] = useState([10, 57]);
  const [categoryList, setCategoryList] = useState([]);
  const [items, setItems] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    const res = await getCategoryDropdown();
    if (res && res.status === true) {
      setCategoryList(res.data);
      // setcategoryName(res.data);
    } else {
      setCategoryList([]);
    }
  };

  const productLists = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
    { id: "3", title: "Delete" },
  ];

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("Product_tabValue",newValue);
  };

  const handleChangess = (event, newValue) => {
    setRange(newValue);
  };


  useEffect(()=>{
    const tabValue = localStorage.getItem("Product_tabValue");
    setValue(tabValue ? tabValue : "1");
  },[]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const { state } = useLocation();
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const handleChangePage = (e, value) => {
    if (fieldName == "name") {
      ProductList(value, fieldName, flagName);
    } else if (fieldName == "margin") {
      ProductList(value, fieldName, flagPrice);
    } else {
      ProductList(value, "", "");
    }
    setPage(value);
  };

  useEffect(() => {
    ProductList(page, "", "");
  }, [filterData, search, refresh]);

  const ProductList = async (page, name = "", flag) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let queryString = `?page=${page}&limit=${rowsPerPage}&search=${search}${string}&sortBy=${name}&sortFlag=${flag}`;

    const res = await getProductList(queryString);

    if (res.status === true) {
      setProductList(res.data);
      setTotalRecords(res.totalProduct);
      setTotalPages(Math.ceil(res.totalProduct / rowsPerPage));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setProductList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const dataShorting = (name) => {
    if (name === "name") {
      setflagName(!flagName);
      ProductList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    if (name === "margin") {
      setflagPrice(!flagPrice);
      ProductList(page, name, !flagPrice);
    } else {
      setflagPrice(false);
      setSortFlag(!sortFlag);
    }
    setFieldName(name);
  };

  const handledelete = () => {
    setDeleteValue(true);
  };
  const closedelete = () => {
    setDeleteValue(false);
  };

  const handleChangeStatus = async () => {
    // setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      status: checked ? 1 : 0,
    };
    const res = await productStatusChange(switchId, payload);

    if (res && res.status === true) {
      if (fieldName == "name") {
        ProductList(page, fieldName, flagName);
      } else if (fieldName == "margin") {
        ProductList(page, fieldName, flagPrice);
      } else {
        ProductList(page, "", "");
      }
      handleCloseSwitch();
      // setRefresh(!refresh);
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteProduct(id);
    setOpenBreakdrop(!openBackdrop);
    if (res && res.status === true) {
      if (fieldName == "name") {
        ProductList(page, fieldName, flagName);
      } else if (fieldName == "margin") {
        ProductList(page, fieldName, flagPrice);
      } else {
        ProductList(page, "", "");
      }
      handleCloseDelete();
      // setRefresh(!refresh);
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      fromPrice: range[0],
      toPrice: range[1],
      categoryId: categoryId,
    });
    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const getSelectedItem = (data, value) => {
    if (data) {
      const rendomNum = Math.floor(100000 + Math.random() * 900000);
      const selectedItem = {
        category: data?.name,
      };

      items.push(selectedItem);
      const neItems = [...items];
      setItems(neItems);
      // setCompanyId(data?.id);
      setCategoryId(data?.id);
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={7} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Product
              </Typography>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "block",
                    sm: "flex-end",
                    md: "flex-end",
                    lg: "flex-end",
                  },
                  marginTop: { xs: "20px", md: "0", lg: "0" },
                }}
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" } }}
                >
                  <Controls.Button
                    startIcon={<AddIcon />}
                    text="Add"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                      backgroundColor: "rgb(79, 70, 229)",
                      marginLeft: {
                        xs: "0px",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                      },
                      textTransform: "none",
                    }}
                    onClick={() => {
                      navigate("/productAdd");
                    }}
                    variant="outlined"
                  />
                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "rgb(241, 245, 249)",
            }}
          >
            <Box
              sx={{
                paddingTop: "10px",
                paddingLeft: "5px",
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                border: 1,
                borderColor: "#eaeaea",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Product List"
                  value="1"
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
                <Tab
                  label="Product Pending List"
                  value="2"
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
              </TabList>
            </Box>
          </Box>
          <TabPanel value="1">
            <Box
              sx={{
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                border: 1,
                borderColor: "#eaeaea",
              }}
            >
              <div>
                <Box
                  sx={{ width: "100%", backgroundColor: "rgb(241, 245, 249)" }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      borderTopRightRadius: "20px",
                      borderTopLeftRadius: "20px",
                      border: 1,
                      borderColor: "#eaeaea",
                      maxHeight: "485px",
                      overflow: "scroll",
                    }}
                  >
                    <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell
                              onClick={() => dataShorting("name")}
                            >
                              Name
                              {flagName ? (
                                <ArrowUpwardIcon
                                  sx={{
                                    color: "rgba(107, 114, 128, .5)",
                                    fontSize: "17px",
                                    marginLeft: "3px",
                                    position: "relative",
                                    top: "3px",
                                  }}
                                />
                              ) : (
                                <ArrowDownwardIcon
                                  sx={{
                                    color: "rgba(107, 114, 128, .5)",
                                    fontSize: "17px",
                                    marginLeft: "3px",
                                    position: "relative",
                                    top: "3px",
                                  }}
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell>Category</StyledTableCell>
                            <StyledTableCell
                              align="center"
                              onClick={() => dataShorting("margin")}
                            >
                              Margin
                              {flagPrice ? (
                                <ArrowUpwardIcon
                                  sx={{
                                    color: "rgba(107, 114, 128, .5)",
                                    fontSize: "17px",
                                    marginLeft: "3px",
                                    position: "relative",
                                    top: "3px",
                                  }}
                                />
                              ) : (
                                <ArrowDownwardIcon
                                  sx={{
                                    color: "rgba(107, 114, 128, .5)",
                                    fontSize: "17px",
                                    marginLeft: "3px",
                                    position: "relative",
                                    top: "3px",
                                  }}
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell>Date</StyledTableCell>

                            <StyledTableCell align="center">
                              Active
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Action
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productList &&
                            productList.length > 0 &&
                            productList.map((row) => (
                              <StyledTableRow
                                key={row.name}
                                hover
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigate("/productDetail", {
                                    state: {
                                      product: row?.id,
                                    },
                                  });
                                }}
                              >
                                <StyledTableCell component="th" scope="row">
                                  {row.productName}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {row.categroyname}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {row.productMargin}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {moment(row.createdAt).format(
                                    "MM-DD-YYYY LT"
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <Controls.Switch
                                    checked={row.status == "1" ? true : false}
                                    onChange={(e) => {
                                      handleSwitchModel();
                                      setStatus(
                                        e.target.checked == true
                                          ? "Active"
                                          : "InActive"
                                      );
                                      setSwitchId(row.id);
                                      setChecked(e.target.checked);
                                    }}
                                    name="checkedB"
                                    color="primary"
                                    sx={{ marginLeft: "40px" }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <Space size="middle">
                                    <Tooltip
                                      placement="bottom"
                                      title="View Product"
                                    >
                                      <button
                                        className="action-button view"
                                        onClick={() => {
                                          navigate("/productDetail", {
                                            state: {
                                              product: row?.id,
                                            },
                                          });
                                        }}
                                      >
                                        {" "}
                                        <GrView />{" "}
                                      </button>
                                    </Tooltip>
                                    <Tooltip
                                      placement="bottom"
                                      title="Edit Product"
                                    >
                                      <button
                                        className="action-button edit"
                                        onClick={(e) => {
                                          navigate("/productEdit", {
                                            state: {
                                              product: row?.id,
                                            },
                                          });
                                        }}
                                      >
                                        {" "}
                                        <RiEditLine />{" "}
                                      </button>
                                    </Tooltip>
                                    <Tooltip
                                      placement="bottom"
                                      title="Delete Product"
                                    >
                                      <button
                                        className="action-button delete-btn"
                                        onClick={() => {
                                          // setId(record.id);
                                          setDeleteId(row.id);
                                          handleDeleteModel();
                                        }}
                                      >
                                        {" "}
                                        <RiDeleteBinLine />{" "}
                                      </button>
                                    </Tooltip>
                                  </Space>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
                {productList && productList.length === 0 ? (
                  <div className="noDataFound"> No Data Found</div>
                ) : (
                  <Box
                    sx={{
                      display: {
                        xs: "block",
                        sm: "flex",
                        md: "flex",
                        lg: "flex",
                      },
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginLeft: {
                        xs: "0",
                        sm: "20px",
                        md: "20px",
                        lg: "20px",
                      },
                      marginRight: {
                        xs: "0",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                      },
                      mt: 2,
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "14px",
                        marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                      }}
                    >
                      {" "}
                      Showing{" "}
                      {page * rowsPerPage > totalRecords
                        ? totalRecords
                        : page * rowsPerPage}{" "}
                      of {totalRecords} Results
                    </Box>
                    <Box>
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        sx={{
                          "& .MuiButtonBase-root ": {
                            color: "#000 !important",
                          },
                          "& .Mui-selected ": {
                            background: "#4b4b4e70 !important",
                          },
                          "& .Mui-disabled ": {
                            background: "#6c696966 !important",
                            color: "#000000 !important",
                            opacity: 1,
                          },
                        }}
                      />
                    </Box>
                  </Box>
                )}

                <Modal
                  keepMounted
                  open={openSwitchModel}
                  onClose={handleCloseSwitch}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <Box sx={style}>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#000",
                        marginBottom: "15px",
                      }}
                    >
                      Status Change
                    </Typography>
                    <label className="fontLight">
                      Are you sure change status to {status} ?
                    </label>
                    <Grid container style={{ marginTop: "2rem" }}>
                      <Grid item xs={9} className="text-end">
                        <Controls.Button
                          style={{ backgroundColor: "#fff", color: "#000" }}
                          text="Cancel"
                          onClick={handleCloseSwitch}
                        />
                      </Grid>
                      <Grid item xs={3} className="text-end">
                        <Controls.Button
                          style={{ marginRight: "7px" }}
                          text="Change"
                          onClick={() => handleChangeStatus(switchId)}
                          type="submit"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>

                <Modal
                  keepMounted
                  open={openDeleteModel}
                  onClose={handleCloseDelete}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                  <Box sx={style}>
                    <label className="fontLight">
                      Are you sure to want to delete this record?
                    </label>
                    <Grid container gap={1} style={{ marginTop: "1rem" }}>
                      <Grid item xs={8} className="text-end">
                        <Controls.Button
                          text="Cancel"
                          onClick={handleCloseDelete}
                        />
                      </Grid>
                      <Grid item xs={3} className="text-end">
                        <Controls.Button
                          text="Delete"
                          style={{ backgroundColor: "red" }}
                          onClick={() => handleDelete(deleteId)}
                          type="submit"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Modal>
              </div>
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <ProductRequestList
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </TabPanel>
        </TabContext>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  // marginLeft: "8px",
                  marginTop: "8px",

                  // marginRight: "8px",
                }}
              >
                <Divider sx={{ mt: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />
                //{" "}
                <StyledFormLabel htmlFor="name">Product Name</StyledFormLabel>
                <WhiteBorderTextField
                  className="input"
                  // placeholder="Enter Product Name"
                  fullWidth
                  id="productName"
                  name="productName"
                  value={values.productName}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                />
              </Box>

              <Box>
                <StyledFormLabel htmlFor="name">Category</StyledFormLabel>

                <Autocomplete
                  id="categoryId"
                  name="categoryId"
                  className="input"
                  disablePortal
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  labelId="category-input"
                  value={selectedCategory || ""}
                  options={categoryList}
                  openOnFocus={true}
                  onChange={(event, value) => {
                    if (value) {
                      setSelectedCategory(value);
                      setCategoryId(value?.id);
                    }
                  }}
                  getOptionLabel={(option) => {
                    return option.name || "";
                  }}
                  sx={{
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
                      color: "#000",
                    },
                    width: "100%",
                    "& .MuiAutocomplete-popupIndicator": {
                      transform: "none",
                    },
                  }}
                  renderInput={(params) => (
                    <Controls.Input fullWidth {...params} />
                  )}
                  popupIcon={<Search />}
                />
              </Box>

              <Box>
                <StyledFormLabel htmlFor="taxRate">Status</StyledFormLabel>
                <Controls.SelectBox
                  className="input"
                  // placeholder="Enter HSN Code"
                  fullWidth
                  id="status"
                  name="status"
                  value={values.status}
                  onChange={handleInputChange}
                  options={productLists}
                />
              </Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button className="order-filter-button" type="submit">
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>
      </Box>
    </>
  );
};

export default Product;
