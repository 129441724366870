import axiosRequest from "../axios/axios";


export const stateDropdown = async () => {
    try {
        const response = await axiosRequest.get(`/stateDropdown`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}



//citu dropdown by id 
export const cityDropdown = async (id) => {
    try {
        const response = await axiosRequest.get(`/cityDropdown/${id}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

