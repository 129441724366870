import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Modal,
  Slide,
  IconButton,
  styled,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { Spin } from "antd";
import CloseIcon from "@mui/icons-material/Close";
import { addUserMargin, deleteCompany } from "../../state/action/buyerAction";
import Notification from "../../Utils/Notification";

import {
  buyerDetails,
  CompanyAssign,
  getCompany,
  taxStatusChange,
} from "../../state/action/userAction";
import { useForm } from "../../Utils/useForm";
import Search from "@mui/icons-material/Search";
import "./buyer.css";
import { useSnackbar } from "notistack";

const initialValues = {
  userName: "",
  comapnyName: "",
  email: "",
  mobile: "",
  margin: "",
  userMarginType: "",
  userMargin: "",
};

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BuyerDetail() {
  const [buyer, setBuyer] = useState([]);
  const [company, setCompany] = useState([]);
  const [category, setCategory] = useState([]);
  const [location, setLocation] = useState([]);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [notify, setNotify] = useState({});
  const [companyName, setCompanyName] = useState([]);
  const [companyId, setCompanyId] = useState([]);
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentImageIndex, setImageIndex] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [openmarginPopup, setOpenmarginPopup] = useState(false);
  const [buyerId, setbuyerId] = useState();
  const [deleteCompanyId, setDeleteCompanyId] = useState(null);
  const [deleteCompanyPopup, setDeleteCompanyPopup] = useState(false);

  const tdsList = [
    { id: 1, title: "TCS" },
    { id: 2, title: "TDS" },
  ];

  const handelMarginChange = async (e) => {
    try {
      if (values.userMargin == "" || values.userMargin == null) {
        setNotify({
          isOpen: true,
          message: "Please enter margin",
          type: "error",
        });
        return;
      }

      if (values.userMarginType == "" || values.userMarginType == null) {
        setNotify({
          isOpen: true,
          message: "Please select margin type",
          type: "error",
        });
        return;
      }

      if (values.userMarginType) {
        //CHAR not allowed regex but decimal allowed
        let regex = /^[0-9]*\.?[0-9]*$/;
        if (!regex.test(values.userMargin)) {
          setNotify({
            isOpen: true,
            message: "Please enter valid margin",
            type: "error",
          });
          return;
        }
      }

      setOpenBreakdrop(true);
      e.preventDefault();
      const res = await addUserMargin(buyerId, {
        userMargin: values.userMargin,
        userMarginType: values.userMarginType,
      });
      if (res) {
        setOpenmarginPopup(false);
        setOpenBreakdrop(false);
        getDetails(buyerId);
        enqueueSnackbar("Margin updated successfully", {
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        setOpenBreakdrop(false);
        setOpenmarginPopup(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    } catch (error) {
      setOpenBreakdrop(false);
      // setOpenAddressPopup(false);
    }
  };

  const EditHandler = (data) => {
    navigate(`/addcompany`, {
      state: {
        edit: true,
        data,
        buyer: state.buyer,
        companyId: data.id,
      },
    });
  };

  const openMarginBox = () => {
    setOpenmarginPopup(true);
  };
  const closeMarginBox = () => {
    setOpenmarginPopup(false);
  };

  const handleDeleteCompanyPopup = (id) => {
    setDeleteCompanyId(id);
    setDeleteCompanyPopup(true)

  }

  const closeDeleteBox = () => {
    setDeleteCompanyPopup(false);
  };

  const handleClickOpen = (index) => {
    setImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const id = state;
  const { enqueueSnackbar } = useSnackbar();

  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const handleConfirmDelete = async () => {
    if (deleteCompanyId) {
      const DeletPayload = {
        buyerId: state.buyer,
      }
      const deleteRes = await deleteCompany(deleteCompanyId, DeletPayload);
      if (deleteRes && deleteRes.status) {
        getDetails();
        setDeleteCompanyPopup(false);
        setDeleteCompanyId(null);
        enqueueSnackbar(deleteRes.message || "Deleted successfully", {
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(deleteRes.message || "Failed to delete company", {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    } else {
      enqueueSnackbar("Company not selected", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }

  const getDetails = async () => {
    const res = await buyerDetails(state.buyer);

    if (res && res.status) {
      setSelectedCompany(res?.BuyerSelectedCompany);
      setBuyer(res?.data);
      setCompany(res?.data?.company);
      setCategory(res?.data?.category);
      setLocation(res.data.location);
      setValues({
        ...values,
        userMarginType: res?.data?.userMarginType,
        userMargin: res?.data?.userMargin,
      });
      refreshScreen();
    }
  };

  useEffect(() => {
    getDetails(state.buyer);
  }, [state]);

  const handleChangeStatus = async (id) => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const payload = {
      userId: buyer?.id,
      companyId: companyId,
    };
    const res = await CompanyAssign(payload);

    if (res && res.status === true) {
      handleCloseSwitch();
      getDetails(id);
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const CompanyList = async () => {
    const res = await getCompany(state?.buyer);
    if (res.status === true) {
      setCompanyName(res.data);
    } else {
      setCompanyName([]);
    }
  };

  //statelist
  useEffect(() => {
    CompanyList();
  }, []);

  const getSelectedItem = (data) => {
    if (data) {
      const selectedItem = {
        companyName: data?.companyName,
      };
      items.push(selectedItem);
      const neItems = [...items];
      setItems(neItems);
      setCompanyId(data?.id);
    }
  };

  const handleTaxStatus = async (e) => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      taxSlab: e.target.value == "" ? 0 : e.target.value,
    };
    const res = await taxStatusChange(state.buyer, payload);

    if (res && res.status === true) {
      setBuyer({
        ...buyer,
        taxSlab: e.target.value,
      });
      // navigate("/buyer");
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);
    }
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/buyer");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Buyer
          </Typography>

          <Grid item sm={6} xs={12}></Grid>
          <Grid container alignItems="top" style={{ marginBottom: "20px" }}>
            <Grid item sm={7} xs={12}>
              <Box display="flex">
                <Box>
                  <img
                    src={"../../assets/images/product-image.png"}
                    style={{ borderRadius: "5px" }}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                  >
                    {selectedCompany?.companyId}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#000",
                      marginTop: "3px",
                    }}
                  >
                    Detail
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Controls.Button
                  text="Assign Comapny"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    borderRadius: "50px",
                    border: "0",
                    color: "#fff",
                    ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                    backgroundColor: "rgb(79, 70, 229)",
                    textTransform: "none",
                    marginRight: "10px",
                  }}
                  onClick={(e) => {
                    handleSwitchModel();
                  }}
                  variant="outlined"
                />
                <Controls.Button
                  text=" Edit"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    backgroundColor: "rgb(79, 70, 229)",
                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                    textTransform: "none",
                    borderRadius: "50px",
                    boxShadow: 0,
                  }}
                  onClick={(e) => {
                    // handleClick(e, record.id);
                    // handleOpenModal("edit");
                    navigate("/buyerEdit", {
                      state: {
                        buyer: state?.buyer,
                        companyId: state?.companyId,
                      },
                    });
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
            overflow: "auto",
            height: "100%",
            scrollbarWidth: "thin",
            boxShadow: "insert 0 0 5px gray",
            borderRedius: "10px",
            background: "#f1f1f1",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderTopRightRadius: "25px",
              borderTopLeftRadius: "25px",
              border: 0,
              borderColor: "#eaeaea",
              paddingLeft: "0px",
              paddingTop: "10px",
            }}
          >
            <Box
              sx={{
                marginTop: "15px",
                marginLeft: { xs: "15px", md: "25px", lg: "25px" },
              }}
            >
              <Box
                sx={{
                  maxWidth: {
                    md: "600px",
                    lg: "900px",
                  },
                }}
              >
                <Grid container spacing={8} style={{ marginBottom: "30px" }}>
                  <Grid item sm={6} xs={12}>
                    <Typography
                      sx={{
                        marginBottom: "20px",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    >
                      Personal Detail :
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <Typography
                          sx={{
                            paddingBottom: "3px",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          User Name :
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#000000c7",
                          }}
                        >
                          {buyer?.userName}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography
                          sx={{
                            paddingBottom: "3px",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          Email :
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#000000c7",
                          }}
                        >
                          {buyer?.email}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography
                          sx={{
                            paddingBottom: "3px",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          Phone Number :
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#000000c7",
                          }}
                        >
                          {buyer?.mobile}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Grid container spacing={2}>
                      <Box
                        sx={{ marginBottom: { xs: "15px", md: "0", lg: "0" } }}
                      >
                        <Typography
                          sx={{
                            paddingBottom: "3px",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#000",
                            marginBottom: { xs: "5px", md: "10px", lg: "10px" },
                          }}
                        >
                          TDS/TCS:
                        </Typography>

                        <Controls.SelectBox
                          text="Add New"
                          className="input"
                          fullWidth
                          id="taxSlab"
                          value={
                            buyer?.taxSlab == 0 ? "" : parseInt(buyer?.taxSlab)
                          }
                          // value={""}
                          onChange={(e) => {
                            handleTaxStatus(e);
                          }}
                          color="primary"
                          options={tdsList}
                          label="None"
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            paddingBottom: "3px",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "black",
                            marginLeft: "20px",
                          }}
                        >
                          Margin :
                          {openmarginPopup ? null : (
                            <Button
                              onClick={() => {
                                setbuyerId(buyer.id);
                                openMarginBox();
                              }}
                              size="large"
                              endIcon={<EditIcon />}
                            >
                              {/* {ordersData?.remarks} */}
                            </Button>
                          )}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#000000c7",
                            marginLeft: "20px",
                          }}
                        >
                          {buyer?.userMarginType == 1 && "+"}{" "}
                          {buyer?.userMarginType == 0 && "-"}
                          {buyer?.userMargin}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  maxWidth: {
                    md: "600px",
                    lg: "950px",
                  },
                }}
              >
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  {company?.map((row, index) => {
                    return (
                      <>
                        <Grid item xs={12} md={12}>
                          <Stack flexDirection={'row'} gap={1} alignItems={'center'} marginBottom={3}>
                            <Typography
                              sx={{
                                fontSize: "20px",
                                fontWeight: "600",
                                color: "#000",
                              }}
                            >
                              Company Detail :{" "}
                            </Typography>
                            <Controls.Button
                              sx={{ minWidth: "30px" }}
                              text={<EditIcon />}
                              onClick={() => EditHandler(row)}
                            />
                            <Controls.Button
                              sx={{ minWidth: "30px" }}
                              text={<DeleteIcon />}
                              onClick={() => handleDeleteCompanyPopup(row.id)}
                            />
                          </Stack>
                          <Grid container spacing={2} marginBottom={"20px"}>
                            <Grid item md={6} xs={12}>
                              <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    sx={{
                                      paddingBottom: "3px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000",
                                    }}
                                  >
                                    Company Name :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {row?.companyName}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    sx={{
                                      paddingBottom: "3px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000",
                                    }}
                                  >
                                    Address :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {row?.address}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    sx={{
                                      paddingBottom: "3px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000",
                                    }}
                                  >
                                    GST Number :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {row?.gstNumber}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    sx={{
                                      paddingBottom: "3px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000",
                                    }}
                                  >
                                    Pan Number :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {row?.panNumber}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    sx={{
                                      paddingBottom: "3px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000",
                                    }}
                                  >
                                    State Name :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {row?.stateName}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    sx={{
                                      paddingBottom: "3px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000",
                                    }}
                                  >
                                    City Name :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {row?.cityName}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <Grid container>
                                <Grid item md={6} xs={12}>
                                  <Box
                                    display="flex"
                                    gap={3}
                                    sx={{
                                      marginBottom: {
                                        xs: "20px",
                                        md: "0px",
                                        lg: "0px",
                                      },
                                    }}
                                  >
                                    <Box>
                                      <Box>
                                        <Typography
                                          sx={{
                                            paddingBottom: "3px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            color: "#000",
                                          }}
                                        >
                                          PanImage
                                        </Typography>
                                        <img
                                          width="150px"
                                          height="150px"
                                          src={row?.panImage}
                                          style={{ borderRadius: "5px" }}
                                          onClick={(e) => {
                                            handleClickOpen(index);
                                          }}
                                          alt=""
                                          onImageIndexChange={(index) =>
                                            setImageIndex(index)
                                          }
                                        />
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Box>
                                        <Typography
                                          sx={{
                                            paddingBottom: "3px",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            color: "#000",
                                          }}
                                        >
                                          Gst Cetificate
                                        </Typography>
                                        <Box sx={{ textAlign: "end" }}>
                                          <StyledIconWrapper
                                            style={{
                                              // margin: "0",
                                              display: "flex",
                                              justifyContent: "center",
                                              background: "none",
                                              border: "none",
                                              width: "auto",
                                              height: "50px",
                                              // marginTop:""
                                            }}
                                          >
                                            <a
                                              href={row?.gstCertificate}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <Controls.Button
                                                text="PDF"
                                                sx={{
                                                  fontSize: "11px",
                                                  fontWeight: "500",
                                                  color: "#fff",
                                                  backgroundColor:
                                                    "rgb(79, 70, 229)",
                                                  ":hover": {
                                                    bgcolor: "rgb(79, 70, 229)",
                                                  },
                                                  textTransform: "none",
                                                  borderRadius: "50px",
                                                  boxShadow: 0,
                                                }}
                                              />
                                            </a>
                                          </StyledIconWrapper>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider />
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Box>

              <Box
                sx={{
                  maxWidth: {
                    md: "600px",
                    lg: "950px",
                  },
                }}
              >
                <Grid container spacing={0} style={{ marginBottom: "30px" }}>
                  <Grid item sm={6} xs={12}>
                    <Box
                      sx={{
                        marginTop: "10px",
                        marginBottom: "20px",
                        borderTopRightRadius: "20px",
                        borderTopLeftRadius: "20px",
                        border: 1,
                        borderColor: "#eaeaea",
                        maxWidth: {
                          lg: "350px",
                        },
                      }}
                    >
                      <TableContainer
                        component={Paper}
                        sx={{
                          boxShadow: 0,
                          paddingLeft: "0!important",
                        }}
                      >
                        <Table
                          aria-label="simple table"
                          sx={{ paddingLeft: "0!important" }}
                        >
                          <TableHead sx={{ paddingLeft: "0!important" }}>
                            <TableRow
                              sx={{
                                backgroundColor: "#f6f9fb",
                                paddingLeft: "0!important",
                              }}
                            >
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                State
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                City
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              backgroundColor: "#fff",
                              paddingLeft: "0!important",
                            }}
                          >
                            {location?.map((row) => {
                              return (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "none",
                                    }}
                                  >
                                    {row.stateName}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "none",
                                    }}
                                  >
                                    {row.cityName}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "30px",
                        marginBottom: "20px",
                        borderTopRightRadius: "20px",
                        borderTopLeftRadius: "20px",
                        border: 1,
                        borderColor: "#eaeaea",
                        maxWidth: {
                          lg: "250px",
                        },
                      }}
                    >
                      <TableContainer
                        component={Paper}
                        sx={{
                          boxShadow: 0,
                          paddingLeft: "0!important",
                        }}
                      >
                        <Table
                          aria-label="simple table"
                          sx={{ paddingLeft: "0!important" }}
                        >
                          <TableHead sx={{ paddingLeft: "0!important" }}>
                            <TableRow
                              sx={{
                                backgroundColor: "#f6f9fb",
                                paddingLeft: "0!important",
                              }}
                            >
                              <TableCell
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  textTransform: "none",
                                  borderColor: "rgba(107, 114, 128, .4)",
                                }}
                              >
                                Category
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody
                            sx={{
                              backgroundColor: "#fff",
                              paddingLeft: "0!important",
                            }}
                          >
                            {category?.map((row) => {
                              return (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      color: "#000",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      textTransform: "none",
                                    }}
                                  >
                                    {row?.name}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <img
              width="40%"
              height="28%"
              style={{ marginLeft: "158px" }}
              src={company[currentImageIndex]?.panImage}
              alt=""
            />
          </DialogContent>
        </Dialog>

        <Modal
          // keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Assign Company
            </Typography>
            <Controls.Button
              text="Create Company"
              onClick={() =>
                navigate("/addcompany", {
                  state: {
                    buyer: state.buyer,
                    companyId: "",
                    edit: false,
                    data: null,
                  },
                })
              }
            />
            <Grid container style={{ marginTop: "2rem", textAlign: "center" }}>
              OR
            </Grid>
            <Grid container style={{ marginTop: "2rem" }}>
              <Box sx={{ width: "100%" }}>
                <Autocomplete
                  disablePortal
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  // labelId="category-input"
                  sx={{
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
                      color: "#000",
                    },
                    width: "100%",
                    "& .MuiAutocomplete-popupIndicator": {
                      transform: "none",
                    },
                  }}
                  options={companyName}
                  openOnFocus={true}
                  onChange={(event, value) => getSelectedItem(value)}
                  getOptionLabel={(option) =>
                    option ? option.companyName : ""
                  }
                  renderInput={(params) => (
                    <Controls.Input fullWidth {...params} />
                  )}
                  popupIcon={<Search />}
                />
              </Box>

              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    marginTop: "9px",
                  }}
                  text="Cancel"
                  onClick={handleCloseSwitch}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px", marginTop: "9px" }}
                  text="Submit"
                  onClick={() => handleChangeStatus()}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={openmarginPopup} onClose={closeMarginBox}>
          {/* //create design for address edit  */}
          <Box sx={styles}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                Margin
              </Typography>
              <IconButton onClick={closeMarginBox}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Grid container style={{ marginTop: "1rem" }}>
              <Grid item xs={3} className="text-end">
                {/* <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Margin"
                  name="margin"
                  value={values.margin}
                  onChange={handleInputChange}
                  error={errors.margin}
                  helperText={errors.margin}
                  fullWidth
                /> */}
                <select
                  style={{
                    width: "100%",
                    height: "40px",
                    marginTop: "10px",
                    borderRadius: "5px",
                    border: "1px solid #eaeaea",
                    padding: "5px",
                    outline: "none",
                  }}
                  name="userMarginType"
                  value={values.userMarginType}
                  onChange={handleInputChange}
                  error={errors.userMarginType}
                >
                  <option value="">Select</option>
                  <option value="0">-</option>
                  <option value="1">+</option>
                </select>
              </Grid>
              <Grid item xs={0.5} className="text-end"></Grid>

              <Grid item xs={8.5} className="text-end">
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Margin"
                  name="userMargin"
                  value={values.userMargin}
                  onChange={handleInputChange}
                  error={errors.userMargin}
                  helperText={errors.userMargin}
                  fullWidth
                />
              </Grid>

              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    marginTop: "20px",
                  }}
                  text="Cancel"
                  onClick={closeMarginBox}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "0px", marginTop: "20px" }}
                  text="Submit"
                  onClick={(e) => handelMarginChange(e)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        {/* Delete Confirmation popup */}
        <Modal open={deleteCompanyPopup} onClose={closeDeleteBox}>
          {/* //create design for address edit  */}
          <Box sx={styles}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                Confirm Delete
              </Typography>
              <IconButton onClick={closeDeleteBox}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Grid container style={{ marginTop: "1rem" }}>
              <Typography>
                Are you sure to delete company
              </Typography>
              <Grid item xs={12} className="text-end">
                <Stack flexDirection={'row'} gap={1} alignItems={'right'} >
                  <Controls.Button
                    style={{ marginRight: "0px", marginTop: "20px" }}
                    text="Yes"
                    onClick={() => handleConfirmDelete()}
                    type="button"
                  />
                  <Controls.Button
                    style={{ marginRight: "0px", marginTop: "20px" }}
                    text="No"
                    onClick={closeDeleteBox}
                  />
                </Stack>
              </Grid>

            </Grid>
          </Box>
        </Modal>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}

export default BuyerDetail;
