import axios from "axios";
import { store } from "../store";

const axiosRequest = axios.create({
  // baseURL: "http://localhost:4301/api/v1",
  baseURL: "https://api.puripolymers.com/api/v1",
});

axiosRequest.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.token;
  config.params = config.params || {};
  config.headers["x-auth-token"] = token;
  return config;
});

export default axiosRequest;
