import { ColorModeContext, useMode } from "./theme";
import { ThemeProvider } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Layout from "./Component/Layout/Layout";
import PrivateRoute from "./Routes/PrivetRoute";
import { useDispatch } from "react-redux";
import { loadUserApi } from "./state/action/authAction";
import { useEffect } from "react";

const App = () => {
  const [theme, colorMode] = useMode();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname.substr(1);

  useEffect(() => {
    dispatch(loadUserApi());
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/SignUp" element={<SignUp />} />
          <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          ></Route>
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>

    // <ColorModeContext.Provider value={colorMode}>
    //   <ThemeProvider theme={theme}>
    //     <Routes>
    //       <Route path="/login" element={<Login />} />
    //     </Routes>

    //     <CssBaseline />
    //     <MyProSidebarProvider>
    //       <div style={{ height: "100%", width: "100%" }}>
    //         <main>
    //           <Topbar />
    //           <Routes>
    //             <Route path="/" element={<Dashboard />} />
    //             <Route path="/product" element={<Product />} />
    //             <Route path="/UserModule" element={<UserModule />} />
    //             <Route path="/productAdd" element={<ProductAdd />} />
    //           </Routes>
    //         </main>
    //       </div>
    //     </MyProSidebarProvider>
    //   </ThemeProvider>
    // </ColorModeContext.Provider>
  );
};

export default App;
