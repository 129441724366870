import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Slide,
  styled,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Controls from "../../Component/controls/Controls";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useLocation, useNavigate } from "react-router";
import {
  getProduct,
  productDetails,
  productReqDetails,
} from "../../state/action/productAction";
import PDFViewer from "pdf-viewer-reactjs";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ProductReqDetail() {
  const [value, setValue] = useState("1");
  const [product, setProduct] = useState([]);
  const [document, setDocument] = useState([]);
  const [currentImageIndex, setImageIndex] = useState(0);

  const [image, setImage] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (index) => {
    setImageIndex(index);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const id = state;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const getDetails = async (id) => {
    const res = await productReqDetails(id);
    if (res && res.status) {
      setProduct(res?.data);
      setDocument(res?.data?.productDocuments);
      setImage(res?.data?.productImages);
    }
  };
  useEffect(() => {
    getDetails(id);
  }, [id]);

  const rows = [
    createData(
      "Dollie Bullock",
      "abbott@withinpixels.com",
      "+1-202-555-0175",
      "Saois"
    ),
  ];

  return (
    <>
      <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/product");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Product
          </Typography>
          <Grid container alignItems="top" style={{ marginBottom: "20px" }}>
            <Grid item sm={12} xs={12}>
              <Box display="flex">
                <Box>
                  <img
                    src={"../../assets/images/product-image.png"}
                    style={{ borderRadius: "5px" }}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                  >
                    Product Detail
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#000",
                      marginTop: "3px",
                    }}
                  >
                    Detail
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              border: 1,
              borderColor: "#eaeaea",
              paddingLeft: "0px",
              paddingTop: "10px",
            }}
          >
            <Box sx={{ marginLeft: "20px", marginTop: "10px" }}>
              <Grid container spacing={8}>
                <Grid item sm={6} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item sm={4} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Product Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {product?.productName}
                      </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Category :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {product?.categoryName}
                      </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Seller Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {product?.sellerName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Box
                display="flex"
                gap={3}
                sx={{ marginTop: "35px", marginBottom: "30px" }}
              ></Box>
            </Box>
          </Box>
        </Box>{" "}
        <Grid
          container
          spacing={0}
          style={{ marginBottom: "30px", marginLeft: "20px" }}
        >
          <Grid item sm={6} xs={12}>
            <Box display="flex" gap={3} sx={{ marginTop: "35px" }}>
              {image?.map((row, i) => {
                return (
                  <Grid item md={6} xs={12}>
                    <Box
                      display="flex"
                      gap={3}
                      sx={{
                        marginTop: "35px",
                        marginBottom: {
                          xs: "20px",
                          md: "0px",
                          lg: "0px",
                        },
                      }}
                    >
                      <Box>
                        <Box>
                          <Typography
                            sx={{
                              paddingBottom: "3px",
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            Product Image
                          </Typography>
                          <img
                            alt=""
                            width="150px"
                            height="150px"
                            src={row?.image}
                            style={{ borderRadius: "5px" }}
                            onClick={(e) => handleClickOpen(i)}
                            onImageIndexChange={(index) => setImageIndex(index)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}

              {document?.map((row) => {
                return (
                  <Box>
                    <Box>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                          marginTop: "37px",
                        }}
                      >
                        Product Document
                      </Typography>
                      <Box sx={{ textAlign: "end" }}>
                        <StyledIconWrapper
                          style={{
                            // margin: "0",
                            display: "flex",
                            justifyContent: "center",
                            background: "none",
                            border: "none",
                            width: "auto",
                            height: "50px",
                            // marginTop:""
                          }}
                        >
                          <a href={row?.image} target="_blank" rel="noreferrer">
                            <Controls.Button
                              text="PDF"
                              sx={{
                                fontSize: "11px",
                                fontWeight: "500",
                                color: "#fff",
                                backgroundColor: "rgb(79, 70, 229)",
                                ":hover": {
                                  bgcolor: "rgb(79, 70, 229)",
                                },
                                textTransform: "none",
                                borderRadius: "50px",
                                boxShadow: 0,
                              }}
                            />
                          </a>
                        </StyledIconWrapper>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <img
                  width="40%"
                  height="28%"
                  style={{ marginLeft: "158px" }}
                  src={image[currentImageIndex]?.image}
                  // style={{ borderRadius: "5px" }}
                  alt=""
                  // onClick={handleClickOpen}
                />
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ProductReqDetail;
