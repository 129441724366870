import axiosRequest from "../axios/axios";

//get State List
export const getStateList = async () => {
  try {
    const response = await axiosRequest.get(`/stateDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//get City List
export const getCityList = async (id) => {
  try {
    const response = await axiosRequest.get(`/CityDropdown/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getCityLists = async (id) => {
  try {
    const response = await axiosRequest.get(`/getAllCity`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//get Transport Details
export const getTransport = async (id) => {
  try {
    const response = await axiosRequest.get(`/addTransportationDetails/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateTransport = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/updateTransportation/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// add Transport

export const addTransport = async (transport) => {
  try {
    const response = await axiosRequest.post(`/addTransportation`, transport);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//  Transport List

export const getTransportList = async (queryString) => {
  try {
    const response = await axiosRequest.get(
      `/getTransportationList${queryString}`
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//transport status change
export const transportStatusChange = async (id, status) => {
  try {
    const response = await axiosRequest.post(
      `/transportationStatus/${id}`,
      status
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteTransport = async (id) => {
  try {
    const response = await axiosRequest.delete(`/deleteTransportation/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//get State List
export const getStateUserList = async () => {
  try {
    const response = await axiosRequest.get(`/userStateDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//get City List
export const getCityUserList = async (id) => {
  try {
    const response = await axiosRequest.get(`/userCityDropdown/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCityUserLists = async (id) => {
  try {
    const response = await axiosRequest.get(`/getAllCity`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// State and city API

//Add State

// add Transport

export const addState = async (payload) => {
  try {
    const response = await axiosRequest.post(`/addState`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// State List
export const getStateLocationList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/statelist${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// State Status Chnage

//transport status change
export const stateLocationStatus = async (id, status) => {
  try {
    const response = await axiosRequest.post(
      `/stateStatus/${id}`,
      status
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// Update state

export const updateStateLocation = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/updateState/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// Delete State

export const deleteState = async (id) => {
  try {
    const response = await axiosRequest.delete(`/deleteState/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// City list by state

export const getCityLocationList = async (id, queryString) => {
  try {
    const response = await axiosRequest.get(`/citylist/${id}${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add City by state Id

export const addCityByState = async (payload) => {
  try {
    const response = await axiosRequest.post(`/addCity`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//transport City status change
export const cityLocationStatus = async (id, status) => {
  try {
    const response = await axiosRequest.post(
      `/cityStatus/${id}`,
      status
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// Update state

export const updateCityLocation = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/updateCity/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// Delete City

export const deleteCityLocation = async (id) => {
  try {
    const response = await axiosRequest.delete(`/deleteCity/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};