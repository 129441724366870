import {
  Button,
  Chip,
  FormLabel,
  Grid,
  Modal,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import React, { useState, useEffect } from "react";
import Controls from "../../Component/controls/Controls";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  getOrderList,
  OrderStatusChange,
  OrderStatusChanges,
  OrderReject
} from "../../state/action/orderAction";
import { useForm } from "../../Utils/useForm";
import { useNavigate } from "react-router";
import moment from "moment";
import { GrView } from "react-icons/gr";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useSnackbar } from "notistack";


const initialValues = {
  productName: "",
  categoryId: "",
  fromquantity: "",
  toquantity: "",
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 14,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },
  "&:nth-of-type(even)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(
  Productname,
  companyname,
  quantity,
  price,
  OrderStatus,
  action
) {
  return { Productname, companyname, quantity, price, OrderStatus, action };
}


const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const BuyerorderPendingList = (props) => {
  const { data, dataShorting, flagName, flagCompanyName, refreshScreen } =
    props;
  const [deleteValue, setDeleteValue] = useState(false);
  const [switchId, setSwitchId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [search, setSearch] = useState("");
  const [flagPrice, setflagPrice] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState([]);
  const [orderRequestList, setOrderRequestList] = useState([]);
  const [sortFlag, setSortFlag] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [notify, setNotify] = useState({});
  const [value, setValue] = useState("1");
  const [pagePerSize, setPagePerSize] = useState(10);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [fieldName, setFieldName] = useState("");

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    OrderList(page);
  }, [filterData, search, page, rowsPerPage]);

  const OrderList = async (page, name = "", flag) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}${string}&sortBy=${name}&sortFlag=${flag}&status=0`;

    const res = await getOrderList(queryString);

    if (res.status === true) {
      setOrderRequestList(res.data);
      setTotalRecords(res.totalOrder);
      setTotalPages(Math.ceil(res.totalOrder / rowsPerPage));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setOrderRequestList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  const orderStatus = (orderstatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Confirmed",
        backgroundColor: "green",
        color: "#ede7dd",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[orderstatus]}
        label={statusColors[orderstatus] ? statusColors[orderstatus].key : ""}
      />
    );
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleApproveStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      status: 1,
      // productMargin: values?.productMargin,
    };
    const res = await OrderStatusChanges(switchId, payload);

    if (res && res.status === true) {
      refreshScreen();
      handleCloseSwitch();
      // setNotify({
      //   isOpen: true,
      //   message: res.message || "Status changed Successfully.",
      //   type: "success",
      // });
      enqueueSnackbar(res.message || "Status changed Successfully.", {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      // setNotify({
      //   isOpen: true,
      //   message: res.message || "Something went wrong.",
      //   type: "error",
      // });
      enqueueSnackbar(res.message || "Something went wrong.", {
        variant: "error",
        autoHideDuration: 2000,
      });

    }
  };

  const handleRejectStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      status: 2,
      reason: values?.reason,
      // productMargin: values?.productMargin,
    };
    const res = await OrderReject(deleteId, payload);
    if (res.status) {
      // setNotify({
      //   isOpen: true,
      //   message: res.message || "Status changed Successfully.",
      //   type: "success",
      // });
      resetForm()
      enqueueSnackbar(res.message || "Order has been rejected successfully.", {
        variant: "success",
        autoHideDuration: 2000,
      });
      refreshScreen();
      handleCloseDelete();

    } else {
      setOpenBreakdrop(!openBackdrop);
      enqueueSnackbar(res.message || "Failed to update order", {
        variant: "error",
        autoHideDuration: 2000,
      });
      // setNotify({
      //   isOpen: true,
      //   message: res.message || "Something went wrong.",
      //   type: "error",
      // });
    }
  };

  const handleChangePage = (e, value) => {
    setPage(value);
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
          border: 1,
          borderColor: "#eaeaea",
        }}
      >
        <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell onClick={() => dataShorting("productName")}>
                  Order Number
                </StyledTableCell>
                <StyledTableCell onClick={() => dataShorting("productName")}>
                  Product Name
                  {flagName ? (
                    <ArrowUpwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                        position: "relative",
                        top: "3px",
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                        position: "relative",
                        top: "3px",
                      }}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell onClick={() => dataShorting("companyName")}>
                  Company Name
                  {flagCompanyName ? (
                    <ArrowUpwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                        position: "relative",
                        top: "3px",
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                        position: "relative",
                        top: "3px",
                      }}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell>Quantity</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      minWidth: "0",
                    }}
                  >
                    Price
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      minWidth: "0",
                    }}
                  >
                    Date
                  </Button>
                </StyledTableCell>

                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      minWidth: "0",
                    }}
                  >
                    Order Status
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      minWidth: "0",
                    }}
                  >
                    Action
                  </Button>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => (
                <StyledTableRow
                  key={row.Productname}
                  onClick={() => {
                    navigate("/buyerOrderDetail", {
                      state: row.id,
                    });
                  }}
                >
                  <StyledTableCell component="th" scope="row" style={{ fontWeight: 700 }}>
                    {row.orderNumber}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.productName}
                  </StyledTableCell>
                  <StyledTableCell>{row.companyName}</StyledTableCell>
                  <StyledTableCell>{row.quantity} MT</StyledTableCell>
                  <StyledTableCell>{row.productPrice}</StyledTableCell>
                  <StyledTableCell>
                    {moment(row.createdAt).format("MM-DD-YYYY LT")}
                  </StyledTableCell>
                  <StyledTableCell>{orderStatus(row?.status)}</StyledTableCell>
                  <StyledTableCell onClick={(e) => e.stopPropagation()}>
                    <Box display={"flex"} gap={1}>
                      <Tooltip placement="bottom" title="View Order">
                        <button
                          className="action-button view"
                          onClick={() => {
                            navigate("/buyerOrderDetail", {
                              state: row.id,
                            });
                          }}
                        >
                          <GrView />
                        </button>
                      </Tooltip>
                      <Controls.Button
                        text="Accept"
                        sx={{
                          fontSize: "11px",
                          fontWeight: "500",
                          color: "#fff",
                          backgroundColor: "rgb(0,128,0)",
                          ":hover": { bgcolor: "rgb(0,128,0)" },
                          textTransform: "none",
                          borderRadius: "50px",
                          boxShadow: 0,
                        }}
                        onClick={(e) => {
                          handleSwitchModel();
                          setSwitchId(row.id);
                        }}
                      />
                      <Controls.Button
                        text="Reject"
                        sx={{
                          fontSize: "11px",
                          fontWeight: "500",
                          color: "#fff",
                          backgroundColor: "rgb(239, 83, 80)",
                          ":hover": { bgcolor: "rgb(239, 83, 80)" },
                          textTransform: "none",
                          borderRadius: "50px",
                          boxShadow: 0,
                        }}
                        onClick={(e) => {
                          handleDeleteModel();
                          setDeleteId(row.id);
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {data && data.length === 0 ? (
        <div className="noDataFound"> No Data Found</div>
      ) : (
        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            }}
          >
            {" "}
            Showing{" "}
            {page * pagePerSize > totalRecords
              ? totalRecords
              : page * pagePerSize}{" "}
            of {totalRecords} Results
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>
      )}

      <Modal
        keepMounted
        open={openSwitchModel}
        onClose={handleCloseSwitch}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styles}>
          <label className="fontLight">
            Are you sure to Update the record.?
          </label>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseSwitch} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Approve"
                style={{ backgroundColor: "red" }}
                onClick={() => handleApproveStatus(switchId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styles}>
          <Box sx={{ marginTop: "-25px" }}>
            <Controls.Input
              text="Add New"
              variant="outlined"
              color="primary"
              label="Reason"
              name="reason"
              value={values.reason}
              onChange={handleInputChange}
              error={errors.reason}
              helperText={errors.reason}
              fullWidth
            />
          </Box>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseDelete} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Reject"
                style={{ backgroundColor: "red" }}
                onClick={() => handleRejectStatus(deleteId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default BuyerorderPendingList;
