import axiosRequest from "../axios/axios";

export const SendMessageonWhatshapp = async (id) => {
    try {
        const response = await axiosRequest.post(`/sendWhatshappMessage`);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
};

export const getSendingStatus = async (id) => {
    try {
        const response = await axiosRequest.get(`/getcounts`);
        return response.data;
    } catch (e) {
        return e.response.data;
    }
};