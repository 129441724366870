
import { Box } from "@mui/system";
import React from "react";
import TransportList from "../Transportation/TransportList";

const Transport = () => {
  return (
    <Box>
      <TransportList />
    </Box>
  );
};

export default Transport;
