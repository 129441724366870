import axiosRequest from "../axios/axios";

export const getProductList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/productList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const productDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/productDetails/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createProduct = async (product) => {
  try {
    const response = await axiosRequest.post(`/createProdct`, product);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateProduct = async (id, product) => {
  try {
    const response = await axiosRequest.post(`/updateProduct/${id}`, product);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteProduct = async (id) => {
  try {
    const response = await axiosRequest.delete(`/deleteProduct/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const productStatusChange = async (id, payload) => {
  try {
    const response = await axiosRequest.patch(`/productStatus/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getProductRequestList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/productReqList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//product approve
export const productApprove = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/requestProductApprove/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const productReqDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/requestProductDetails/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getproductDropdown = async () => {
  try {
    const response = await axiosRequest.get(`/productDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//productdelete

//product approve
export const productRemove = async (payload) => {
  try {
    const response = await axiosRequest.post(`/deleteProductImage`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
