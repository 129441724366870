import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Typography,
  InputBase,
  Pagination,
  Tooltip,
  Divider,
  Chip,
  Slider,
  Autocomplete,
} from "@mui/material";
import Controls from "../../Component/controls/Controls";
import { Drawer, FormLabel } from "@mui/material";
import { useForm } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Box, Grid } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../theme";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Space } from "antd";
import { GrView } from "react-icons/gr";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import SellerOrderPendinglist from "./SellerOrderPendinglist";
import { getSellerOrderList } from "../../state/action/orderAction";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import { getproductDropdown } from "../../state/action/productAction";
import Search from "@mui/icons-material/Search";
import SellerOrderPOlist from "./SellerpoList";
import {
  getCityList,
  getStateList,
} from "../../state/action/locationAction";

const initialValues = {
  productName: "",
  sellToQty: "",
  state: "",
  city: "",
  email: "",
  gstNumber: "",
  panNumber: "",
  mobile: "",
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 14,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },
  "&:nth-of-type(even)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

const rows = [];

export default function CustomizedTables() {
  const [search, setSearch] = useState("");
  const [flagName, setflagName] = useState(false);
  const [flagCompanyName, setflagCompanyName] = useState(false);
  const [flagPrice, setflagPrice] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [sortFlag, setSortFlag] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [value, setValue] = useState("1");
  const [pagePerSize, setPagePerSize] = useState(10);
  const [times, setTimes] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [productId, setProductId] = useState([]);
  const [productDropdown, setProductDropdown] = useState([]);
  const [itemss, setItemss] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [userLocations, setUserLocations] = useState([]);
  const [requestCount, setRequestCount] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);

  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  const [ranges, setRanges] = useState([]);
  const [range, setRange] = useState([]);

  const [fromCityList, setFromCityList] = useState([]);

  useEffect(() => {
    ProdcutList();
  }, []);

  const ProdcutList = async () => {
    const res = await getproductDropdown();
    if (res.status === true) {
      setProductDropdown(res.data);
    } else {
      setProductDropdown([]);
    }
  };

  const handleChangePage = (e, value) => {
    if (fieldName == "productName") {
      OrderList(value, fieldName, flagName);
    } else if (fieldName == "companyName") {
      OrderList(value, fieldName, flagCompanyName);
    } else if (fieldName == "quantity") {
      OrderList(value, fieldName, flagPrice);
    } else {
      OrderList(value, "", "");
    }
    setPage(value);
  };

  const handleChange = (event, newValue) => {
    console.log("")
    setSearch("")
    setValue(newValue);
    localStorage.setItem("Seller_tabValue", newValue);
    setPage(1);
  };


  useEffect(() => {
    const tabValue = localStorage.getItem("Seller_tabValue");
    setValue(tabValue ? tabValue : "1");
  }, []);

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      fromRate: ranges[0],
      toRate: ranges[1],
      product: productId,
    });
    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [delay, setDelay] = useState(180);
  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    OrderList(page, "", "");
  }, [search, filterData, value, refresh]);

  const OrderList = async (page, name = "", flag) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}${string}&sortBy=${name}&sortFlag=${flag}&status=${value == 2 ? 0 : 1
      }`;

    const res = await getSellerOrderList(queryString);
    if (res.status === true) {
      setTimes(res?.currentTime);

      setRange(res.maxPrice);
      setRequestCount(res.requestedCount);
      setTotalOrder(res.confirmCount);
      setRanges([0, res.maxPrice]);
      setOrderList(res.data);
      setTotalRecords(res.totalOrder);
      setTotalPages(Math.ceil(res.totalOrder / rowsPerPage));
      setFilter(false);
    } else {
      setOrderList([]);
      setTotalRecords(0);
      setTotalPages(0);
    }
  };

  const handleChanges = (event, newValue) => {
    setRanges(newValue);
  };

  const orderStatus = (orderstatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Confirmed",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: "Approved By Admin",
        backgroundColor: "gray",
        color: "#ede7dd",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[orderstatus]}
        label={statusColors[orderstatus] ? statusColors[orderstatus].key : ""}
      />
    );
  };

  const dataShorting = (name) => {
    if (name === "productName") {
      setflagName(!flagName);
      OrderList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    if (name === "companyName") {
      setflagCompanyName(!flagCompanyName);
      OrderList(page, name, !flagCompanyName);
    } else {
      setflagCompanyName(false);
      setSortFlag(!sortFlag);
    }

    setFieldName(name);
  };

  //for product
  const getSelectedItems = (data) => {
    if (data) {
      const rendomNum = Math.floor(100000 + Math.random() * 900000);
      const selectedItem = {
        product: data?.productName,
      };
      itemss.push(selectedItem);
      const neItems = [...itemss];
      setItemss(neItems);
      setProductId(data?.id);
    }
  };

  const handlefromCityList = async (id) => {
    const res = await getCityList(id);
    if (res.status === true) {
      setFromCityList(res.data);
    } else {
      setFromCityList([]);
    }
  };

  //statelist
  useEffect(() => {
    StateList();
  }, []);

  const StateList = async () => {
    const res = await getStateList();
    if (res.status === true) {
      setStateList(res.data);
    } else {
      setStateList([]);
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={7} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Seller Orders
              </Typography>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "block",
                    sm: "flex-end",
                    md: "flex-end",
                    lg: "flex-end",
                  },
                  marginTop: { xs: "20px", md: "0", lg: "0" },
                }}
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  startIcon={<FilterAltIcon />}
                  onClick={openFilterDrawer}
                  sx={{
                    marginLeft: "15px",
                    borderRadius: "50px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    color: "#fff",
                    ":hover": { bgcolor: "rgb(30, 41, 59)" },
                    background: "rgb(30, 41, 59)",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "none",
                  }}
                >
                  Filter
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "rgb(241, 245, 249)",
            }}
          >
            <Box
              sx={{
                paddingTop: "10px",
                paddingLeft: "5px",
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                border: 1,
                borderColor: "#eaeaea",
                overflow: "scroll",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label={`Listing Approved (${totalOrder})`}
                  value="1"
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
                <Tab
                  label={`Listing Requested (${requestCount})`}
                  value="2"
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
                <Tab
                  label={`Listing PO`}
                  value="3"
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />

              </TabList>
            </Box>
          </Box>
          <TabPanel value="1">
            <Box
              sx={{
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                border: 1,
                borderColor: "#eaeaea",
              }}
            >
              <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        onClick={() => dataShorting("productName")}
                      >
                        Product Name
                        {flagName ? (
                          <ArrowUpwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                              position: "relative",
                              top: "3px",
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                              position: "relative",
                              top: "3px",
                            }}
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell>Quantity</StyledTableCell>
                      <StyledTableCell>Remaining Quantity</StyledTableCell>

                      <StyledTableCell>Rate</StyledTableCell>

                      <StyledTableCell>
                        <Button
                          variant="text"
                          sx={{
                            ":hover": { bgcolor: "transparent" },
                            paddingLeft: "0",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          Date
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          variant="text"
                          sx={{
                            ":hover": { bgcolor: "transparent" },
                            paddingLeft: "0",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          Time
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          variant="text"
                          sx={{
                            ":hover": { bgcolor: "transparent" },
                            paddingLeft: "0",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                          }}
                        >
                          Remaining Time
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          variant="text"
                          sx={{
                            ":hover": { bgcolor: "transparent" },
                            paddingLeft: "0",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "capitalize",
                            minWidth: "0",
                          }}
                        >
                          Packing Type
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          variant="text"
                          sx={{
                            ":hover": { bgcolor: "transparent" },
                            paddingLeft: "0",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "capitalize",
                            minWidth: "0",
                          }}
                        >
                          State
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          variant="text"
                          sx={{
                            ":hover": { bgcolor: "transparent" },
                            paddingLeft: "0",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "capitalize",
                            minWidth: "0",
                          }}
                        >
                          City
                        </Button>
                      </StyledTableCell>

                      <StyledTableCell>
                        <Button
                          variant="text"
                          sx={{
                            ":hover": { bgcolor: "transparent" },
                            paddingLeft: "0",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "capitalize",
                            minWidth: "0",
                          }}
                        >
                          Status
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          variant="text"
                          sx={{
                            ":hover": { bgcolor: "transparent" },
                            paddingLeft: "0",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "capitalize",
                            minWidth: "0",
                          }}
                        >
                          Action
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderList.map((row, index) => {
                      // var startTime = moment(times);
                      // var endTime = moment(row.endDate);
                      // var duration = moment.duration(endTime.diff(startTime));
                      const now = new Date(times).getTime();
                      const futureDate = new Date(row.endDate).getTime();

                      const timeleft = futureDate - now;
                      // const hours = Math.floor(
                      //   (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                      // );

                      // const minutes = Math.floor(
                      //   (timeleft % (1000 * 60 * 60)) / (1000 * 60)
                      // );
                      const seconds = timeleft / 1000;
                      const minutess = seconds / 60;
                      const hours = minutess / 60;
                      const days = hours / 24;
                      const minutes = Math.floor(
                        (timeleft % (1000 * 60 * 60)) / (1000 * 60)
                      );

                      return (
                        <StyledTableRow
                          key={index}
                          onClick={() => {
                            // if (minutes >= 0) {
                            navigate("/sellerOrderDetail", {
                              state: {
                                id: row.id,
                                poButton: false,
                                isPO: false,
                              }
                            });
                            // }
                          }}
                          style={{
                            cursor: "pointer",
                            color:
                              hours <= 0
                                ? "rgb(182,191,209) !important"
                                : "black",
                          }}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={{
                              color:
                                minutes <= 0
                                  ? "rgb(182,191,209) !important"
                                  : "black",
                            }}
                          >
                            {row.productName}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color:
                                minutes <= 0
                                  ? "rgb(182,191,209) !important"
                                  : "black",
                            }}
                          >
                            {row.totalQty}MT
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color:
                                minutes <= 0
                                  ? "rgb(182,191,209) !important"
                                  : "black",
                            }}
                          >
                            {row.remainingQty}MT
                          </StyledTableCell>

                          <StyledTableCell
                            sx={{
                              color:
                                minutes <= 0
                                  ? "rgb(182,191,209) !important"
                                  : "black",
                            }}
                          >
                            {row.price}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color:
                                minutes <= 0
                                  ? "rgb(182,191,209) !important"
                                  : "black",
                            }}
                          >
                            {moment(row.startDate).format("MM-DD-YYYY LT")}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color:
                                minutes <= 0
                                  ? "rgb(182,191,209) !important"
                                  : "black",
                            }}
                          >
                            {row.startTime}
                          </StyledTableCell>
                          {minutes <= 0 ? (
                            <StyledTableCell
                              sx={{
                                color:
                                  minutes <= 0
                                    ? "rgb(182,191,209) !important"
                                    : "black",
                              }}
                            >
                              0
                            </StyledTableCell>
                          ) : (
                            <StyledTableCell>
                              {Math.floor(hours) + "h" + " " + minutes + "m"}
                            </StyledTableCell>
                          )}

                          <StyledTableCell
                            sx={{
                              color:
                                minutes <= 0
                                  ? "rgb(182,191,209) !important"
                                  : "black",
                            }}
                          >
                            {row?.type}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color:
                                minutes <= 0
                                  ? "rgb(182,191,209) !important"
                                  : "black",
                            }}
                          >
                            {row?.stateName}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color:
                                minutes <= 0
                                  ? "rgb(182,191,209) !important"
                                  : "black",
                            }}
                          >
                            {row?.cityName}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color:
                                minutes <= 0
                                  ? "rgb(182,191,209) !important"
                                  : "black",
                            }}
                          >
                            {orderStatus(row?.status)}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={(e) => e.stopPropagation()}
                            sx={{
                              color:
                                minutes <= 0
                                  ? "rgb(182,191,209) !important"
                                  : "black",
                            }}
                          >
                            <Space size="middle">
                              <Tooltip
                                placement="bottom"
                                title="View Order"
                                disabled={minutes <= 0}
                              >
                                <button
                                  className="action-button view"
                                  onClick={() => {
                                    navigate("/sellerOrderDetail", {
                                      state: {
                                        id: row.id,
                                        poButton: false,
                                        isPO: false,
                                      }
                                    });
                                  }}
                                >
                                  <GrView />
                                </button>
                              </Tooltip>

                              <Tooltip placement="bottom" title="Edit Order">
                                <button
                                  className="action-button edit"
                                  disabled={minutes <= 0}
                                  onClick={() => {
                                    navigate("/sellerOrderDetail", {
                                      state: {
                                        id: row.id,
                                        poButton: false,
                                        isPO: true,
                                      }
                                    });
                                  }}
                                >
                                  <RiEditLine />
                                </button>
                              </Tooltip>
                            </Space>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            {orderList && orderList.length === 0 ? (
              <div className="noDataFound"> No Data Found</div>
            ) : (
              <Box
                sx={{
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: {
                    xs: "10px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                  },
                  marginRight: {
                    xs: "10px",
                    sm: "15px",
                    md: "15px",
                    lg: "15px",
                  },
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    fontSize: "14px",
                    marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                  }}
                >
                  {" "}
                  Showing{" "}
                  {page * pagePerSize > totalRecords
                    ? totalRecords
                    : page * pagePerSize}{" "}
                  of {totalRecords} Results
                </Box>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handleChangePage}
                  variant="outlined"
                  shape="rounded"
                  sx={{
                    "& .MuiButtonBase-root ": {
                      color: "#000 !important",
                    },
                    "& .Mui-selected ": {
                      background: "#4b4b4e70 !important",
                    },
                    "& .Mui-disabled ": {
                      background: "#6c696966 !important",
                      color: "#000000 !important",
                      opacity: 1,
                    },
                  }}
                />
              </Box>
            )}
          </TabPanel>
          <TabPanel value="2">
            <Box
              sx={{
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                border: 1,
                borderColor: "#eaeaea",
              }}
            >
              <SellerOrderPendinglist
                data={orderList}
                setSortFlag={setSortFlag}
                sortFlag={sortFlag}
                page={page}
                search={search}
                setPage={setPage}
                dataShorting={dataShorting}
                flagName={flagName}
                refreshScreen={refreshScreen}
                refresh={refresh}
              />
            </Box>
          </TabPanel>
          <TabPanel value="3">
            <Box
              sx={{
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                border: 1,
                borderColor: "#eaeaea",
              }}
            >
              <SellerOrderPOlist
                data={orderList}
                setSortFlag={setSortFlag}
                sortFlag={sortFlag}
                page={page}
                search={search}
                setPage={setPage}
                dataShorting={dataShorting}
                flagName={flagName}
                refreshScreen={refreshScreen}
                refresh={refresh}
              />
            </Box>
          </TabPanel>
          {/* <TabPanel value="3">
            <Box
              sx={{
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                border: 1,
                borderColor: "#eaeaea",
              }}
            >
              <SellerOrderPOlist
                data={orderList}
                setSortFlag={setSortFlag}
                sortFlag={sortFlag}
                page={page}
                setPage={setPage}
                dataShorting={dataShorting}
                flagName={flagName}
                refreshScreen={refreshScreen}
                refresh={refresh}
              /></Box>
          </TabPanel> */}
        </TabContext>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  // marginLeft: "8px",
                  marginTop: "8px",

                  // marginRight: "8px",
                }}
              >
                <Divider sx={{ mt: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />
                <StyledFormLabel htmlFor="name">Product Name</StyledFormLabel>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    disablePortal
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    labelId="category-input"
                    options={productDropdown}
                    openOnFocus={true}
                    sx={{
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
                        color: "#000",
                      },
                      width: "100%",
                      "& .MuiAutocomplete-popupIndicator": {
                        transform: "none",
                      },
                    }}
                    onChange={(event, value) => getSelectedItems(value)}
                    getOptionLabel={(option) =>
                      option ? option.productName : ""
                    }
                    renderInput={(params) => (
                      <Controls.Input fullWidth {...params} />
                    )}
                    popupIcon={<Search />}
                  />
                </Grid>
                <StyledFormLabel htmlFor="name">Rate</StyledFormLabel>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={ranges}
                  min={0}
                  step={1}
                  max={range}
                  onChange={handleChanges}
                  valueLabelDisplay="auto"
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
              >
                <Box>
                  <StyledFormLabel htmlFor="name">State</StyledFormLabel>

                  <Controls.SelectBox
                    className="input"
                    // placeholder="Enter HSN Code"
                    color="primary"
                    id="stateName"
                    name="stateName"
                    value={userLocations[0]?.stateId}
                    onChange={(e) => {
                      handleInputChange(e);
                      handlefromCityList(e.target.value);
                    }}
                    options={stateList}
                    label="State"
                  />
                </Box>
                <Box>
                  <StyledFormLabel htmlFor="name">City</StyledFormLabel>
                  <Controls.SelectBox
                    className="input"
                    // placeholder="Enter HSN Code"
                    color="primary"
                    id="cityName"
                    name="cityName"
                    value={values.cityName}
                    error={errors.cityName}
                    onChange={handleInputChange}
                    options={fromCityList}
                    label="City"
                  />
                </Box>
              </Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button
                className="cancel-filter"
                onClick={closeFilterDrawer}
                style={{ cursor: "pointer" }}
              >
                Reset
              </button>
              <button
                className="order-filter-button"
                type="submit"
                style={{ cursor: "pointer" }}
              >
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>
      </Box>
    </>
  );
}
