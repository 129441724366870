import {
  Button,
  FormLabel,
  Grid,
  InputAdornment,
  Modal,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import Controls from "../../Component/controls/Controls";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useForm } from "../../Utils/useForm";
import {
  getSellerOrderList,
  OrderSellerStatusChange,
} from "../../state/action/orderAction";
import { useNavigate } from "react-router";
import moment from "moment";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { GrView } from "react-icons/gr";

const initialValues = {
  productName: "",
  categoryId: "",
  fromquantity: "",
  toquantity: "",
  reason: "",
};

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 14,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },
  "&:nth-of-type(even)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SellerOrderPendinglist = (props) => {
  const {
    data,
    setSortFlag,
    sortFlag,
    page,
    setPage,
    dataShorting,
    flagName,
    search,
    refreshScreen,
  } = props;

  const [deleteValue, setDeleteValue] = useState(false);
  const [switchId, setSwitchId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [flagPrice, setflagPrice] = useState(false);
  // const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState([]);
  const [orderReqList, setOrderReqList] = useState([]);
  // const [sortFlag, setSortFlag] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);

  const [notify, setNotify] = useState({});
  const [value, setValue] = useState("1");
  const [pagePerSize, setPagePerSize] = useState(10);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [flagCompanyName, setflagCompanyName] = useState(false);

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    OrderLists(page, "", 0);
  }, [filterData, search, page, rowsPerPage]);

  const OrderLists = async (page, name = "", flag) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}${string}&sortBy=${name}&sortFlag=${flag || 0}&status=0`;

    const res = await getSellerOrderList(queryString);

    if (res.status) {
      console.log('res', res);
      setOrderReqList(res.data);
      setTotalRecords(res.totalOrder);
      setTotalPages(Math.ceil(res.totalOrder / rowsPerPage));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setOrderReqList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleApproveStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      status: 1,
    };
    const res = await OrderSellerStatusChange(switchId, payload);

    if (res && res.status === true) {
      OrderLists(page, "", 0);
      handleCloseSwitch();
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const handleRejectStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      status: 2,
      reason: values?.reason,
      // productMargin: values?.productMargin,
    };
    const res = await OrderSellerStatusChange(deleteId, payload);

    if (res && res.status === true) {
      OrderLists(page, "", 0);
      handleCloseDelete();
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const handleChangePage = (e, value) => {
    // if (fieldName == "productName") {
    //   data(value, fieldName, flagName);
    // } else if (fieldName == "quantity") {
    //   data(value, fieldName, flagPrice);
    // } else {
    //   data(value, "", "");
    // }
    setPage(value);
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
          border: 1,
          borderColor: "#eaeaea",
        }}
      >
        <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell onClick={() => dataShorting("productName")}>
                  Product Name
                  {flagName ? (
                    <ArrowUpwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                        position: "relative",
                        top: "3px",
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                        position: "relative",
                        top: "3px",
                      }}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell>Quantity</StyledTableCell>
                <StyledTableCell>Rate</StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      minWidth: "0",
                    }}
                  >
                    Date
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                    }}
                  >
                    Time
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      minWidth: "0",
                    }}
                  >
                    Packing Type
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      minWidth: "0",
                    }}
                  >
                    State
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      minWidth: "0",
                    }}
                  >
                    City
                  </Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    variant="text"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      paddingLeft: "0",
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "capitalize",
                      minWidth: "0",
                    }}
                  >
                    Action
                  </Button>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderReqList && orderReqList.map((row, index) => (
                <StyledTableRow
                  key={index}
                  onClick={() => {
                    navigate("/sellerOrderDetail", {
                      state: {
                        id: row.id,
                        isPO: false,
                      }
                    });
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.productName}
                  </StyledTableCell>
                  <StyledTableCell> {row.totalQty}MT</StyledTableCell>
                  <StyledTableCell>{row?.price}</StyledTableCell>
                  <StyledTableCell>
                    {moment(row.createdAt).format("MM-DD-YYYY LT")}
                  </StyledTableCell>
                  <StyledTableCell>{row.startTime}</StyledTableCell>
                  <StyledTableCell>{row?.type}</StyledTableCell>
                  <StyledTableCell>{row?.stateName}</StyledTableCell>
                  <StyledTableCell>{row?.cityName}</StyledTableCell>
                  <StyledTableCell onClick={(e) => e.stopPropagation()}>
                    <Box display={"flex"} gap={1}>
                      <Tooltip placement="bottom" title="View Order">
                        <button
                          className="action-button view"
                          onClick={() => {
                            navigate("/sellerOrderDetail", {
                              state: {
                                id: row.id,
                                isPO: false,
                              }
                            });
                          }}
                        >
                          <GrView />
                        </button>
                      </Tooltip>

                      <Controls.Button
                        text="Accept"
                        sx={{
                          fontSize: "11px",
                          fontWeight: "500",
                          color: "#fff",
                          backgroundColor: "rgb(0,128,0)",
                          ":hover": { bgcolor: "rgb(0,128,0)" },
                          textTransform: "none",
                          borderRadius: "50px",
                          boxShadow: 0,
                        }}
                        onClick={(e) => {
                          handleSwitchModel();
                          setSwitchId(row.id);
                        }}
                      />
                      <Controls.Button
                        text="Reject"
                        sx={{
                          fontSize: "11px",
                          fontWeight: "500",
                          color: "#fff",
                          backgroundColor: "rgb(239, 83, 80)",
                          ":hover": { bgcolor: "rgb(239, 83, 80)" },
                          textTransform: "none",
                          borderRadius: "50px",
                          boxShadow: 0,
                        }}
                        onClick={(e) => {
                          handleDeleteModel();
                          setDeleteId(row.id);
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {data && data.length === 0 ? (
        <div className="noDataFound"> No Data Found</div>
      ) : (
        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            }}
          >
            {" "}
            Showing{" "}
            {page * pagePerSize > totalRecords
              ? totalRecords
              : page * pagePerSize}{" "}
            of {totalRecords} Results
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>
      )}

      <Modal
        keepMounted
        open={openSwitchModel}
        onClose={handleCloseSwitch}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styles}>
          <label className="fontLight">
            Are you sure to Update the record?
          </label>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseSwitch} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Approve"
                style={{ backgroundColor: "red" }}
                onClick={() => handleApproveStatus(switchId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styles}>
          <Box sx={{ marginTop: "-25px" }}>
            <Controls.Input
              text="Add New"
              variant="outlined"
              color="primary"
              label="Reason"
              name="reason"
              value={values.reason}
              onChange={handleInputChange}
              error={errors.reason}
              helperText={errors.reason}
              fullWidth
            />
          </Box>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseDelete} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Reject"
                style={{ backgroundColor: "red" }}
                onClick={() => handleRejectStatus(deleteId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default SellerOrderPendinglist;
