import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Form } from "../../Utils/useForm";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BackdropComponent from "../../Component/BackDrop/BackDrop";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  AddSeller,
  sellerDetails,
  updateSeller,
  updateUser,
} from "../../state/action/userAction";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import Notification from "../../Utils/Notification";
import { stateDropdown, cityDropdown } from "../../state/action/AddressAction";
const initialValues = {
  userName: "",
  companyName: "",
  email: "",
  mobile: "",
  panNumber: "",
  gstNumber: "",
  address: "",
  panImage: "",
  gstCertificate: "",
  stateId: "",
  cityId: "",
};

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [images, setImages] = useState();
  const [images1, setImages1] = useState();
  const [stateList, setStateList] = useState([]);
  const [fromCityList, setFromCityList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const navigate = useNavigate();
  const { state } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("companyName" in fieldValues)
      temp.companyName = fieldValues.companyName
        ? ""
        : "This field is required.";

    if ("userName" in fieldValues)
      temp.userName = fieldValues.userName ? "" : "This field is required.";
    if ("address" in fieldValues)
      temp.address = fieldValues.address ? "" : "This field is required.";
    // if ("email" in fieldValues) {
    //   if (fieldValues.email !== "" && fieldValues.email !== undefined) {
    //     if (!/$^|.+@.+..+/.test(fieldValues.email)) {
    //       temp.email = "Invalid Email";
    //     } else {
    //       temp.email = "";
    //     }
    //   } else {
    //     temp.email = "";
    //   }
    // }
    if ("mobile" in fieldValues) {
      //only numbers allow regex
      var regex = /^[0-9]+$/;
      if (!regex.test(fieldValues.mobile)) {
        temp.mobile = " Only numbers are allowed";
      } else {
        temp.mobile = "";
      }
    }

    if ("panNumber" in fieldValues) {
      temp.panNumber = fieldValues.panNumber ? "" : "This field is required.";
    }

    if ("gstNumber" in fieldValues) {
      temp.gstNumber = fieldValues.gstNumber ? "" : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    StateList();
  }, []);


  const StateList = async () => {
    const res = await stateDropdown();
    if (res.status === true) {
      setStateList(res.data);
    } else {
      setStateList([]);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("userName", values.userName);
      formData.append("companyName", values.companyName);
      formData.append("address", values.address);
      formData.append("email", values.email);
      formData.append("mobile", values.mobile);
      formData.append("panNumber", values.panNumber);
      formData.append("gstNumber", values.gstNumber);
      formData.append("gstCertificate", values.gstCertificate);
      formData.append("panImage", values.panImage);
      formData.append("stateId", values.stateId);
      formData.append("cityId", values.cityId);

      const formDatas = new FormData();

      formDatas.append("userName", values.userName);
      formDatas.append("companyId", state?.companyId);
      formDatas.append("companyName", values.companyName);
      formDatas.append("address", values.address);
      formDatas.append("email", values.email);
      formDatas.append("mobile", values.mobile);
      formDatas.append("panNumber", values.panNumber);
      formDatas.append("gstNumber", values.gstNumber);
      formDatas.append("gstCertificate", values.gstCertificate);
      formDatas.append("panImage", values.panImage);
      formDatas.append("stateId", values.stateId);
      formDatas.append("cityId", values.cityId);

      if (isEdit) {
        const res = await updateSeller(state.seller, formDatas);
        if (res && res.status === true) {
          navigate("/seller");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          navigate("/seller", {
            state: { notify: true },
          });
        }
      }
      if (!isEdit) {
        const res = await AddSeller(formData);

        if (res && res.status === true) {
          navigate("/seller");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          setNotify({
            isOpen: true,
            message: res.message || "Please Upload the GST certificate ",
            type: "error",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (state && state.seller) {
      setIsEdit(true);

      setValues({
        ...values,
        userName: state.seller.userName,
        companyName: state.seller.companyName,
        email: state.seller.email,
        mobile: state.seller.mobile,
        panNumber: state.seller.panNumber,
        gstNumber: state.seller.gstNumber,

      });
      getSellerDetails(state.seller);

    } else {
      setIsEdit(false);
      setOpenBackdrop(true);
      setTimeout(() => {
        setOpenBackdrop(false);
      }, 300);
    }
  }, [state]);


  const FromCityList = async (id) => {
    const res = await cityDropdown(id);
    if (res.status === true) {
      setFromCityList(res.data);
    } else {
      setFromCityList([]);
    }
  };
  const getSellerDetails = async (id) => {
    const res = await sellerDetails(state?.seller);

    if (res && res.status === true) {
      console.log("res.data.company[0].panImage", res?.data?.company[0]?.stateId);
      setValues({
        ...values,
        userName: res.data.userName,
        email: res.data.email,
        mobile: res.data.mobile,
        address: res?.data?.company[0]?.address,
        companyName: res?.data?.company[0]?.companyName,
        panNumber: res?.data?.company[0]?.panNumber,
        gstNumber: res?.data?.company[0]?.gstNumber,
        panImage: images,
        gstCertificate: images1,
        stateId: res?.data?.company[0]?.stateId,
        cityId: res?.data?.company[0]?.cityId,
      });
      setImages(res?.data?.company[0]?.panImage);
      setImages1(res?.data?.company[0]?.gstCertificate);
      FromCityList(res?.data?.company[0]?.stateId);
    }
  };


  console.log("values", values);


  // async function handleImageItem1(e) {
  const handleImageItem1 = async (e) => {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["application/pdf"];

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setImages1(selected.name);
      setValues({ ...values, gstCertificate: selected });
    } else {
      // toast.error("Please Select a PDF File", toastType);
    }
  };
  async function deleteImage1(index) {
    setImages1("");
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image1"];
      return newValues;
    });
  }

  const resetImage = () => {
    setImages("");
  };


  const deleteImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["panImage"];
      return newValues;
    });
  };
  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImages(base64String);
      setValues({ ...values, panImage: selected });
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <BackdropComponent open={openBackdrop} />
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/seller");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Seller
          </Typography>
          <Grid container alignItems="top" style={{ marginBottom: "25px" }}>
            <Grid item sm={7} xs={12}>
              <Box display="flex">
                <Box>
                  <img
                    src={"../../assets/images/product-image.png"}
                    style={{ borderRadius: "5px" }}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                  >
                    Seller Module
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#000",
                      marginTop: "3px",
                    }}
                  >
                    Module
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Controls.Button
                  text="Cancel"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "red",
                    ":hover": { bgcolor: "red" },
                    textTransform: "none",
                    borderRadius: "50px",
                    boxShadow: 0,
                  }}
                  onClick={() => {
                    navigate("/seller");
                  }}
                />
                <Controls.Button
                  text="Save"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "rgb(79, 70, 229)",
                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                    marginLeft: "10px",
                    textTransform: "none",
                    borderRadius: "50px",
                    boxShadow: 0,
                  }}
                  variant="outlined"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", backgroundColor: "rgb(241, 245, 249)" }}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              border: 1,
              borderColor: "#eaeaea",
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "rgba(107, 114, 128, .2)",
                marginBottom: "10px",
                paddingLeft: "10px",
                paddingTop: "10px",
                paddingBottom: "0px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Basic Info"
                  {...a11yProps(0)}
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
                <Tab
                  label="Doc Image"
                  {...a11yProps(1)}
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                // disabled={validate()}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Box
                sx={{
                  maxWidth: {
                    md: "500px",
                    lg: "650px",
                  },
                  marginLeft: {
                    xs: "5px",
                    md: "5px",
                    lg: "15px",
                  },
                  paddingBottom: "20px",
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="User Name"
                    name="userName"
                    value={values.userName}
                    onChange={handleInputChange}
                    error={errors.userName}
                    helperText={errors.userName}
                  />


                  <Box sx={{ marginTop: "15px" }}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Company Name"
                      name="companyName"
                      value={values.companyName}
                      onChange={handleInputChange}
                      error={errors.companyName}
                      helperText={errors.companyName}
                    />
                  </Box>

                  <Box sx={{ marginTop: "15px" }}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Address"
                      name="address"
                      value={values.address}
                      onChange={handleInputChange}
                      error={errors.address}
                      helperText={errors.address}
                    />
                  </Box>

                  <Box sx={{ marginTop: "15px" }}>
                    <Grid item sm={6} xs={12}>
                      {/* <Controls.Input
                        text="Add New"
                        variant="outlined"
                        color="primary"
                        label="State"
                        // defaultValue="Delhi"
                        name="startStateId"
                        onChange={handleInputChange}
                        value={values.startStateId}
                        error={errors.startStateId}
                        inputProps={{ maxLength: 100 }}
                        maxLength={200}
                      /> */}

                      <Controls.SelectBox
                        text="Add New"
                        className="input"
                        fullWidth
                        id="State"
                        name="stateId"
                        // selected={values.stateId}
                        value={values.stateId}
                        error={errors.stateId}
                        onChange={(e) => {
                          handleInputChange(e);
                          FromCityList(e.target.value);
                        }}
                        color="primary"
                        options={stateList}
                        label="State"
                      />
                      {Boolean(errors.stateId) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            marginTop: "4px",
                            marginBottom: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {errors.stateId}
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Controls.SelectBox
                        text="Add New"
                        className="input"
                        fullWidth
                        id="cityId"
                        name="cityId"
                        value={values.cityId}
                        error={errors.cityId}
                        onChange={handleInputChange}
                        color="primary"
                        options={fromCityList}
                        label="City"
                      />
                      {Boolean(errors.cityId) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            marginTop: "4px",
                            marginBottom: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {errors.cityId}
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Box>
                  <Box sx={{ marginTop: "15px" }}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleInputChange}
                      error={errors.email}
                      helperText={errors.email}
                    />
                  </Box>
                  <Box sx={{ marginTop: "15px" }}>

                    {isEdit ? (<Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Mobile Number"
                      name="mobile"
                      value={values.mobile}
                      error={errors.mobile}
                      helperText={errors.mobile}
                      inputProps={{ maxLength: 10 }}
                    />) : (<Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Mobile Number"
                      name="mobile"
                      value={values.mobile}
                      onChange={handleInputChange}
                      error={errors.mobile}
                      helperText={errors.mobile}
                      inputProps={{ maxLength: 10 }}
                    />)}



                  </Box>
                  <Box sx={{ marginTop: "15px" }}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Pan Number"
                      name="panNumber"
                      value={values.panNumber}
                      onChange={handleInputChange}
                      error={errors.panNumber}
                      helperText={errors.panNumber}
                    />
                  </Box>
                  <Box sx={{ marginTop: "15px" }}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Gst Number"
                      name="gstNumber"
                      value={values.gstNumber}
                      onChange={handleInputChange}
                      error={errors.gstNumber}
                      helperText={errors.gstNumber}
                    />
                  </Box>
                </Form>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box
                sx={{
                  marginLeft: {
                    xs: "5px",
                    md: "10px",
                    lg: "25px",
                  },
                  marginRight: {
                    xs: "5px",
                    md: "10px",
                    lg: "25px",
                  },
                }}
              >
                <Box display="flex">
                  <Box>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        ":hover": { bgcolor: "transparent" },
                        textTransform: "none",
                        fontSize: "16px",
                        backgroundColor: "#f6f9fb",
                        borderRadius: "15px",
                        padding: "40px",
                      }}
                    >
                      <DriveFolderUploadIcon
                        sx={{
                          fontSize: "40px",
                          color: "rgb(107, 114, 128)",
                          display: "flex",
                        }}
                      />
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          handleImageItem1(e);
                        }}
                        accept="application/pdf"
                      />
                    </Button>
                    <Typography
                      sx={{ fontSize: "13px", color: "#000", marginTop: "7px" }}
                    >
                      Gst Certificate
                    </Typography>
                  </Box>

                  <Box sx={{ textAlign: "end" }}>
                    {images1 && images1 !== "" && (
                      <StyledIconWrapper
                        style={{}}
                        sx={{
                          position: "relative",
                          margin: "25px 5px 5px 5px",
                          width: "147px",
                          height: "140px",
                          background: "none !important",
                          border: "none",
                        }}
                        onClick={() => {
                          // setIconFlag(id);
                        }}
                      >
                        <StyledIconWrapper
                          style={{
                            margin: "0",
                            display: "flex",
                            justifyContent: "center",
                            background: "none",
                            border: "none",
                            width: "auto",
                            height: "50px",
                          }}
                        >
                          {/* <img style={{ marginTop: "13px" }} src={PictureAsPdfIcon} alt="Document" /> */}
                          <PictureAsPdfIcon
                            style={{
                              width: "40px",
                              height: "40px",
                              color: "rgb(118 116 107)",
                            }}
                          />
                        </StyledIconWrapper>

                        <p style={{ fontSize: "12px", textAlign: "center" }}>
                          {" "}
                          {images1 !== "" && images1}
                        </p>
                        <IconButton
                          size="large"
                          sx={{
                            position: "absolute",
                            right: "2%",
                            top: "3%",
                            width: "20px",
                            height: "20px",
                            background: "rgba(15, 15, 15, 0.5)",
                            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                            borderRadius: "10px",

                            color: "white",
                            "&:hover": {
                              backgroundColor: "#FFC400",
                            },
                          }}
                        >
                          <CloseIcon
                            sx={{ width: "20px", height: "20px" }}
                            onClick={() => {
                              deleteImage1();
                            }}
                          />
                        </IconButton>
                      </StyledIconWrapper>
                    )}
                  </Box>
                </Box>
                <Divider
                  sx={{
                    marginBottom: "20px",
                    marginTop: "12px",
                    borderColor: "rgba(107, 114, 128, .4)",
                    width: "15%",
                  }}
                />
                <Box display="flex">
                  <Box>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        ":hover": { bgcolor: "transparent" },
                        textTransform: "none",
                        fontSize: "16px",
                        backgroundColor: "#f6f9fb",
                        borderRadius: "15px",
                        padding: "40px",
                      }}
                    >
                      <DriveFolderUploadIcon
                        sx={{
                          fontSize: "40px",
                          color: "rgb(107, 114, 128)",
                          display: "flex",
                        }}
                      />
                      <input type="file" hidden onChange={handleImageItem} />
                    </Button>
                    <Typography
                      sx={{ fontSize: "13px", color: "#000", marginTop: "7px" }}
                    >
                      Pan Image
                    </Typography>
                  </Box>

                  <Box sx={{ textAlign: "end" }}>
                    {images && images !== "" && (
                      <StyledIconWrapper
                        sx={{
                          position: "relative",
                          margin: "25px 5px 5px 5px",
                          width: "147px",
                          height: "140px",
                          backgroundImage: `url(${images})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          marginTop: "-21px",
                          marginLeft: "27px",
                        }}
                        key={1}
                        onClick={() => {
                          // setIconFlag(id);
                        }}
                      >
                        <IconButton
                          size="large"
                          sx={{
                            position: "absolute",
                            right: "2%",
                            top: "3%",
                            width: "36px",
                            height: "36px",
                            background: "rgba(15, 15, 15, 0.5)",
                            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                            borderRadius: "10px",

                            color: "white",
                            "&:hover": {
                              backgroundColor: "#0957DD",
                            },
                          }}
                        >
                          <CloseIcon
                            sx={{ width: "20px", height: "20px" }}
                            onClick={() => {
                              deleteImage();
                            }}
                          />
                        </IconButton>
                      </StyledIconWrapper>
                    )}
                  </Box>
                </Box>
              </Box>
            </TabPanel>
          </Box>
        </Box>
        <Notification notify={notify} setNotify={setNotify} />
      </Box>
    </>
  );
}
