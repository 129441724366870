import React, { useEffect } from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import {
  useTheme,
  Box,
  IconButton,
  Typography,
  InputBase,
  Avatar,
  Divider,
} from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useProSidebar } from "react-pro-sidebar";
import Badge from '@mui/material/Badge';
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { logoutApi } from "../../state/action/authAction";
import { Navigate, useNavigate } from "react-router";
import Logo from "../../assets/Logo_Final.png";
import { useDispatch, useSelector } from "react-redux";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";

import { Notification } from "../../state/action/notification";


const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const dispatch = useDispatch();

  const users = useSelector((store) => store?.auth?.user);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNoti, setAnchorElNoti] = React.useState(null);
  const [notificationData, setNotificationData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const openNoti = Boolean(anchorElNoti);

  useEffect(() => {
    getAllNotification();
  }, []);

  useEffect(() => {
    window.OneSignal.push(function () {
      window.OneSignal.on('notificationDisplay', function (event) {
        getAllNotification();
      });
    });

  }, []);

  const navigate = useNavigate();
  const getAllNotification = async () => {

    const res = await Notification();

    if (res?.status === true) {
      setNotificationData(res?.data);
      setCount(res?.data?.length);
    }
    else {
      setNotificationData([]);
    }
  };


  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNoti = (event) => {
    setAnchorElNoti(event.currentTarget);
  };

  const handleCloseNoti = () => {
    setAnchorElNoti(null);
  };

  const logoutAdmin = async () => {
    dispatch(logoutApi());
    // navigate("/");
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      px={2}
      py={0.7}
      sx={{
        backgroundColor: "#fff",
        boxShadow: "2",
        alignItems: "center",
        position: "sticky",
        top: "0",
        zIndex: "999",
      }}
    >
      {broken && !rtl && (
        <IconButton sx={{ margin: "0 6 0 2" }} onClick={() => toggleSidebar()}>
          <MenuOutlinedIcon
            sx={{
              color: "#000",
            }}
          />
        </IconButton>
      )}
      <Box
        display="flex"
        sx={{
          display: {
            xs: "none",
            md: "block",
            lg: "block",
          },
        }}
      >
        {/* <IconButton
          style={{
            color: colors.grey[500],
          }}
        >
          <MailOutlineIcon
            style={{
              fontSize: "25px",
            }}
          />
          </IconButton> */}
        {/*<IconButton
          style={{
            color: colors.grey[500],
          }}
        >
          <DateRangeIcon
            style={{
              fontSize: "25px",
            }}
          />
          </IconButton>*/}
        {/* <IconButton
          style={{
            color: colors.grey[500],
          }}
        >
          <PeopleOutlinedIcon
            style={{
              fontSize: "25px",
            }}
          />
          </IconButton>*/}
      </Box>
      <Box display="flex">
        <IconButton
          style={{
            color: colors.grey[500],
          }}
          id="basic-button-noti"
          aria-controls={open ? "notification" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickNoti}
        >
          <Badge color="info" badgeContent={count}>
            <NotificationsOutlinedIcon
              style={{
                fontSize: "25px",
              }}
            />
          </Badge>
        </IconButton>

        <Box>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Box
              display="flex"
              sx={{ marginLeft: "10px", alignItems: "center" }}
            >
              <Box textAlign="right" sx={{ marginRight: "10px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#000",
                    marginBottom: "0px",
                    fontWeight: "600",
                    textTransform: "capitalize",
                  }}
                >
                  {users?.fname + " " + users?.lname}
                </Typography>
              </Box>
              <Box>
                <Avatar
                  src="/broken-image.jpg"
                  className="avater-image"
                  alt="profile user"
                  width="40px"
                  height="40px"
                  style={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    backgroundColor: "#dedada",
                  }}
                />
              </Box>
            </Box>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#fff",
              },
            }}
          >
            <MenuItem
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <AccountCircleIcon
                sx={{
                  color: "rgb(107, 114, 128)",
                  fontSize: "25px",
                  marginRight: "7px",
                }}
              />
              My Profile
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}
            >
              <ForwardToInboxIcon
                sx={{
                  color: "rgb(107, 114, 128)",
                  fontSize: "25px",
                  marginRight: "7px",
                }}
              />
              Inbox
            </MenuItem>

            <MenuItem
              sx={{ color: "#000", fontSize: "14px", fontWeight: "400" }}
              onClick={() => logoutAdmin()}
            >
              <LogoutIcon
                sx={{
                  color: "rgb(107, 114, 128)",
                  fontSize: "25px",
                  marginRight: "7px",
                }}
              />
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <Menu
        id="notification"
        anchorEl={anchorElNoti}
        open={openNoti}
        onClose={handleCloseNoti}
        MenuListProps={{
          "aria-labelledby": "basic-button-noti",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#fff",
          },
          height: "350px",
          overflow: "auto",
          boxShadow: "none",

        }}

      >
        {notificationData.map((item) => {
          return (<div>
            <MenuItem
              onClick={handleClose}
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: "400",

              }}
              key={item.id}
            >

              <Box sx={{ display: "flex" }}
                onClick={() => {
                  handleCloseNoti();
                  if (item.type == 0) {
                    // close notification
                    navigate("/userDetail", {
                      state: item.userId,
                    });
                  }
                  if (item.type == 1) {
                    navigate("sellerOrder");
                  }
                  if (item.type == 2) {
                    navigate("buyerOrder");
                  }
                  if (item.type == 3) {
                    navigate("/product");
                  }
                }}
              >
                <Avatar
                  aria-label="recipe"
                  sx={{
                    marginLeft: " 16px",
                    marginTop: "16px",
                    width: "30px",
                    height: "30px",
                  }}

                  src={Logo}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "20px",
                    marginTop: "15px",
                  }}
                >
                  <Box sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{ color: "black" }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                      sx={{ color: "black" }}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </MenuItem>
            <Divider sx={{ mt: 1, bgcolor: "rgba(107, 114, 128, .4)" }} /> </div >
          );
        }
        )}



      </Menu>
    </Box>
  );
};

export default Topbar;
