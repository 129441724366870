import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import CardMedia from "@mui/material/CardMedia";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Form } from "../../Utils/useForm";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import BackdropComponent from "../../Component/BackDrop/BackDrop";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { addCategory, updateCategory } from "../../state/action/categoryAction";
const initialValues = {
  name: "",
  categoryImage: "",
};

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [images, setImages] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [files, setFiles] = useState([]);

  const navigate = useNavigate();
  const { state } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("categoryImage", values.categoryImage);

      if (isEdit) {
        const res = await updateCategory(state.category.id, formData);
        if (res && res.status === true) {
          navigate("/category", {
            state: { notify: "true1" },
          });
        } else {
          navigate("/category", {
            state: { notify: true },
          });
        }
      }
      if (!isEdit) {
        const res = await addCategory(formData);

        if (res && res.status === true) {
          navigate("/category");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (state && state.category && state.category.id) {
      setIsEdit(true);

      setValues({
        ...values,
        name: state.category.name,
        categoryImage: state.category.image,
      });

      setImages(state.category.image);
    } else {
      setIsEdit(false);
      setOpenBackdrop(true);
      setTimeout(() => {
        setOpenBackdrop(false);
      }, 300);
    }
  }, [state]);

  const resetImage = () => {
    setImages("");
  };
  const reset = () => {
    resetImage();
    resetForm();
  };
  const deleteImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["panImage"];
      return newValues;
    });
  };
  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImages(base64String);
      setValues({ ...values, categoryImage: selected });
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <BackdropComponent open={openBackdrop} />
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/category");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Category
          </Typography>
          <Grid container alignItems="top" style={{ marginBottom: "25px" }}>
            <Grid item sm={7} xs={12}>
              <Box display="flex">
                <Box>
                  <img
                    src={"../../assets/images/product-image.png"}
                    style={{ borderRadius: "5px" }}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                  >
                    Category Module
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#000",
                      marginTop: "3px",
                    }}
                  >
                    Module
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Controls.Button
                  text="Cancel"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "red",
                    ":hover": { bgcolor: "red" },
                    textTransform: "none",
                    borderRadius: "50px",
                    boxShadow: 0,
                  }}
                  onClick={() => {
                    navigate("/category");
                  }}
                />
                <Controls.Button
                  text="Save"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "rgb(79, 70, 229)",
                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                    marginLeft: "10px",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    textTransform: "none",
                    borderRadius: "50px",
                  }}
                  variant="outlined"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", backgroundColor: "rgb(241, 245, 249)" }}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              border: 1,
              borderColor: "#eaeaea",
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "rgba(107, 114, 128, .2)",
                marginBottom: "10px",
                paddingLeft: "10px",
                paddingTop: "10px",
                paddingBottom: "0px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Basic Info"
                  {...a11yProps(0)}
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
                <Tab
                  label="Upload Image"
                  {...a11yProps(1)}
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                  // disabled={validate()}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Box
                sx={{
                  width: {
                    md: "100%",
                    lg: "600px",
                  },
                }}
              >
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Name"
                  name="name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  helperText={errors.name}
                  sx={{ color: "#000", borderColor: "#000", border: "1" }}
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box>
                <Box>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      ":hover": { bgcolor: "transparent" },
                      textTransform: "none",
                      fontSize: "16px",
                      backgroundColor: "#f6f9fb",
                      borderRadius: "15px",
                      padding: "40px",
                    }}
                  >
                    <DriveFolderUploadIcon
                      sx={{
                        fontSize: "40px",
                        color: "rgb(107, 114, 128)",
                        display: "flex",
                      }}
                    />
                    <input
                      type="file"
                      hidden
                      // onChange={(e) => {
                      //   handleImageItem1(e);
                      // }}
                      // accept="application/pdf"
                      onChange={handleImageItem}
                      error={errors.categoryImage}
                      helperText={errors.categoryImage}
                    />
                  </Button>
                  <Typography
                    sx={{ fontSize: "13px", color: "#000", marginTop: "7px" }}
                  >
                    Category Image
                  </Typography>
                </Box>

                <Box sx={{ textAlign: "end" }}>
                  {images && images !== "" && (
                    <StyledIconWrapper
                      sx={{
                        position: "relative",
                        margin: "25px 5px 5px 5px",
                        width: "147px",
                        height: "140px",
                        backgroundImage: `url(${images})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                      key={1}
                      onClick={() => {
                        // setIconFlag(id);
                      }}
                    >
                      <IconButton
                        size="large"
                        sx={{
                          position: "absolute",
                          right: "2%",
                          top: "3%",
                          width: "36px",
                          height: "36px",
                          background: "rgba(15, 15, 15, 0.5)",
                          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                          borderRadius: "10px",

                          color: "white",
                          "&:hover": {
                            backgroundColor: "#0957DD",
                          },
                        }}
                      >
                        <CloseIcon
                          sx={{ width: "20px", height: "20px" }}
                          onClick={() => {
                            deleteImage();
                          }}
                        />
                      </IconButton>
                    </StyledIconWrapper>
                  )}
                </Box>
              </Box>
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </>
  );
}
