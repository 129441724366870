import React, { useState, useEffect } from "react";
import { Box, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useNavigate, useLocation } from "react-router";
import { useForm, Form } from "../../Utils/useForm";
import {
  addState,
  updateStateLocation,
} from "../../state/action/locationAction";
import { useSnackbar } from "notistack";
const initialValues = {
  state: "",
};
function AddState() {
  const [value, setValue] = useState("1");
  const [isEdit, setIsEdit] = useState(false);
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("state" in fieldValues)
      temp.state = fieldValues.state ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        name: values.state,
      };
      if (isEdit) {
        // updateProduct(formData, state.product._id);
        const res = await updateStateLocation(state.data.id, payload);
        if (res && res.status === true) {
          navigate("/State");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        const res = await addState(payload);
        if (res && res.status === true) {
          navigate("/State");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (
      state?.data.id != "" &&
      state?.data.id != null &&
      state?.data.id != undefined
    ) {
      setIsEdit(true);
      setValues({
        ...values,
        state: state?.data.name,
      });
    }
  }, [state]);
  return (
    <>
      <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
            }}
            onClick={() => {
              navigate("/State");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            State
          </Typography>
          <Grid container alignItems="top" style={{ marginBottom: "20px" }}>
            <Grid item sm={6} xs={12}>
              <Box display="flex">
                <Box>
                  <img
                    src={"../../assets/images/product-image.png"}
                    style={{ borderRadius: "5px" }}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                  >
                    {isEdit ? "Edit State" : "Add State"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#000",
                      marginTop: "3px",
                    }}
                  >
                    State
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "block",
                    sm: "flex-end",
                    md: "flex-end",
                    lg: "flex-end",
                  },
                  marginTop: { xs: "20px", md: "0", lg: "0" },
                }}
              >
                <Controls.Button
                  text="Cancel"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "red",
                    ":hover": { bgcolor: "red" },
                    textTransform: "none",
                    borderRadius: "50px",
                    boxShadow: 0,
                  }}
                  onClick={() => {
                    navigate("/State");
                  }}
                />
                <Controls.Button
                  text="Save"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "rgb(79, 70, 229)",
                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                    marginLeft: "10px",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    textTransform: "none",
                    borderRadius: "50px",
                  }}
                  variant="outlined"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              border: 1,
              borderColor: "#eaeaea",
              paddingLeft: "0px",
              paddingTop: "10px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "rgba(107, 114, 128, .4)",
                    marginBottom: "0px",
                  }}
                >
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      label="Add State"
                      value="1"
                      sx={{
                        color: "rgb(107, 114, 128)",
                        fontSize: "14px",
                        fontWeight: "500",
                        textTransform: "none",
                      }}
                    />
                    {/*<Tab label="Upload Img" value="2" sx={{ color:'rgb(107, 114, 128)',fontSize: "14px", fontWeight:'500',textTransform: "none" }}/>  */}
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        width: {
                          md: "100%",
                          lg: "600px",
                        },
                      }}
                    >
                      <Controls.Input
                        text="Add New"
                        variant="outlined"
                        color="primary"
                        label="State"
                        sx={{ color: "#000", borderColor: "#000", border: "1" }}
                        name="state"
                        onChange={handleInputChange}
                        value={values.state}
                        error={errors.state}
                      />
                    </Box>
                  </Form>
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default AddState;
