import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid, IconButton, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Form } from "../../Utils/useForm";
import { styled } from "@mui/material/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BackdropComponent from "../../Component/BackDrop/BackDrop";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
    AddCompanyByBuyerId,
    AddUser,
    EditCompanyByBuyerId,
    buyerDetails,
    deleteUserLocation,
    updateUser,
} from "../../state/action/userAction";
import { useNavigate, useParams } from "react-router";
import { useSnackbar } from "notistack";
import { getCategoryDropdown } from "../../state/action/categoryAction";
import {
    getCityUserLists,
    getStateUserList,
} from "../../state/action/locationAction";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Multiselect from "multiselect-react-dropdown";
import { stateDropdown, cityDropdown } from "../../state/action/AddressAction";

const initialValues = {
    companyName: "",
    address: "",
    panNumber: "",
    gstNumber: "",
    gstCertificate: "",
    panImage: "",
    startcityId: "",
    startstateId: "",
};

export default function AddCompany() {
    const { state } = useLocation();
    console.log("state", state);
    const [edit, setEdit] = useState(false);
    const [companyId, setcompanyId] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [fromCityList, setFromCityList] = useState([]);
    const [buyerId, setBuyerId] = useState(state?.buyer);
    const [images1, setImages1] = useState();
    const [images, setImages] = useState();
    // for Display To image 
    const [imageDisplay, setImageDisplay] = useState("");
    const [images1Display, setImages1Display] = useState("")
    const [startStateList, setstartStateList] = useState([]);
    const navigate = useNavigate();

    const [openBackdrop, setOpenBackdrop] = useState(false);

    const StyledIconWrapper = styled(Box)(() => ({
        margin: "20px auto",
        border: "1px solid rgba(15, 15, 15, 0.15)",
        width: "80px",
        height: "80px",
        borderRadius: "10px",
        backgroundColor: "rgba(15, 15, 15, 0.05)",

        img: {
            marginTop: "30px",
        },
    }));

    async function handleImageItem(e) {
        const selected = e.target.files[0];
        const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
        if (selected && ALLOWED_TYPES.includes(selected.type)) {
            const base64String = await getBase64(selected);
            setImages(base64String);
            setImageDisplay(base64String);
            setValues({ ...values, panImage: selected });
        } else {
        }
    }

    async function deleteImage1(index) {
        setImages1("");
        setImages1Display("");
        setValues((previousValues) => {
            var newValues = { ...previousValues };
            delete newValues["gstCertificate"];
            return newValues;
        });
    }

    useEffect(() => {
        if (state?.edit) {
            setEdit(state?.edit);
            setcompanyId(state?.companyId);
            setDataHandler(state?.data);
        }
    }, [state]);


    const setDataHandler = (data) => {
        setValues({
            ...values,
            companyName: data.companyName,
            address: data.address,
            panNumber: data.panNumber,
            gstNumber: data.gstNumber,
            gstCertificate: images1 || "null",
            panImage: images || "null",
            startstateId: data.stateId,
            startcityId: data.cityId,
        });
        FromCityList(data.stateId);
        // setImages => Pan 
        setImageDisplay(data.panImage)
        // setImages1 => Gst 
        console.log("---->", data.gstCertificate.trim())
        // gstCertificate
        setImages1Display(data.gstCertificate)
    }

    const getBase64 = (file) => {
        return new Promise((resolve) => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const resetImage = () => {
        setImages("");
        setImageDisplay("");
    };

    const deleteImage = () => {
        resetImage();
        setValues((previousValues) => {
            var newValues = { ...previousValues };
            delete newValues["panImage"];
            return newValues;
        });
    };

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        console.log("fieldValues", fieldValues);
        if ("companyName" in fieldValues)
            temp.companyName = fieldValues.companyName
                ? ""
                : "This field is required.";

        if ("gstNumber" in fieldValues) {
            temp.gstNumber = fieldValues.gstNumber ? "" : "This field is required.";
        }

        if ("address" in fieldValues) {
            temp.address = fieldValues.address ? "" : "This field is required.";
        }

        // if ("panNumber" in fieldValues) {
        //   //only numbers allow regex
        //   var regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        //   if (!regex.test(fieldValues.panNumber)) {
        //     temp.panNumber = "Invalid PanNumber";
        //   } else {
        //     temp.panNumber = "";
        //   }
        // }

        if ("startstateId" in fieldValues) {
            temp.startstateId = fieldValues.startstateId
                ? ""
                : "This field is required.";
        }

        if ("startcityId" in fieldValues) {
            temp.startcityId = fieldValues.startcityId
                ? ""
                : "This field is required.";
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);

    useEffect(() => {
        StateLists();
    }, []);

    const handleImageItem1 = async (e) => {
        const selected = e.target.files[0];
        //image/jpeg,image/gif,image/png,application/pdf
        const ALLOWED_TYPES = ["application/pdf", "image/jpeg", "image/png"];

        if (selected && ALLOWED_TYPES.includes(selected.type)) {
            setImages1(selected.name);
            setImages1Display(selected.name);
            setValues({ ...values, gstCertificate: selected });
        } else {
            // toast.error("Please Select a PDF File", toastType);
        }
    };

    const StateLists = async () => {
        const res = await stateDropdown();
        if (res.status === true) {
            setstartStateList(res.data);
        } else {
            setstartStateList([]);
        }
    };

    const FromCityList = async (id) => {
        const res = await cityDropdown(id);
        if (res.status === true) {
            setFromCityList(res.data);
        } else {
            setFromCityList([]);
        }
    };

    const handleSubmit = async (e) => {
        if (validate()) {
            if (edit) {
                const formData = new FormData();
                formData.append("companyName", values.companyName);
                formData.append("gstNumber", values.gstNumber);
                formData.append("panNumber", values.panNumber);
                formData.append("gstCertificate", values.gstCertificate);
                formData.append("panImage", values.panImage);
                formData.append("address", values.address);
                formData.append("stateId", values.startstateId);
                formData.append("cityId", values.startcityId);

                const res = await EditCompanyByBuyerId(buyerId, companyId, formData);

                if (res && res.status) {
                    navigate(-1);
                    enqueueSnackbar(res.message, {
                        variant: "success",
                        autoHideDuration: 2000,
                    });
                } else {
                    enqueueSnackbar(res.message, {
                        variant: "error",
                        autoHideDuration: 2000,
                    });
                }

            } else {
                const formData = new FormData();
                formData.append("companyName", values.companyName);
                formData.append("gstNumber", values.gstNumber);
                formData.append("panNumber", values.panNumber);
                formData.append("gstCertificate", values.gstCertificate);
                formData.append("panImage", values.panImage);
                formData.append("address", values.address);
                formData.append("stateId", values.startstateId);
                formData.append("cityId", values.startcityId);

                const res = await AddCompanyByBuyerId(buyerId, formData);
                if (res && res.status) {
                    navigate(-1);
                    enqueueSnackbar(res.message, {
                        variant: "success",
                        autoHideDuration: 2000,
                    });
                } else {
                    enqueueSnackbar(res.message, {
                        variant: "error",
                        autoHideDuration: 2000,
                    });
                }
            }
        }
    };

    return (
        <>
            <Box
                sx={{
                    px: 3,
                    paddingBottom: "3px",
                    backgroundColor: "rgb(241, 245, 249)",
                }}
            >
                <BackdropComponent open={openBackdrop} />
                <Typography
                    sx={{
                        paddingTop: "25px",
                        paddingBottom: "15px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#000",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <KeyboardBackspaceIcon
                        sx={{ verticalAlign: "top", marginRight: "5px" }}
                    />
                    Add Company
                </Typography>
                <Grid container alignItems="top" style={{ marginBottom: "25px" }}>
                    <Grid item sm={7} xs={12}>
                        <Box display="flex">
                            <Box>
                                <img
                                    src={"../../assets/images/product-image.png"}
                                    style={{ borderRadius: "5px" }}
                                />
                            </Box>
                            <Box sx={{ marginLeft: "10px" }}>
                                <Typography
                                    sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                                >
                                    Company
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        color: "#000",
                                        marginTop: "3px",
                                    }}
                                >
                                    ADD / EDIT
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Controls.Button
                                text=" Cancel"
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#fff",
                                    backgroundColor: "Red",
                                    ":hover": { bgcolor: "Red" },
                                    textTransform: "none",
                                    borderRadius: "50px",
                                    boxShadow: 0,
                                }}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            />
                            <Controls.Button
                                text="Save"
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#fff",
                                    backgroundColor: "rgb(79, 70, 229)",
                                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                                    marginLeft: "10px",
                                    textTransform: "none",
                                    borderRadius: "50px",
                                    boxShadow: 0,
                                }}
                                variant="outlined"
                                onClick={(e) => {
                                    handleSubmit(e);
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        backgroundColor: "rgb(241, 245, 249)",
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: "#fff",
                            borderTopRightRadius: "20px",
                            borderTopLeftRadius: "20px",
                            border: 1,
                            borderColor: "#eaeaea",
                            paddingLeft: "0px",
                            paddingTop: "10px",
                        }}
                    >
                        <Box sx={{ width: "100%" }}>
                            <Box
                                sx={{
                                    width: {
                                        md: "100%",
                                        lg: "650px",
                                    },
                                }}
                            >
                                <Form>
                                    <Controls.Input
                                        name="companyName"
                                        onChange={handleInputChange}
                                        value={values.companyName}
                                        error={errors.companyName}
                                        label="company Name"
                                        inputProps={{ maxLength: 100 }}
                                        maxLength={200}
                                    />
                                    <Box sx={{ marginTop: "22px" }}>
                                        <Controls.Input
                                            name="gstNumber"
                                            onChange={handleInputChange}
                                            value={values.gstNumber}
                                            error={errors.gstNumber}
                                            label="gst Number"
                                            inputProps={{ maxLength: 100 }}
                                            maxLength={200}
                                        />
                                    </Box>
                                    <Box sx={{ marginTop: "22px" }}>
                                        <Controls.Input
                                            name="panNumber"
                                            onChange={handleInputChange}
                                            value={values.panNumber}
                                            error={errors.panNumber}
                                            label="pan Number"
                                            inputProps={{ maxLength: 100 }}
                                            maxLength={200}
                                        />
                                    </Box>
                                    <Box sx={{ marginTop: "22px" }}>
                                        <Controls.Input
                                            name="address"
                                            onChange={handleInputChange}
                                            value={values.address}
                                            error={errors.address}
                                            label="address"
                                        // inputProps={{ maxLength: 100 }}
                                        // maxLength={200}
                                        />
                                    </Box>
                                    <Box sx={{ marginTop: "22px" }}>
                                        <Controls.SelectBox
                                            text="Add New"
                                            className="input"
                                            fullWidth
                                            id="startstateId"
                                            name="startstateId"
                                            value={values.startstateId}
                                            error={errors.startstateId}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                FromCityList(e.target.value);
                                            }}
                                            color="primary"
                                            options={startStateList}
                                            label="State"
                                        />
                                        {Boolean(errors.startstateId) ? (
                                            <p
                                                style={{
                                                    color: "rgb(255 0 0)",
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                {errors.startstateId}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </Box>
                                    <Box sx={{ marginTop: "22px" }}>
                                        <Controls.SelectBox
                                            text="Add New"
                                            className="input"
                                            fullWidth
                                            id="startcityId"
                                            name="startcityId"
                                            value={values.startcityId}
                                            error={errors.startcityId}
                                            onChange={handleInputChange}
                                            color="primary"
                                            options={fromCityList}
                                            label="City"
                                        />
                                        {Boolean(errors.startcityId) ? (
                                            <p
                                                style={{
                                                    color: "rgb(255 0 0)",
                                                    marginTop: "4px",
                                                    marginBottom: "4px",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                {errors.startcityId}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                        <Box display="flex">
                                            <Box>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    sx={{
                                                        ":hover": { bgcolor: "transparent" },
                                                        textTransform: "none",
                                                        fontSize: "16px",
                                                        backgroundColor: "#f6f9fb",
                                                        borderRadius: "15px",
                                                        padding: "40px",
                                                    }}
                                                >
                                                    <DriveFolderUploadIcon
                                                        sx={{
                                                            fontSize: "40px",
                                                            color: "rgb(107, 114, 128)",
                                                            display: "flex",
                                                        }}
                                                    />
                                                    <input
                                                        type="file"
                                                        hidden
                                                        onChange={(e) => {
                                                            handleImageItem1(e);
                                                        }}
                                                        accept="image/jpeg,image/gif,image/png,application/pdf"
                                                    />
                                                </Button>
                                                <Typography
                                                    sx={{
                                                        fontSize: "13px",
                                                        color: "#000",
                                                        marginTop: "7px",
                                                    }}
                                                >
                                                    Gst Certificate
                                                </Typography>
                                            </Box>
                                            <Box sx={{ textAlign: "end" }}>
                                                {images1Display && images1Display !== "" && (
                                                    <StyledIconWrapper
                                                        style={{}}
                                                        sx={{
                                                            position: "relative",
                                                            margin: "25px 5px 5px 5px",
                                                            width: "147px",
                                                            height: "140px",
                                                            background: "none !important",
                                                            border: "none",
                                                        }}
                                                        onClick={() => {
                                                            // setIconFlag(id);
                                                        }}
                                                    >
                                                        <StyledIconWrapper
                                                            style={{
                                                                margin: "0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                background: "none",
                                                                border: "none",
                                                                width: "auto",
                                                                height: "50px",
                                                            }}
                                                        >
                                                            <PictureAsPdfIcon
                                                                style={{
                                                                    width: "40px",
                                                                    height: "40px",
                                                                    color: "rgb(118 116 107)",
                                                                }}
                                                            />
                                                        </StyledIconWrapper>

                                                        <p
                                                            style={{ fontSize: "12px", textAlign: "center" }}
                                                        >
                                                            {" "}
                                                            {images1Display !== "" && images1Display}
                                                        </p>
                                                        {/* <IconButton
                                                            size="large"
                                                            sx={{
                                                                position: "absolute",
                                                                right: "2%",
                                                                top: "3%",
                                                                width: "20px",
                                                                height: "20px",
                                                                background: "rgba(15, 15, 15, 0.5)",
                                                                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                                                borderRadius: "10px",

                                                                color: "white",
                                                                "&:hover": {
                                                                    backgroundColor: "#FFC400",
                                                                },
                                                            }}
                                                        >
                                                            <CloseIcon
                                                                sx={{ width: "20px", height: "20px" }}
                                                                onClick={() => {
                                                                    deleteImage1();
                                                                }}
                                                            />
                                                        </IconButton> */}
                                                    </StyledIconWrapper>
                                                )}
                                            </Box>
                                        </Box>

                                        <Box display="flex">
                                            <Box>
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    sx={{
                                                        ":hover": { bgcolor: "transparent" },
                                                        textTransform: "none",
                                                        fontSize: "16px",
                                                        backgroundColor: "#f6f9fb",
                                                        borderRadius: "15px",
                                                        padding: "40px",
                                                    }}
                                                >
                                                    <DriveFolderUploadIcon
                                                        sx={{
                                                            fontSize: "40px",
                                                            color: "rgb(107, 114, 128)",
                                                            display: "flex",
                                                        }}
                                                    />
                                                    <input
                                                        type="file"
                                                        hidden
                                                        // accept="application/pdf"
                                                        onChange={handleImageItem}
                                                    />
                                                </Button>
                                                <Typography
                                                    sx={{
                                                        fontSize: "13px",
                                                        color: "#000",
                                                        marginTop: "7px",
                                                    }}
                                                >
                                                    Pan Image
                                                </Typography>
                                            </Box>

                                            <Box sx={{ textAlign: "end" }}>
                                                {imageDisplay && imageDisplay !== "" && (
                                                    <StyledIconWrapper
                                                        sx={{
                                                            position: "relative",
                                                            margin: "25px 5px 5px 5px",
                                                            width: "147px",
                                                            height: "140px",
                                                            backgroundImage: `url(${imageDisplay})`,
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundSize: "cover",
                                                            marginTop: "-21px",
                                                            marginLeft: "27px",
                                                        }}
                                                        key={1}
                                                        onClick={() => {
                                                            // setIconFlag(id);
                                                        }}
                                                    >
                                                        {/* <IconButton
                                                            size="large"
                                                            sx={{
                                                                position: "absolute",
                                                                right: "2%",
                                                                top: "3%",
                                                                width: "36px",
                                                                height: "36px",
                                                                background: "rgba(15, 15, 15, 0.5)",
                                                                boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                                                borderRadius: "10px",

                                                                color: "white",
                                                                "&:hover": {
                                                                    backgroundColor: "#0957DD",
                                                                },
                                                            }}
                                                        >
                                                            <CloseIcon
                                                                sx={{ width: "20px", height: "20px" }}
                                                                onClick={() => {
                                                                    deleteImage();
                                                                }}
                                                            />
                                                        </IconButton> */}
                                                    </StyledIconWrapper>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Form>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
}
