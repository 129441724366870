import axiosRequest from "../axios/axios";

export const getCategoryList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/categoryList${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCategory = async (id) => {
  try {
    const response = await axiosRequest.get(`/category/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const addCategory = async (category) => {
  try {
    const response = await axiosRequest.post(`/addCategory`, category);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateCategory = async (id, category) => {
  try {
    const response = await axiosRequest.post(`/updateCategory/${id}`, category);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteCategory = async (id) => {
  try {
    const response = await axiosRequest.delete(`/deleteCategory/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const categoryStatusChange = async (id, status) => {
  try {
    const response = await axiosRequest.post(`/categoryStatus/${id}`, status);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//category Dropdown
export const getCategoryDropdown = async () => {
  try {
    const response = await axiosRequest.get(`/categoryDropdown`);
    return response.data;
  } catch (error) {
    return error;
  }
};
