import axiosRequest from "../axios/axios";


export const Notification = async (payload) => {
    try {
        const response = await axiosRequest.get(`/getNotification`, payload);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}
