import React, { useState, useEffect } from 'react'
import { Modal } from "@mui/material";
import Controls from "../../Component/controls/Controls";
import {
    Box,
    Grid,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { SendMessageonWhatshapp, getSendingStatus } from "../../state/action/sendWhatshappMessage";
import Notification from "../../Utils/Notification";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';


const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "0",
    outline: "0",
    bgcolor: "#fff",
    borderRadius: "10px",
    p: 3,
};


function WhatshappMessageSend() {
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    const [sendingCount, setSendingCount] = useState({ sentCount: 0, pendingSend: 0 });

    const handleWhatshappModel = () => setOpenWhatshappModel(true);
    const handleCloseWhatshapp = () => setOpenWhatshappModel(false);
    const [openWhatshappModel, setOpenWhatshappModel] = useState(false);

    const handleSubmit = async () => {

        const res = await SendMessageonWhatshapp();
        if (res && res.status == true) {
            setNotify({
                isOpen: true,
                message: "Message Sent Successfully",
                type: "success",
            });

            handleCloseWhatshapp();
        } else {
            setNotify({
                isOpen: true,
                message: "Message Not Sent",
                type: "error",
            });
        }
    };

    const getCountData = async () => {
        const res = await getSendingStatus();
        if (res && res.status == true) {
            const resData = res?.countData[0];
            setSendingCount(resData);
        }
    }

    useEffect(() => {
        getCountData();
    }, [])



    return (
        <div>
            <p
                style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#000",
                    marginBottom: "15px",
                    marginLeft: "35px",
                }}
            >
                Click here to send message on WhatsApp
            </p>

            <button
                style={{
                    backgroundColor: "#4F46E5",
                    color: "white",
                    border: "1px solid rgb(79, 70, 229)",
                    borderRadius: "10px",
                    padding: "8px 13px",
                    fontSize: "16px",
                    cursor: "pointer",
                    marginBottom: "15px",
                    marginLeft: "35px",
                }}
                onClick={handleWhatshappModel}
            >
                Send Message
            </button>

            <Card sx={{ minWidth: 100 }}>
                <CardContent style={{ border: "1px solid #bbb", color: "black" }}>
                    <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
                        <b>Total Sent</b>
                    </Typography>
                    <Typography variant="h5" component="div" sx={{ mb: 1.8 }}>
                        {sendingCount.sentCount}
                    </Typography>
                    <Typography sx={{ mb: 1.5, fontSize: 14 }}>
                        <b>Pending Send</b>
                    </Typography>
                    <Typography sx={{ mb: 1.5, fontSize: 14 }}>
                        {sendingCount.pendingSend}
                    </Typography>
                </CardContent>

            </Card>



            <Modal
                keepMounted
                open={openWhatshappModel}
                onClose={handleCloseWhatshapp}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={styles}>
                    <Typography
                        sx={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            color: "#000",
                            marginBottom: "15px",
                        }}
                    >
                        Send Message
                    </Typography>
                    <label className="fontLight">
                        Are you sure want to send message on whatshapp
                    </label>
                    <Grid container style={{ marginTop: "2rem" }}>
                        <Grid item xs={9} className="text-end">
                            <Controls.Button
                                style={{ backgroundColor: "#fff", color: "#000" }}
                                text="No"
                                onClick={handleCloseWhatshapp}
                            />
                        </Grid>
                        <Grid item xs={3} className="text-end">
                            <Controls.Button
                                style={{ marginRight: "7px" }}
                                text="Yes"
                                onClick={() => handleSubmit()}
                                type="submit"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Notification notify={notify} setNotify={setNotify} />

        </div>
    )
}

export default WhatshappMessageSend
