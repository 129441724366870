import { Box } from "@mui/system";
import React from "react";
import BuyerList from "../Buyer/BuyerList";

const Buyer = () => {
  return (
    <Box>
      <BuyerList />
    </Box>
  );
};
export default Buyer;
