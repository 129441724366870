import React from "react";
import { Box, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";

function CategoryModule() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData(
      "Dollie Bullock",
      "abbott@withinpixels.com",
      "+1-202-555-0175",
      "Saois"
    ),
  ];

  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Typography
          sx={{
            paddingTop: "25px",
            paddingBottom: "15px",
            fontSize: "15px",
            fontWeight: "600",
            color: "#000",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/product");
          }}
        >
          <KeyboardBackspaceIcon
            sx={{ verticalAlign: "top", marginRight: "5px" }}
          />
          Order
        </Typography>
        <Grid container alignItems="top" style={{ marginBottom: "20px" }}>
          <Grid item sm={12} xs={12}>
            <Box display="flex">
              <Box>
                <img
                  src={"../../assets/images/product-image.png"}
                  style={{ borderRadius: "5px" }}
                />
              </Box>
              <Box sx={{ marginLeft: "10px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                >
                  Order Detail
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    marginTop: "3px",
                  }}
                >
                  Detail
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: "rgb(241, 245, 249)" }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderTopRightRadius: "25px",
            borderTopLeftRadius: "25px",
            border: 0,
            borderColor: "#eaeaea",
            paddingLeft: "0px",
            paddingTop: "10px",
          }}
        >
          <Box
            sx={{
              marginTop: "15px",
              marginLeft: { xs: "15px", md: "25px", lg: "25px" },
            }}
          >
            <Box
              sx={{
                maxWidth: {
                  md: "600px",
                  lg: "1200px",
                },
              }}
            >
              <Grid container spacing={8} style={{ marginBottom: "30px" }}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      marginBottom: "20px",
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Buyer Detail :
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        User Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        ryhghfg
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Company Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        ryhghfg
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Email :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        dsfidgg
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Phone Number :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        09845489
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      marginBottom: "20px",
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Seller Detail :
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        User Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        ryhghfg
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Company Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        ryhghfg
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Email :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        dsfidgg
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Phone Number :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        09845489
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                sx={{
                  borderTop: 0,
                  borderColor: "#eaeaea",
                  paddingTop: "10px",
                }}
              ></Divider>
              <Box
                gap={7}
                sx={{
                  marginBottom: "30px",
                  marginTop: "30px",
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  marginRight: "10px",
                }}
              >
                <Box
                  sx={{
                    maxWidth: {
                      md: "300px",
                      lg: "300px",
                    },
                    marginBottom: { xs: "15px", md: "0", lg: "0" },
                  }}
                >
                  <Typography
                    sx={{
                      paddingBottom: "3px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Shipping Address :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    377 E South Omaha Bridge Rd, Council Bluffs, IA 51501, USA
                  </Typography>
                </Box>
                <Box
                  sx={{
                    maxWidth: {
                      md: "300px",
                      lg: "300px",
                    },
                    marginBottom: { xs: "15px", md: "0", lg: "0" },
                  }}
                >
                  <Typography
                    sx={{
                      paddingBottom: "3px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Invoice Address :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    377 E South Omaha Bridge Rd, Council Bluffs, IA 51501, USA
                  </Typography>
                </Box>
                <Box sx={{ marginBottom: { xs: "15px", md: "0", lg: "0" } }}>
                  <Typography
                    sx={{
                      paddingBottom: "3px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                      marginBottom: { xs: "5px", md: "10px", lg: "10px" },
                    }}
                  >
                    Payment Status :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{
                        ":hover": { bgcolor: "#4caf50" },
                        bgcolor: "#4caf50",
                        paddingLeft: "10px",
                        paddingBottom: "5px",
                        paddingTop: "5px",
                        color: "#000",
                        fontSize: "13px",
                        fontWeight: "500",
                        borderRadius: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#fff",
                        }}
                      >
                        Paid
                      </Typography>
                    </Button>
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      paddingBottom: "3px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Date :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    22/02/2023
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                maxWidth: {
                  lg: "1250px",
                },
              }}
            >
              <Box
                sx={{
                  marginTop: "40px",
                  marginBottom: "20px",
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",
                  border: 1,
                  borderColor: "#eaeaea",
                  marginRight: "20px",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: 0, paddingLeft: "0!important" }}
                >
                  <Table
                    aria-label="simple table"
                    sx={{ paddingLeft: "0!important" }}
                  >
                    <TableHead sx={{ paddingLeft: "0!important" }}>
                      <TableRow
                        sx={{
                          backgroundColor: "#f6f9fb",
                          paddingLeft: "0!important",
                        }}
                      >
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          ID
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Image
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Quantity
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        backgroundColor: "#fff",
                        paddingLeft: "0!important",
                      }}
                    >
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          1
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          <Box>
                            <img
                              width="50px"
                              height="50px"
                              src={"../../assets/images/bg.jpg"}
                              style={{ borderRadius: "5px" }}
                            />
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          Walk Amongst Canvas Print
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          1023.00
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          3 MT
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box
                sx={{
                  marginRight: "20px",
                  marginBottom: "20px",
                }}
              >
                <Box
                  sx={{
                    marginLeft: "auto",
                    maxWidth: "250px",
                  }}
                >
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      sx={{
                        paddingBottom: "3px",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "#000",
                      }}
                    >
                      Amount
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000c7",
                      }}
                    >
                      &#8377; 1020
                    </Typography>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      sx={{
                        paddingBottom: "3px",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "#000",
                      }}
                    >
                      IGST
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000c7",
                      }}
                    >
                      &#8377; 1020
                    </Typography>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      sx={{
                        paddingBottom: "3px",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "#000",
                      }}
                    >
                      CGST
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000c7",
                      }}
                    >
                      &#8377; 1020
                    </Typography>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      sx={{
                        paddingBottom: "3px",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "#000",
                      }}
                    >
                      SGST
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000c7",
                      }}
                    >
                      &#8377; 1020
                    </Typography>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      sx={{
                        paddingBottom: "3px",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "#000",
                      }}
                    >
                      TDS
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000c7",
                      }}
                    >
                      &#8377; 1020
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    sx={{
                      borderTop: 1,
                      borderColor: "#eaeaea",
                      paddingLeft: "2px",
                      paddingTop: "2px",
                      marginTop: "6px",
                    }}
                  >
                    <Typography
                      sx={{
                        paddingBottom: "3px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#000",
                        marginTop: "7px",
                      }}
                    >
                      Total
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000c7",
                        marginTop: "7px",
                      }}
                    >
                      &#8377; 1020
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CategoryModule;
