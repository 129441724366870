import React, { useEffect, useState } from "react";
import { Modal, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../theme";
import { InputBase } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    Box,
    Grid,
    Pagination,
} from "@mui/material";
import Controls from "../../Component/controls/Controls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from "@mui/material/styles";
import { Space } from "antd";
import {
    deleteIncomplateUser,
    incompletedList,
} from "../../state/action/userAction";
import moment from "moment";
import { useSnackbar } from "notistack";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#f6f9fb",
        color: "#000",
        fontSize: 14,
        border: 0,
        fontWeight: 600,
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingLeft: "25px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: "rgb(17, 24, 39)",
        border: 0,
        paddingLeft: "25px",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
        backgroundColor: "#fff",
        ":hover": { backgroundColor: "rgb(248 248 248)!important" },
    },
    "&:nth-of-type(even)": {
        backgroundColor: "rgb(250 250 250)!important",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "0",
    outline: "0",
    bgcolor: "#fff",
    borderRadius: "10px",
    p: 3,
};

export default function Incompleteusers() {
    const [userList, setUserList] = useState([]);
    const [pagePerSize, setPagePerSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [deleteModel, setDeleteModel] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const getAllRequest = async () => {
        let queryString = `?page=${page}&pagePerSize=${pagePerSize}&mobile=${search}`;
        const res = await incompletedList(queryString);
        if (res.status === true) {
            setUserList(res.data);
            setTotalRecords(res.totalUser);
            setTotalPages(Math.ceil(res.totalUser / pagePerSize));
        } else {
            setUserList([]);
            setTotalRecords(0);
            setTotalPages(0);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const HandleDeleteUser = (id) => {
        if (id) {
            setDeleteUserId(id)
            setDeleteModel(true)
        }
    }

    const confirmDelete = async () => {
        if (deleteUserId) {
            const resDelet = await deleteIncomplateUser(deleteUserId)
            if (resDelet.status) {
                setDeleteModel(false);
                setDeleteUserId(null);
                getAllRequest()
                enqueueSnackbar(resDelet.message, {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            } else {
                enqueueSnackbar(resDelet.message, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
            }
        } else {
            enqueueSnackbar("Something goes wrong", {
                variant: "error",
                autoHideDuration: 2000,
            });
        }
    }

    useEffect(() => {
        getAllRequest();
    }, [page, search])

    return (
        <>
            <Box sx={{ backgroundColor: "rgb(241, 245, 249)" }}>
                <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
                    <Grid
                        container
                        style={{
                            paddingTop: "40px",
                            paddingBottom: "40px",
                            alignItems: "center",
                        }}
                    >
                        <Grid item sm={4} xs={12}>
                            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                                User Incomplete
                            </Typography>
                        </Grid>
                        <Grid item sm={8} xs={12}>
                            <Box
                                sx={{
                                    display: { xs: "flex", sm: "flex", md: "flex", lg: "flex" },
                                    marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" },
                                }}
                                justifyContent="flex-end"
                            >
                                <Box
                                    border={1}
                                    borderColor="rgba(0, 0, 0, .1)"
                                    alignItems="center"
                                    display="flex"
                                    backgroundColor="#ffffffa3"
                                    p={0.4}
                                    borderRadius={10}
                                >
                                    <SearchIcon
                                        style={{
                                            color: colors.grey[300],
                                        }}
                                        sx={{ ml: 1, fontSize: "24px" }}
                                    />
                                    <InputBase
                                        name="search"
                                        sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                                        placeholder="Search"
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                            setPage(1);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: "100%", backgroundColor: "rgb(241, 245, 249)" }}>
                    <Box
                        sx={{
                            backgroundColor: "#fff",
                            borderTopRightRadius: "20px",
                            borderTopLeftRadius: "20px",
                            border: 1,
                            borderColor: "#eaeaea",
                            maxHeight: "485px",
                            overflow: "scroll",
                        }}
                    >
                        <TableContainer component={Paper} sx={{ boxShadow: "0" }}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow hover>
                                        <StyledTableCell>
                                            User Name
                                        </StyledTableCell>
                                        <StyledTableCell >
                                            Email
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            Mobile Number
                                        </StyledTableCell>
                                        <StyledTableCell>Date</StyledTableCell>

                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userList.map((row, index) => (
                                        <StyledTableRow
                                            hover
                                            key={index}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <StyledTableCell component="th" scope="row">
                                                {row.userName || '-'}
                                            </StyledTableCell>
                                            <StyledTableCell>{row.email || '-'}</StyledTableCell>
                                            <StyledTableCell>{row.mobile}</StyledTableCell>
                                            <StyledTableCell>
                                                {moment(row.createdAt).format("MM-DD-YYYY LT")}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" onClick={(e) => e.stopPropagation()}>
                                                <Space size="middle">
                                                    <Controls.Button
                                                        text={<DeleteIcon />}
                                                        sx={{
                                                            fontSize: "11px",
                                                            fontWeight: "500",
                                                            color: "#fff",
                                                            backgroundColor: "rgb(239, 83, 80)",
                                                            ":hover": { bgcolor: "rgb(239, 83, 80)" },
                                                            textTransform: "none",
                                                            borderRadius: "50px",
                                                            boxShadow: 0,
                                                        }}
                                                        onClick={() => HandleDeleteUser(row.id)}
                                                    />
                                                </Space>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
            {userList && userList.length === 0 ? (
                <div className="noDataFound"> No Data Found</div>
            ) : (
                <Box
                    display="flex"
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        mx: 3,
                        mt: 2,
                    }}
                >
                    <Box
                        sx={{
                            fontSize: "14px",
                            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                        }}
                    >
                        {" "}
                        Showing{" "}
                        {page * pagePerSize > totalRecords
                            ? totalRecords
                            : page * pagePerSize}{" "}
                        of {totalRecords} Results
                    </Box>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        sx={{
                            "& .MuiButtonBase-root ": {
                                color: "#000 !important",
                            },
                            "& .Mui-selected ": {
                                background: "#4b4b4e70 !important",
                            },
                            "& .Mui-disabled ": {
                                background: "#6c696966 !important",
                                color: "#000000 !important",
                                opacity: 1,
                            },
                        }}
                    />
                </Box>
            )}

            <Modal
                // keepMounted
                open={deleteModel}
                onClose={() => setDeleteModel(false)}
            >
                <Box sx={styles}>
                    <Typography
                        sx={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            color: "#000",
                            marginBottom: "15px",
                        }}
                    >
                        Are you sure to delete user?
                    </Typography>

                    <Grid container style={{ marginTop: "2rem" }}>

                        <Grid item xs={9} className="text-end">
                            <Controls.Button
                                style={{ backgroundColor: "#fff", color: "#000" }}
                                text="Cancel"
                                onClick={() => setDeleteModel(false)}
                            />
                        </Grid>
                        <Grid item xs={3} className="text-end">
                            <Controls.Button
                                style={{ marginRight: "7px" }}
                                text="Confirm"
                                onClick={() => confirmDelete()}
                                type="button"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    )

}