import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import {
  sellerorderPoDetails,
} from "../../state/action/orderAction";
import moment from "moment";
import Logo from "../../assets/logoinvoice.png";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";



function SellerOrderDetails() {


  const [value, setValue] = useState("1");
  const [sellerOrderDetail, setSellerOrderDetail] = useState([]);
  const [productData, setProductdata] = useState([]);
  const [ordersData, setOrdersdata] = useState([]);
  const [sellercomapny, setSellerComapny] = useState([]);
  const [buyercomapny, setBuyerComapny] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [bankDetail, setBankDetail] = useState([]);
  const [city, setCity] = useState([]);
  const [states, setStates] = useState([]);
  const [terms, setTerms] = useState([]);

  const pdfExportComponent = useRef(null);

  const downloadPdf = async () => {
    if (pdfExportComponent.current) {
      // navigate(-1);
      //how to change download pdf file name
      const fileName = "ORDEREEEE"
      // await pdfExportComponent.current.save(fileName + ".pdf" + "?download=true");
      await pdfExportComponent.current.save({
        fileName: fileName + '.pdf',
        paperSize: 'A4',
      });
    }
  };


  const navigate = useNavigate();

  const { state } = useLocation();

  useEffect(() => {
    getInvoiceDetails(state);
  }, [state]);

  const getInvoiceDetails = async () => {
    const res = await sellerorderPoDetails(state);
    if (res && res.status) {
      setOrderDetail(res?.data?.userData);
      setSellerOrderDetail(res?.data?.sellerData);
      setProductdata(res?.data?.productData);
      setOrdersdata(res?.data?.sellerQuoteData);
      setSellerComapny(res?.data?.sellerCompanys);
      setBuyerComapny(res?.data?.usercompanyData);
      setBankDetail(res?.data?.getBankDetails);
      setStates(res.data?.stateData);
      setCity(res.data.cityData);
      setTerms(res.data?.gettnc);
    }
  };

  let a = ordersData?.totalQty * ordersData?.price;



  const amount = ordersData?.amount
    ? ordersData?.amount.toLocaleString("en-IN", {
      // style: "currency",
      currency: "INR",
    })
    : "";

  return (
    <>
      <Box sx={{ overflowY: "hidden !imporatant" }}>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            PO
          </Typography>
          <Grid container alignItems="top" style={{ marginBottom: "20px" }}>
            <Grid item sm={7} xs={12}>
              <Box display="flex">
                <Box style={{
                  width: "50px", height: "50px"
                }}>
                  <img
                    src={"../../assets/images/product-image.png"}
                    style={{ borderRadius: "5px", }}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    PO Detail
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#000",
                      marginTop: "3px",
                    }}
                  >
                    Detail
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item sm={5} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Controls.Button
                  text="Download Po"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    backgroundColor: "rgb(79, 70, 229)",
                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                    textTransform: "none",
                    borderRadius: "50px",
                    boxShadow: 0,
                  }}
                  onClick={() => {
                    downloadPdf();
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <PDFExport
          ref={pdfExportComponent}
          paperSize="A4"
          scale={0.7}
          fileName={`${sellercomapny.companyName}_${ordersData?.ponumber}`} // userName
        >
          <Box
            sx={{
              border: "3px solid #black",
            }}
          >
            <Box
              sx={{
                backgroundColor: "rgb(241, 245, 249)",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderTopRightRadius: "25px",
                  borderTopLeftRadius: "25px",
                  border: 0,
                  borderColor: "#eaeaea",
                  paddingLeft: "0px",
                  paddingTop: "10px",
                }}
              >
                <Box
                  sx={{
                    marginTop: "30px",
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: {
                        md: "600px",
                        lg: "1200px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        marginBottom: "20px",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    ></Typography>
                    <Box
                      sx={{
                        border: "3px solid #black",
                      }}
                    >
                      <Grid
                        container
                        spacing={8}
                        style={{ marginBottom: "30px" }}
                      >
                        <Grid item sm={6} xs={12}>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ marginLeft: "35px" }}
                            >
                              <img
                                src={Logo}
                                alt="logo"
                                style={{
                                  width: "250px",
                                  height: "90px",
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: "22px",
                                lineHeight: "31px",
                                color: "black",
                                marginTop: "20px",
                                marginLeft: "41px",
                              }}
                            >
                              PURI POLYMERS
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginLeft: "41px",
                              }}
                            >
                              Behind Divya Bhaskar Office, C-602 Sixth Floor,
                              Titanium Business Park, Wing C, Makarba Road, Makarba,
                              Ahmedabad, Ahmedabad, Gujarat, 382210
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginLeft: "41px",
                              }}
                            >
                              email : puripolymers@gmail.com
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ paddingTop: "0px !important" }}
                          >
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginLeft: "41px",
                              }}
                            >
                              GSTIN :24ABDFP2006M1ZF
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} style={{ paddingRight: '50px' }}>
                          <Grid item sm={12} xs={12}>
                            <Typography
                              sx={{
                                fontSize: "23px",
                                fontWeight: "Bold",
                                color: "#000",
                                marginTop: "13px",
                                marginBottom: "14px",
                                // display: "flex",
                                // justifyContent: "center",
                              }}
                            >
                              Purchase Order
                            </Typography>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ paddingTop: "0px !important" }}
                            ></Grid>

                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ paddingTop: "0px !important" }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                  color: "black",
                                  // display: "flex",
                                  // justifyContent: "center",
                                  // marginRight: "64px",
                                  fontWeight: 600,
                                  color: "black"
                                }}
                              >
                                Order No. :{" "}
                                {ordersData.ponumber}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ paddingTop: "0px !important" }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                  color: "black",
                                  // display: "flex",
                                  // justifyContent: "center",
                                  // marginRight: "64px",
                                }}
                              >
                                Order Date:{" "}
                                {moment(ordersData.createdAt).format(
                                  "DD-MM-YYYY"
                                )}
                              </Typography>
                            </Grid>


                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ paddingTop: "0px !important" }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                  color: "black",
                                  // display: "flex",
                                  // justifyContent: "center",
                                  // marginRight: "9px",
                                }}
                              >
                                Location: {city.name}, {states.name}
                              </Typography>
                            </Grid>

                            {ordersData?.paymentTerms && (<Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ paddingTop: "0px !important" }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                  color: "black",
                                  // display: "flex",
                                  // justifyContent: "center",
                                  // marginRight: "9px",
                                }}
                              >
                                Payment Terms: {ordersData.paymentTerms}
                              </Typography>
                            </Grid>)}


                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container spacing={8}>
                        <Grid item sm={6}>
                          <Grid container>
                            <Grid item sm={12} xs={12}>

                              <Typography
                                variant="h6"
                                style={{
                                  fontStyle: "normal",
                                  // fontWeight: "bold",
                                  fontSize: "17px",
                                  lineHeight: "26px",
                                  color: "black",
                                  marginLeft: "41px",
                                }}
                              >
                                Supplier
                              </Typography>
                            </Grid>
                            <Grid item sm={12} xs={12}>

                              <Typography
                                variant="h6"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "bold",
                                  fontSize: "18px",
                                  lineHeight: "27px",
                                  color: "black",
                                  // marginTop: "20px",
                                  marginLeft: "41px",
                                }}
                              >
                                {sellercomapny?.companyName}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ paddingTop: "0px !important" }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                  color: "black",
                                  marginLeft: "41px",
                                }}
                              >
                                {sellercomapny?.address}
                              </Typography>
                            </Grid>
                            {sellerOrderDetail && sellerOrderDetail?.email ? (
                              <Grid
                                item
                                sm={12}
                                xs={12}
                                sx={{ paddingTop: "0px !important" }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    lineHeight: "25px",
                                    color: "black",
                                    marginLeft: "41px",
                                  }}
                                >
                                  email : {sellerOrderDetail?.email}
                                </Typography>
                              </Grid>
                            ) : ("")}
                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ paddingTop: "0px !important" }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                  color: "black",
                                  marginLeft: "41px",
                                }}
                              >
                                GSTIN : {sellercomapny?.gstNumber}
                              </Typography>
                            </Grid>

                          </Grid>
                        </Grid>
                        <Grid item sm={6}>
                          <Grid container>
                            {ordersData && ordersData.shipTo && (
                              <Grid item sm={12} xs={12}>
                                <Grid item sm={12} xs={12}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontStyle: "normal",
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      lineHeight: "29px",
                                      color: "black",

                                      // display: "flex",
                                      // justifyContent: "center",
                                      // marginRight: "110px",
                                    }}
                                  >
                                    Ship To
                                  </Typography>
                                </Grid>

                                <Grid item sm={12} xs={12}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      fontSize: "16px",
                                      lineHeight: "25px",
                                      color: "black",
                                      // marginTop: "-7px",
                                      // display: "flex",
                                      // justifyContent: "center",
                                      // marginRight: "53px",
                                    }}
                                  >
                                    {ordersData?.shipTo}

                                  </Typography>
                                </Grid>
                              </Grid>)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        maxWidth: {
                          lg: "1250px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          marginTop: "40px",
                          marginBottom: "20px",
                          border: 1,
                          borderColor: "#eaeaea",
                          marginRight: "20px",
                          width: "90%",
                          justifyContent: "center",
                          display: "flex",
                          marginLeft: "40px",
                        }}
                      >
                        <TableContainer
                          component={Paper}
                          sx={{ boxShadow: 0, paddingLeft: "0!important" }}
                        >
                          <Table
                            aria-label="simple table"
                            sx={{ paddingLeft: "0!important", minWidth: 600 }}
                          >
                            <TableHead sx={{ paddingLeft: "0!important" }}>
                              <TableRow
                                sx={{
                                  backgroundColor: "#f6f9fb",
                                  paddingLeft: "0!important",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                    width: "100px",
                                  }}
                                >
                                  Sr.No
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                    width: "200px",
                                  }}
                                >
                                  Item
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  HSN/SAC
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                  }}
                                >
                                  Quantity(MT)
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    borderColor: "rgba(107, 114, 128, .4)",
                                    textAlign: 'end !important',
                                  }}
                                >
                                  Rate(Kg)
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody
                              sx={{
                                backgroundColor: "#fff",
                                paddingLeft: "0!important",
                              }}
                            >
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "none",
                                    width: "100px",
                                  }}
                                >
                                  1
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "none",
                                    width: "200px",
                                  }}
                                >
                                  {productData?.productName}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "none",
                                  }}
                                >
                                  {productData && productData?.hsn
                                    ? productData?.hsn
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "none",
                                  }}
                                >
                                  {ordersData?.qty}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    color: "#000",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textTransform: "none",
                                    textAlign: 'end !important',
                                  }}
                                >
                                  {ordersData?.price}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>

                      <Box
                        sx={{
                          marginRight: "40px",
                          marginBottom: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            marginLeft: "auto",
                            maxWidth: "250px",
                          }}
                        >
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <Typography
                              sx={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              Amount
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#000000c7",
                                marginRight: '2px'
                              }}
                            >
                              INR {amount}
                            </Typography>
                          </Box>
                          {ordersData?.sgst != 0 ? (
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                sx={{
                                  paddingBottom: "3px",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  color: "#000",
                                }}
                              >
                                SGST @ 9%
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#000000c7",
                                  marginRight: '0px'
                                }}
                              >
                                {ordersData?.sgst}
                              </Typography>
                            </Box>
                          ) : null}
                          {ordersData?.cgst != 0 ? (
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                sx={{
                                  paddingBottom: "3px",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  color: "#000",
                                }}
                              >
                                CGST @ 9%
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#000000c7",
                                  marginRight: '0px'
                                }}
                              >
                                {ordersData?.cgst}
                              </Typography>
                            </Box>
                          ) : null}
                          {ordersData?.igst != 0 ? (
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <Typography
                                sx={{
                                  paddingBottom: "3px",
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  color: "#000",
                                }}
                              >
                                IGST @18%
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#000000c7",
                                  marginRight: '0px'
                                }}
                              >
                                {ordersData?.igst}
                              </Typography>
                            </Box>
                          ) : null}

                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            {ordersData?.tcs != "0.00" ? (
                              <>
                                <Typography
                                  sx={{
                                    paddingBottom: "3px",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    color: "#000",
                                  }}
                                >
                                  TCS
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#000000c7",
                                    marginRight: '2px'
                                  }}
                                >
                                  + {ordersData?.tcs}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography
                                  sx={{
                                    paddingBottom: "3px",
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    color: "#000",
                                  }}
                                >
                                  TDS
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "#000000c7",
                                    marginRight: '2px'
                                  }}
                                >
                                  - {ordersData?.tds}
                                </Typography>
                              </>
                            )}
                          </Box>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                              borderTop: 1,
                              borderColor: "#eaeaea",
                              paddingLeft: "2px",
                              paddingTop: "2px",
                              marginTop: "6px",
                            }}
                          >
                            <Typography
                              sx={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#000",
                                marginTop: "7px",
                              }}
                            >
                              Total
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#000000c7",
                                marginTop: "7px",
                                marginRight: '2px'
                              }}
                            >
                              INR {ordersData?.total}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Grid item sm={6} xs={12} sx={{ paddingLeft: "10px" }}>
                        <Grid container spacing={2}>
                          <Grid item sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginTop: "20px",
                                marginLeft: "30px",
                              }}
                            >
                              Terms & Conditions
                            </Typography>

                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "23px",
                                color: "black",
                                marginLeft: "30px",
                              }}
                            >
                              {/* <div
                                dangerouslySetInnerHTML={{
                                  __html: terms,
                                }}
                              /> */}
                              {terms.description1} <br />
                              {terms.description2} <br />
                              {terms.description3} <br />
                              {terms.description4} <br />
                              {terms.description5}
                            </Typography>
                          </Grid>

                          {ordersData?.remarks && (<Grid item sm={12} xs={12}>
                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "bold",
                                fontSize: "16px",
                                lineHeight: "25px",
                                color: "black",
                                marginLeft: "30px",
                              }}
                            >
                              Remarks
                            </Typography>

                            <Typography
                              variant="h6"
                              style={{
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "23px",
                                color: "black",
                                marginLeft: "30px",
                              }}
                            >
                              {ordersData?.remarks}

                            </Typography>
                          </Grid>)}



                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                              marginTop: "20px",
                              marginBottom: "10px",
                              marginLeft: "45px",
                            }}
                          >
                            * This is computer generated document, does not require any signature.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </PDFExport >
      </Box >
    </>
  );
}

export default SellerOrderDetails;
