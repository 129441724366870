import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Slide,
  styled,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import { sellerDetails } from "../../state/action/userAction";

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SellerDetail() {
  const [value, setValue] = useState("1");
  const [seller, setSeller] = useState([]);
  const [company, setCompany] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const { state } = useLocation();


  const getDetails = async (id) => {
    const res = await sellerDetails(state?.seller);
    if (res && res.status) {
      setSeller(res?.data);
      setCompany(res?.data?.company);
    }
  };
  useEffect(() => {
    getDetails(state?.seller);
  }, [state]);



  return (
    <>
      <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/seller");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Seller
          </Typography>
          <Grid container alignItems="top" style={{ marginBottom: "20px" }}>
            <Grid item sm={7} xs={12}>
              <Box display="flex">
                <Box>
                  <img
                    src={"../../assets/images/product-image.png"}
                    style={{ borderRadius: "5px" }}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                  >
                    {company[0]?.companyName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#000",
                      marginTop: "3px",
                    }}
                  >
                    Detail
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item sm={5} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Controls.Button
                  text=" Edit"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    backgroundColor: "rgb(79, 70, 229)",
                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                    textTransform: "none",
                    borderRadius: "50px",
                    boxShadow: 0,
                  }}
                  onClick={() => {
                    navigate("/sellerEdit", {
                      state: {
                        seller: state?.seller,
                        companyId: state?.companyId,
                      },
                    });
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
            overflow: "scroll",
            height: "500px",
            scrollbarWidth: "thin",
            boxShadow: "insert 0 0 5px gray",
            borderRedius: "10px",
            background: "#f1f1f1",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderTopRightRadius: "25px",
              borderTopLeftRadius: "25px",
              border: 0,
              borderColor: "#eaeaea",
              paddingLeft: "0px",
              paddingTop: "10px",
            }}
          >
            <Box
              sx={{
                marginTop: "15px",
                marginLeft: { xs: "15px", md: "25px", lg: "25px" },
              }}
            >
              <Box
                sx={{
                  maxWidth: {
                    md: "600px",
                    lg: "900px",
                  },
                }}
              >
                <Grid container spacing={8} style={{ marginBottom: "30px" }}>
                  <Grid item sm={6} xs={12}>
                    <Typography
                      sx={{
                        marginBottom: "20px",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    >
                      Personal Detail :
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <Typography
                          sx={{
                            paddingBottom: "3px",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          User Name :
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#000000c7",
                          }}
                        >
                          {seller?.userName}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography
                          sx={{
                            paddingBottom: "3px",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          Email :
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#000000c7",
                          }}
                        >
                          {seller?.email}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography
                          sx={{
                            paddingBottom: "3px",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          Phone Number :
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#000000c7",
                          }}
                        >
                          {seller?.mobile}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  maxWidth: {
                    md: "600px",
                    lg: "950px",
                  },
                }}
              >
                <Grid container spacing={0} style={{ marginBottom: "30px" }}>
                  <Grid item md={12} xs={12}>
                    <Box display="flex" gap={3} sx={{ marginTop: "35px" }}>
                      {company?.map((row) => {
                        return (
                          <>
                            <Grid item md={6} xs={12}>
                              <Typography
                                sx={{
                                  marginBottom: "20px",
                                  fontSize: "20px",
                                  fontWeight: "600",
                                  color: "#000",
                                }}
                              >
                                Company Detail :
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    sx={{
                                      paddingBottom: "3px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000",
                                    }}
                                  >
                                    Company Name :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {row?.companyName}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    sx={{
                                      paddingBottom: "3px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000",
                                    }}
                                  >
                                    Address :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {row?.address}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    sx={{
                                      paddingBottom: "3px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000",
                                    }}
                                  >
                                    GST Number :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {row?.gstNumber}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    sx={{
                                      paddingBottom: "3px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000",
                                    }}
                                  >
                                    Pan Number :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {row?.panNumber}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    sx={{
                                      paddingBottom: "3px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000",
                                    }}
                                  >
                                    State Name :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {seller?.stateName}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                  <Typography
                                    sx={{
                                      paddingBottom: "3px",
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      color: "#000",
                                    }}
                                  >
                                    City Name :
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#000000c7",
                                    }}
                                  >
                                    {seller?.cityName}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <Box
                                display="flex"
                                gap={3}
                                sx={{
                                  marginTop: "35px",
                                  marginBottom: {
                                    xs: "20px",
                                    md: "0px",
                                    lg: "0px",
                                  },
                                }}
                              >
                                <Box>
                                  <Box>
                                    <Typography
                                      sx={{
                                        paddingBottom: "3px",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        color: "#000",
                                      }}
                                    >
                                      PanImage
                                    </Typography>
                                    <img
                                      width="150px"
                                      height="150px"
                                      src={row?.panImage}
                                      style={{ borderRadius: "5px" }}
                                      onClick={handleClickOpen}
                                    />
                                  </Box>
                                </Box>
                                <Box>
                                  <Box>
                                    <Typography
                                      sx={{
                                        paddingBottom: "3px",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        color: "#000",
                                      }}
                                    >
                                      Gst Cetificate
                                    </Typography>
                                    <Box sx={{ textAlign: "end" }}>
                                      <StyledIconWrapper
                                        style={{
                                          // margin: "0",
                                          display: "flex",
                                          justifyContent: "center",
                                          background: "none",
                                          border: "none",
                                          width: "auto",
                                          height: "50px",
                                          // marginTop:""
                                        }}
                                      >
                                        <a
                                          href={row?.gstCertificate}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <Controls.Button
                                            text="PDF"
                                            sx={{
                                              fontSize: "11px",
                                              fontWeight: "500",
                                              color: "#fff",
                                              backgroundColor:
                                                "rgb(79, 70, 229)",
                                              ":hover": {
                                                bgcolor: "rgb(79, 70, 229)",
                                              },
                                              textTransform: "none",
                                              borderRadius: "50px",
                                              boxShadow: 0,
                                            }}
                                          />
                                        </a>
                                      </StyledIconWrapper>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>

                              <Dialog
                                open={open}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                aria-describedby="alert-dialog-slide-description"
                              >
                                <DialogContent>
                                  <img
                                    width="40%"
                                    height="28%"
                                    style={{ marginLeft: "158px" }}
                                    src={row?.panImage}
                                    // style={{ borderRadius: "5px" }}
                                    alt=""
                                  // onClick={handleClickOpen}
                                  />
                                </DialogContent>
                              </Dialog>
                            </Grid>
                          </>
                        );
                      })}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SellerDetail;
