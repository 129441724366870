import React from 'react'
import { useTheme, Box, Grid, IconButton, InputBase, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import Controls from "../../Component/controls/Controls";
import CardMedia from "@mui/material/CardMedia";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function Login() {

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (

    <Box sx={{
      backgroundColor: "#1e293b",
      height: { xs: "100%", sm: "100vh", md: "100vh" },
      display: 'flex',
      alignItems: 'center',
    }}>


      <Box sx={{
        width: '900px',
        margin: '0 auto',
        backgroundColor: "#fff",
        borderRadius: '20px'
      }}>

        <Grid container item columns={{ xs: 4, sm: 12, md: 12 }} spacing={1} sx={{
          alignItems: 'center'
        }} >
          <Grid item xs={6} md={6}>
            <Box sx={{
              padding: { xs: "20px", md: "40px" },
            }}>
              <CardMedia
                sx={{ maxWidth: '50px', marginBottom: '20px' }}
                component="img"

                image="../../assets/images/logo.png"
                alt="Paella dish"
              />
              <Typography sx={{ fontSize: "35px", fontWeight: "bold", color: '#000' }}>
                Sign Up
              </Typography>
              <Typography sx={{ fontSize: "16px", color: '#000', marginBottom: '15px' }}>
                Already have an account? <Link href="/Login" sx={{ fontSize: "16px", fontWeight: "medium", color: 'blue' }}>Sign In</Link>
              </Typography>
              <form>
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Name"
                />
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Email"
                />
                <FormControl sx={{ marginTop: '10px', width: '100%' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password" sx={{ color: '#000', bgcolor: '#fff' }}>New Password</InputLabel>
                  <OutlinedInput
                    sx={{ paddingTop: '0px', color: '#000' }}
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }

                  />
                </FormControl>
                <FormControl sx={{ marginTop: '10px', width: '100%' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password" sx={{ color: '#000', bgcolor: '#fff' }}>Confirm Password</InputLabel>
                  <OutlinedInput
                    sx={{ paddingTop: '0px', color: '#000' }}
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }

                  />
                </FormControl>

              </form>
              <Grid container item xs={12} spacing={1} style={{ marginTop: "1rem", alignItems: 'center' }}>
                <Grid item xs={10}>
                  <Controls.Checkbox
                    sx={{ fontSize: '20px' }}
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="I agree to the Terms of Service and Privacy Policy"
                  />
                </Grid>

              </Grid>
              <Button

                sx={{
                  ":hover": { bgcolor: "rgb(79, 70, 229)" },
                  marginTop: '20px',
                  textTransform: "none",
                  fontSize: "16px",
                  color: '#fff',
                  width: '100%',
                  backgroundColor: "rgb(79, 70, 229)",
                  borderRadius: "15px",
                  padding: "10px 40px",
                }}
              >Create Account</Button>
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box className="login_img" >
              <CardMedia
                sx={{ maxWidth: '420px', marginLeft: "auto", padding: '10px' }}
                component="img"

                image="../../assets/images/register-img.jpg"
                alt="Paella dish"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>

  )
}

export default Login