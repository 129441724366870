// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useState } from "react";
import { Menu, Sidebar, MenuItem, SubMenu } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";
import { Link, Navigate } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SwitchRightOutlinedIcon from "@mui/icons-material/SwitchRightOutlined";
import SwitchLeftOutlinedIcon from "@mui/icons-material/SwitchLeftOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import { useNavigate } from "react-router-dom";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SettingsIcon from "@mui/icons-material/Settings";
import SendIcon from '@mui/icons-material/Send';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
//whatshapp icon import

import WhatshotIcon from "@mui/icons-material/Whatshot";

import Logo from "../../../assets/Logo.png";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Navigate = useNavigate();

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => {
        setSelected(title);
        Navigate(to);
      }}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const MyProSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();

  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-item": {
          padding: "5px 10px 5px 10px !important",
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-item:hover": {
          color: `#fff!important`,
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-item.active": {
          color: `#fff!important`,
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
      }}
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={colors.primary[600]}
        image={sidebarImage}
      >
        <Menu iconshape="square">
          <MenuItem
            icon={
              collapsed ? (
                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
              ) : sidebarRTL ? (
                <SwitchLeftOutlinedIcon
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              ) : (
                <SwitchRightOutlinedIcon
                  onClick={() => setSidebarRTL(!sidebarRTL)}
                />
              )
            }
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" sx={{ color: "#fff" }}>
                  <img src={Logo} alt="" style={{ width: "95px" }} />
                </Typography>
                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box padding={collapsed ? undefined : "0% 3%"}>
            <Typography
              variant="h6"
              color={colors.blue[500]}
              fontWeight="600"
              sx={{ m: "20px 20px 5px 20px", textTransform: "uppercase" }}
            >
              Pages
            </Typography>

            <SubMenu
              label="Manage Users"
              sx={{ backgroundColor: "transparent" }}
              icon={<SwitchAccountIcon />}
            >
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Incomplete Request"
                  to="/incomplete"
                  icon={<AddToHomeScreenIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="User Request"
                  to="/userRequest"
                  icon={<AddToHomeScreenIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Buyers"
                  to="/buyer"
                  icon={<ShoppingCartIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Sellers"
                  to="/seller"
                  icon={<StorefrontIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
            </SubMenu>
            <Item
              title="Category"
              to="/category"
              icon={<MenuOpenIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <SubMenu
              label="Product"
              sx={{ backgroundColor: "transparent" }}
              icon={<ProductionQuantityLimitsIcon />}
            >
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Product"
                  to="/product"
                  icon={<ProductionQuantityLimitsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
            </SubMenu>

            <SubMenu
              label="Orders"
              sx={{ backgroundColor: "transparent" }}
              icon={<BorderAllIcon />}
            >
              <MenuItem>
                <Item
                  title="Buyer Orders"
                  to="/buyerOrder"
                  icon={<LibraryBooksIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Seller Orders"
                  to="/sellerOrder"
                  icon={<StorefrontIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
            </SubMenu>

            <SubMenu
              label="Settings"
              sx={{ backgroundColor: "transparent" }}
              icon={<SettingsIcon />}
            >
              <MenuItem sx={{ backgroundColor: "transparent" }}>
                <Item
                  title="Locations"
                  to="/state"
                  icon={<LocationOnIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="Transport"
                  to="/transport"
                  icon={<EmojiTransportationIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="Terms & Conditions"
                  to="/terms"
                  icon={<TextSnippetIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              <MenuItem>
                <Item
                  title="Whatsapp Message"
                  to="/whatshappmessage"
                  icon={<SendIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem>
              {/* <MenuItem>
                <Item
                  title="TDS/TCS"
                  to="/tds"
                  icon={<TextSnippetIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </MenuItem> */}
            </SubMenu>
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
