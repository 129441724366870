import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {
  Box,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Form } from "../../Utils/useForm";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BackdropComponent from "../../Component/BackDrop/BackDrop";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  AddUser,
  buyerDetails,
  deleteUserLocation,
  updateUser,
} from "../../state/action/userAction";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { getCategoryDropdown } from "../../state/action/categoryAction";
import {
  getCityUserLists,
  getStateUserList,
} from "../../state/action/locationAction";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Multiselect from "multiselect-react-dropdown";
import { stateDropdown, cityDropdown } from "../../state/action/AddressAction";

const initialValues = {
  userName: "",
  companyName: "",
  email: "",
  address: "",
  mobile: "",
  panNumber: "",
  gstNumber: "",
  gstCertificate: "",
  panImage: "",
  category: "",
  startcityId: "",
  startstateList: "",
  startstateId: "",
};

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [val, setVal] = useState([]);
  const [images, setImages] = useState();
  const [images1, setImages1] = useState();
  const [userLocations, setUserLocations] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [fromCityList, setFromCityList] = useState([]);
  const [startStateList, setstartStateList] = useState([]);
  const [selectedCategory, setselectedCategory] = useState([]);
  const [byuerSkills, setbyuerSkills] = useState([]);
  const [byuerSCategory, setDeleteCategory] = useState([]);


  const handleAdd = (e) => {
    e.preventDefault();
    const abc = [...val, []];
    setVal(abc);
    const locations = [...userLocations, {}];
    setUserLocations(locations);
  };

  //citylist
  useEffect(() => {
    CityList();
  }, []);

  const CityList = async (value) => {
    const res = await getCityUserLists(value);
    if (res.status === true) {
      setCityList(res.data);
    } else {
      setCityList([]);
    }
  };

  const handleCityChange = (e, i, data) => {
    setUserLocations((prevState) => {
      const newState = [...prevState];
      if (data && data.userId) {
        newState[i] = {
          ...newState[i],
          cityId: e.target.value,
        };
      } else {
        newState[i] = {
          ...newState[i],
          cityId: e.target.value,
          id: "",
        }
      }
      return newState;
    });
  };

  //statelist
  useEffect(() => {
    StateList();
  }, []);

  const StateList = async () => {
    const res = await getStateUserList();
    if (res.status === true) {
      setStateList(res.data);
    } else {
      setStateList([]);
    }
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("userName" in fieldValues)
      temp.userName = fieldValues.userName ? "" : "This field is required.";
    if ("companyName" in fieldValues)
      temp.companyName = fieldValues.companyName
        ? ""
        : "This field is required.";
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        if (!/$^|.+@.+..+/.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    // if ("address" in fieldValues)
    //   temp.address = fieldValues.address ? "" : "This field is required.";

    if (!isEdit) {
      if ("mobile" in fieldValues) {
        //only numbers allow regex
        var regex = /^[0-9]+$/;
        if (!regex.test(fieldValues.mobile)) {
          temp.mobile = " Only numbers are allowed";
        } else {
          temp.mobile = "";
        }
      }
    }


    if ("gstNumber" in fieldValues) {
      temp.gstNumber = fieldValues.gstNumber ? "" : "This field is required.";
    }

    // if ("panNumber" in fieldValues) {
    //   //only numbers allow regex
    //   var regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    //   if (!regex.test(fieldValues.panNumber)) {
    //     temp.panNumber = "Invalid PanNumber";
    //   } else {
    //     temp.panNumber = "";
    //   }
    // }

    if ("startstateId" in fieldValues) {
      temp.startstateId = fieldValues.startstateId
        ? ""
        : "This field is required.";
    }

    if ("startcityId" in fieldValues) {
      temp.startcityId = fieldValues.startcityId
        ? ""
        : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let properties = {};


  let propertiess = {};

  const FromCityList = async (id) => {
    const res = await cityDropdown(id);
    if (res.status === true) {
      setFromCityList(res.data);
    } else {
      setFromCityList([]);
    }
  };


  let dataLocation = userLocations?.map((car) => {
    if (isEdit) {
      if (car && car.id && car.id !== "") {
        propertiess = {
          stateId: car.stateId,
          cityId: car.cityId,
          userId: state.buyer,
          id: car.id,
        };
      }
      else {
        propertiess = {
          stateId: car && car.stateId ? car.stateId : "",
          cityId: car && car.cityId ? car.cityId : "",
          userId: state.buyer,
        };
      }
      return propertiess;
    }
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      if (selectedCategory.length > 0) {
        formData.append("userName", values.userName);
        formData.append("companyName", values.companyName);
        formData.append("address", values.address);
        formData.append("email", values.email || "");
        formData.append("category", JSON.stringify(selectedCategory));
        formData.append("userLocation", JSON.stringify(userLocations));
        formData.append("mobile", values.mobile);
        formData.append("panNumber", values.panNumber);
        formData.append("gstNumber", values.gstNumber);
        formData.append("gstCertificate", values.gstCertificate);
        formData.append("panImage", values.panImage);
        formData.append("stateId", values.startstateId);
        formData.append("cityId", values.startcityId);

        if (isEdit) {
          const formDatas = new FormData();
          formDatas.append("companyId", state.companyId);
          formDatas.append("userId", state.buyer);
          formDatas.append("userName", values.userName);
          formDatas.append("companyName", values.companyName);
          formDatas.append("address", values.address);
          formDatas.append("email", values.email);
          formDatas.append("category", JSON.stringify(selectedCategory));
          formDatas.append("mobile", values.mobile);
          formDatas.append("userLocation", JSON.stringify(dataLocation));
          formDatas.append("panNumber", values.panNumber);
          formDatas.append("gstNumber", values.gstNumber);
          formDatas.append("gstCertificate", values.gstCertificate);
          formDatas.append("panImage", values.panImage);
          formDatas.append("stateId", values.startstateId);
          formDatas.append("cityId", values.startcityId);
          formDatas.append("deleteCategory", JSON.stringify(byuerSCategory));

          const res = await updateUser(state.buyer, formDatas);
          if (res && res.status === true) {
            navigate("/buyer");
            enqueueSnackbar(res.message, {
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(res.message, {
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        }

        if (!isEdit) {
          const res = await AddUser(formData);
          if (res && res.status === true) {
            navigate("/buyer");
            enqueueSnackbar(res.message, {
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {

            enqueueSnackbar(res.message || "Please Upload the GST certificate ", {
              variant: "error",
              autoHideDuration: 2000,
            });

          }
        }
      } else {
        enqueueSnackbar("Please Select category ", {
          variant: "error",
          autoHideDuration: 2000,
        });
      }

    }
  };


  useEffect(() => {
    if (state && state.buyer) {
      setIsEdit(true);

      setValues({
        ...values,
        userName: state.buyer.userName,
        companyName: state.buyer.companyName,
        email: state.buyer.email,
        mobile: state.buyer.mobile,
        panNumber: state.buyer.panNumber,
        gstNumber: state.buyer.gstNumber,
      });
    } else {
      setIsEdit(false);
      setOpenBackdrop(true);
      setTimeout(() => {
        setOpenBackdrop(false);
      }, 300);
    }
  }, [state]);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    const res = await getCategoryDropdown();
    if (res && res.status === true) {
      setCategoryList(res.data);
    } else {
      setCategoryList([]);
    }
  };

  useEffect(() => {
    StateLists();
  }, []);


  const StateLists = async () => {
    const res = await stateDropdown();
    if (res.status === true) {
      setstartStateList(res.data);
    } else {
      setstartStateList([]);
    }
  };

  const handleImageItem1 = async (e) => {
    const selected = e.target.files[0];
    //image/jpeg,image/gif,image/png,application/pdf
    const ALLOWED_TYPES = ["application/pdf", "image/jpeg", "image/png"];

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setImages1(selected.name);
      setValues({ ...values, gstCertificate: selected });
    } else {
      // toast.error("Please Select a PDF File", toastType);
    }
  };
  async function deleteImage1(index) {
    setImages1("");
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image1"];
      return newValues;
    });
  }

  const resetImage = () => {
    setImages("");
  };


  const deleteImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["panImage"];
      return newValues;
    });
  };
  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImages(base64String);
      setValues({ ...values, panImage: selected });
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleStateChange = (e, i, data) => {

    setUserLocations((prevState) => {
      const newState = [...prevState];
      if (data && data.userId) {
        newState[i] = {
          ...newState[i],
          stateId: e.target.value,
        };
      } else {
        newState[i] = {
          ...newState[i],
          stateId: e.target.value,
          id: "",
        };
      }
      return newState;
    });
  };


  // const id = state;

  const getProductDetails = async (id) => {
    const res = await buyerDetails(state.buyer, state.companyId);
    if (res && res.status === true) {
      console.log("res", res?.data?.company[0]?.stateId,);
      setValues({
        ...values,
        userName: res.data.userName,
        email: res.data.email,
        mobile: res.data.mobile,
        address: res?.data?.selected?.address,
        companyName: res?.BuyerCompany,
        panNumber: res?.data?.selected?.panNumber,
        gstNumber: res?.data?.selected?.gstNumber,
        panImage: images,
        gstCertificate: images1,
        // categoryIds: res?.data?.category,
        startstateId: res?.data?.selected?.stateId,
        startcityId: res?.data?.selected?.cityId,
      });
      setbyuerSkills(res?.data?.category)
      setImages(res?.data?.company[0]?.panImage);
      setImages1(res?.data?.company[0]?.gstCertificate);
      setCategoryId(res.data.category[0]);
      setUserLocations(res?.locationData);
      setVal(res?.locationData);
      FromCityList(res?.data?.company[0]?.stateId);
      setselectedCategory(res?.data?.category)
    }
  };


  useEffect(() => {
    // console.log('state.buyer', state.buyer);
    if (state && state.buyer && state.companyId) {
      getProductDetails(state.buyer);
      setIsEdit(true);
    }
  }, [state]);

  const handelLocationDelete = async (e, data, i) => {
    e.preventDefault();

    //if this is last index then not delete
    if (userLocations.length === 1) {
      return;
    }


    if (data && data.userId) {
      const res = await deleteUserLocation(userLocations[i + 1].id);
      if (res && res.status === true) {
        setVal((prevState) => {
          const newState = [...prevState];
          newState.splice(i, 1);
          return newState;
        });
        enqueueSnackbar(res.message || "Delete Successfully.", {
          variant: "success",
          autoHideDuration: 2000,
        });

        //remove this index from array
        setUserLocations((prevState) => {
          const newState = [...prevState];
          newState.splice(i + 1, 1);
          return newState;
        });

      } else {

        enqueueSnackbar(res.message || "Something went wrong.", {
          variant: "error",
          autoHideDuration: 2000,
        });

      }
    }
    else {
      setVal((prevState) => {
        const newState = [...prevState];
        newState.splice(i + 1, 1);
        return newState;
      }
      );

      setUserLocations((prevState) => {
        const newState = [...prevState];
        newState.splice(i + 1, 1);
        return newState;
      }
      );
      enqueueSnackbar("Delete Successfully.", {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
  };

  // const getSkillList = async () => {
  //   // const res = await SkillList();
  //   if (res && res.data) {
  //     const skills = res.data.map((item) => {
  //       return { skillId: item.id, skillName: item.title };
  //     });
  //     setbyuerSkills(skills.filter((item) => { return !selectSkill.some((item2) => { return item.skillId === item2.skillId }) }));

  //   }
  // };removeSkill

  //remove the skill
  const removeSkill = async (e, item) => {
    setbyuerSkills([...byuerSkills, item]);
    const removeSkill = selectedCategory?.filter(
      (items) => items.categoryId != item.categoryId
    );

    console.log("data", item)
    setselectedCategory(removeSkill);
    if (item && item.id) {
      setDeleteCategory([...byuerSCategory, item?.id]);
    }
  };

  const multiselectSelectSkill = (data, e) => {
    console.log('e.id', e);
    setselectedCategory([...selectedCategory, {
      categoryId: e.id,
      name: e.name,
      userId: state && state.buyer ? state.buyer : 0,
    }]);
    setbyuerSkills(byuerSkills.filter((item) => { return !data.some((item2) => { return item.categoryId === item2.categoryId }) }));
  };
  // console.log('selectedCategory ---', byuerSkills);

  return (
    <>
      <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <BackdropComponent open={openBackdrop} />

          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/buyer");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            Buyer
          </Typography>
          <Grid container alignItems="top" style={{ marginBottom: "25px" }}>
            <Grid item sm={7} xs={12}>
              <Box display="flex">
                <Box>
                  <img
                    src={"../../assets/images/product-image.png"}
                    style={{ borderRadius: "5px" }}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                  >
                    Buyer
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#000",
                      marginTop: "3px",
                    }}
                  >
                    {isEdit ? "EDIT" : "ADD"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Controls.Button
                  text=" Cancel"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "Red",
                    ":hover": { bgcolor: "Red" },
                    textTransform: "none",
                    borderRadius: "50px",
                    boxShadow: 0,
                  }}
                  onClick={() => {
                    navigate("/buyer");
                  }}
                />
                <Controls.Button
                  text="Save"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "rgb(79, 70, 229)",
                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                    marginLeft: "10px",
                    textTransform: "none",
                    borderRadius: "50px",
                    boxShadow: 0,
                  }}
                  variant="outlined"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", backgroundColor: "rgb(241, 245, 249)" }}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              border: 1,
              borderColor: "#eaeaea",
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "rgba(107, 114, 128, .2)",
                marginBottom: "10px",
                paddingLeft: "10px",
                paddingTop: "10px",
                paddingBottom: "0px",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Basic Info"
                  {...a11yProps(0)}
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
                <Tab
                  label="Doc Image"
                  {...a11yProps(1)}
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Box
                sx={{
                  maxWidth: {
                    md: "500px",
                    lg: "650px",
                  },
                  marginLeft: {
                    xs: "5px",
                    md: "5px",
                    lg: "15px",
                  },
                  paddingBottom: "20px",
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="User Name"
                    name="userName"
                    value={values.userName}
                    onChange={handleInputChange}
                    error={errors.userName}
                    helperText={errors.userName}
                  />
                  <Box sx={{ marginTop: "15px" }}>
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ width: "100%" }}>
                        <Multiselect
                          options={categoryList}
                          name="category"
                          displayValue="name"
                          closeOnSelect={true}
                          showArrow={true}
                          selectedValues={selectedCategory}
                          // onSelect={(e) => {
                          //   setValues({
                          //     ...values,
                          //     categoryIds: e,
                          //   });
                          // }}
                          // onRemove={(e) => {
                          //   setValues({
                          //     ...values,
                          //     categoryIds: e,
                          //   });
                          // }}
                          onSelect={multiselectSelectSkill} // Function will trigger on select event
                          onRemove={removeSkill} // Function will trigger on remove event
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ marginTop: "15px" }}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Company Name"
                      name="companyName"
                      value={values.companyName}
                      onChange={handleInputChange}
                      error={errors.companyName}
                      helperText={errors.companyName}
                    />
                  </Box>
                  <Box sx={{ marginTop: "15px" }}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Address"
                      name="address"
                      value={values.address}
                      onChange={handleInputChange}
                      error={errors.address}
                      helperText={errors.address}
                    />
                  </Box>
                  <Box sx={{ marginTop: "15px" }}>
                    <Grid item sm={6} xs={12}>
                      <Controls.SelectBox
                        text="Add New"
                        className="input"
                        fullWidth
                        id="startstateId"
                        name="startstateId"
                        value={values.startstateId}
                        error={errors.startstateId}
                        onChange={(e) => {
                          handleInputChange(e);
                          FromCityList(e.target.value);
                        }}
                        color="primary"
                        options={startStateList}
                        label="State"
                      />
                      {Boolean(errors.startstateId) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            marginTop: "4px",
                            marginBottom: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {errors.startstateId}
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Controls.SelectBox
                        text="Add New"
                        className="input"
                        fullWidth
                        id="startcityId"
                        name="startcityId"
                        value={values.startcityId}
                        error={errors.startcityId}
                        onChange={handleInputChange}
                        color="primary"
                        options={fromCityList}
                        label="City"
                      />
                      {Boolean(errors.startcityId) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            marginTop: "4px",
                            marginBottom: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {errors.startcityId}
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Box>

                  <Box sx={{ marginTop: "15px" }}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleInputChange}
                      error={errors.email}
                      helperText={errors.email}
                    />
                  </Box>
                  <Box sx={{ marginTop: "15px" }}>
                    {isEdit ? (<Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Mobile Number"
                      name="mobile"
                      value={values.mobile}
                      error={errors.mobile}
                      helperText={errors.mobile}
                      inputProps={{ maxLength: 10 }}
                    />) : (<Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Mobile Number"
                      name="mobile"
                      value={values.mobile}
                      // disabled={isEdit}
                      onChange={handleInputChange}
                      error={errors.mobile}
                      helperText={errors.mobile}
                      inputProps={{ maxLength: 10 }}
                    />)}

                  </Box>
                  <Box sx={{ marginTop: "15px" }}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Pan Number"
                      name="panNumber"
                      value={values.panNumber}
                      onChange={handleInputChange}
                      error={errors.panNumber}
                      helperText={errors.panNumber}
                      inputProps={{ maxLength: 10 }}
                    />
                  </Box>
                  <Box sx={{ marginTop: "15px" }}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Gst Number"
                      name="gstNumber"
                      value={values.gstNumber}
                      onChange={handleInputChange}
                      error={errors.gstNumber}
                      helperText={errors.gstNumber}
                      inputProps={{ maxLength: 15 }}
                    />
                  </Box>

                  <Box
                    display="flex"
                    sx={{ alignItems: "center", marginTop: "15px" }}
                  >
                    <Box
                      display="flex"
                      gap={2}
                      sx={{ width: "100%", alignItems: "center" }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <select
                          text="Add New"
                          className="input"
                          fullWidth
                          id="State"
                          name="stateList"
                          value={userLocations[0]?.stateId}
                          onChange={(e) => {
                            handleStateChange(e, 0, userLocations[0]);
                            handleInputChange(e);
                          }}
                          color="primary"
                          label="State"
                        >
                          <option value="">Select State</option>
                          {stateList.map((data, i) => {
                            return <option value={data.id}>{data.name}</option>;
                          })}
                        </select>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <select
                          text="Add New"
                          className="input"
                          fullWidth
                          id="City"
                          name="cityList"
                          value={userLocations[0]?.cityId}
                          onChange={(e) => {
                            handleCityChange(e, 0);
                            handleInputChange(e);
                          }}
                          color="primary"
                          label="City"
                        >
                          <option value="">Select City</option>
                          {cityList
                            .filter((data) => {
                              return data.stateId == userLocations[0]?.stateId;
                            })
                            .map((data, i) => {
                              return (
                                <option value={data.id}>{data.name}</option>
                              );
                            })}
                        </select>
                      </Box>
                    </Box>
                    <button
                      //stop the page from refreshing
                      onClick={(e) => {
                        handelLocationDelete(e, userLocations[0], -1);
                      }
                      }
                      className="adddelete"
                    >
                      <DeleteForeverIcon />
                    </button>
                    <button
                      onClick={(e) => handleAdd(e)}
                      className="add_button"
                    >
                      +
                    </button>
                  </Box>

                  {userLocations.map((data, i) => {
                    return (
                      <Box
                        display="flex"
                        sx={{ alignItems: "center", marginTop: "12px" }}
                      >
                        <Box
                          display="flex"
                          gap={2}
                          sx={{ width: "100%", alignItems: "center" }}
                        >
                          <Box sx={{ width: "100%" }}>
                            <select
                              text="Add New"
                              className="input"
                              fullWidth
                              id="State"
                              name="stateList"
                              value={userLocations[i + 1]?.stateId}
                              onChange={(e) => {
                                handleStateChange(e, i + 1, data);
                                handleInputChange(e);
                              }}
                              color="primary"
                              label="State"
                            >
                              <option value="">Select State</option>
                              {stateList.map((data, i) => {
                                return (
                                  <option value={data.id}>{data.name}</option>
                                );
                              })}
                            </select>
                          </Box>
                          <Box sx={{ width: "100%" }}>
                            <select
                              text="Add New"
                              className="input"
                              fullWidth
                              id="City"
                              name="cityList"
                              color="primary"
                              value={userLocations[i + 1]?.cityId}
                              label="City"
                              onChange={(e) => {
                                handleCityChange(e, i + 1, data);
                                handleInputChange(e);
                              }}

                            >
                              <option value="">Select City</option>
                              {cityList
                                .filter((data) => {
                                  return (
                                    data.stateId ==
                                    userLocations[i + 1]?.stateId
                                  );
                                })
                                .map((data, i) => {
                                  return (
                                    <option value={data.id}>{data.name}</option>
                                  );
                                })}
                            </select>
                          </Box>
                        </Box>
                        <button
                          //stop the page from refreshing
                          onClick={(e) => {
                            handelLocationDelete(e, data, i);
                          }
                          }
                          className="adddelete"
                        >
                          <DeleteForeverIcon />
                        </button>
                      </Box>
                    );
                  })}
                </Form>
              </Box>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Box
                sx={{
                  marginLeft: {
                    xs: "5px",
                    md: "10px",
                    lg: "25px",
                  },
                  marginRight: {
                    xs: "5px",
                    md: "10px",
                    lg: "25px",
                  },
                }}
              >
                <Box display="flex">
                  <Box>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        ":hover": { bgcolor: "transparent" },
                        textTransform: "none",
                        fontSize: "16px",
                        backgroundColor: "#f6f9fb",
                        borderRadius: "15px",
                        padding: "40px",
                      }}
                    >
                      <DriveFolderUploadIcon
                        sx={{
                          fontSize: "40px",
                          color: "rgb(107, 114, 128)",
                          display: "flex",
                        }}
                      />
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          handleImageItem1(e);
                        }}
                        accept="image/jpeg,image/gif,image/png,application/pdf"
                      />
                    </Button>
                    <Typography
                      sx={{ fontSize: "13px", color: "#000", marginTop: "7px" }}
                    >
                      Gst Certificate
                    </Typography>
                  </Box>

                  <Box sx={{ textAlign: "end" }}>
                    {images1 && images1 !== "" && (
                      <StyledIconWrapper
                        style={{}}
                        sx={{
                          position: "relative",
                          margin: "25px 5px 5px 5px",
                          width: "147px",
                          height: "140px",
                          background: "none !important",
                          border: "none",
                        }}
                        onClick={() => {
                          // setIconFlag(id);
                        }}
                      >
                        <StyledIconWrapper
                          style={{
                            margin: "0",
                            display: "flex",
                            justifyContent: "center",
                            background: "none",
                            border: "none",
                            width: "auto",
                            height: "50px",
                          }}
                        >
                          <PictureAsPdfIcon
                            style={{
                              width: "40px",
                              height: "40px",
                              color: "rgb(118 116 107)",
                            }}
                          />
                        </StyledIconWrapper>

                        <p style={{ fontSize: "12px", textAlign: "center" }}>
                          {" "}
                          {images1 !== "" && images1}
                        </p>
                        <IconButton
                          size="large"
                          sx={{
                            position: "absolute",
                            right: "2%",
                            top: "3%",
                            width: "20px",
                            height: "20px",
                            background: "rgba(15, 15, 15, 0.5)",
                            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                            borderRadius: "10px",

                            color: "white",
                            "&:hover": {
                              backgroundColor: "#FFC400",
                            },
                          }}
                        >
                          <CloseIcon
                            sx={{ width: "20px", height: "20px" }}
                            onClick={() => {
                              deleteImage1();
                            }}
                          />
                        </IconButton>
                      </StyledIconWrapper>
                    )}
                  </Box>
                </Box>
                <Divider
                  sx={{
                    marginBottom: "20px",
                    marginTop: "12px",
                    borderColor: "rgba(107, 114, 128, .4)",
                    width: "15%",
                  }}
                />
                <Box display="flex">
                  <Box>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        ":hover": { bgcolor: "transparent" },
                        textTransform: "none",
                        fontSize: "16px",
                        backgroundColor: "#f6f9fb",
                        borderRadius: "15px",
                        padding: "40px",
                      }}
                    >
                      <DriveFolderUploadIcon
                        sx={{
                          fontSize: "40px",
                          color: "rgb(107, 114, 128)",
                          display: "flex",
                        }}
                      />
                      <input
                        type="file"
                        hidden
                        // accept="application/pdf"
                        onChange={handleImageItem}
                      />
                    </Button>
                    <Typography
                      sx={{ fontSize: "13px", color: "#000", marginTop: "7px" }}
                    >
                      Pan Image
                    </Typography>
                  </Box>

                  <Box sx={{ textAlign: "end" }}>
                    {images && images !== "" && (
                      <StyledIconWrapper
                        sx={{
                          position: "relative",
                          margin: "25px 5px 5px 5px",
                          width: "147px",
                          height: "140px",
                          backgroundImage: `url(${images})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          marginTop: "-21px",
                          marginLeft: "27px",
                        }}
                        key={1}
                        onClick={() => {
                          // setIconFlag(id);
                        }}
                      >
                        <IconButton
                          size="large"
                          sx={{
                            position: "absolute",
                            right: "2%",
                            top: "3%",
                            width: "36px",
                            height: "36px",
                            background: "rgba(15, 15, 15, 0.5)",
                            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                            borderRadius: "10px",

                            color: "white",
                            "&:hover": {
                              backgroundColor: "#0957DD",
                            },
                          }}
                        >
                          <CloseIcon
                            sx={{ width: "20px", height: "20px" }}
                            onClick={() => {
                              deleteImage();
                            }}
                          />
                        </IconButton>
                      </StyledIconWrapper>
                    )}
                  </Box>
                </Box>
              </Box>
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </>
  );
}
