import axiosRequest from "../axios/axios";

export const getOrderList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/getAllOrder` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const orderDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/orderDetails/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const OrderStatusChange = async (id, payload) => {

  try {
    const response = await axiosRequest.post(
      `/orderStatusUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const OrderStatusChanges = async (id, payload) => {
  if (localStorage.token) {
    axiosRequest.defaults.headers.common[
      "x-auth-token"
    ] = `${localStorage.token}`;
  }
  try {
    const response = await axiosRequest.put(
      `/orderapprovebyadmin/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const OrderReject = async (id, payload) => {
  if (localStorage.token) {
    axiosRequest.defaults.headers.common[
      "x-auth-token"
    ] = `${localStorage.token}`;
  }
  try {
    const response = await axiosRequest.put(
      `/orderrejectbyadmin/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getSellerOrderList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/sellerOrderList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const orderSellerDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/sellerOrderDetails/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const orderSellerPO = async (id) => {
  try {
    const response = await axiosRequest.get(`/sellerOrderPO/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


export const OrderSellerStatusChange = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/qouteProductApprove/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const OrderSellerPrice = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/quoteProductPriceUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const OrderBuyerPrice = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/orderPriceUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const buyerorderInvoiceDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/invoice/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const sellerorderPoDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/addSellerPo/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const remarkChange = async (id, payload) => {
  try {
    const response = await axiosRequest.post(`/addremark/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const sellerRemarkChange = async (id, payload) => {
  try {
    const response = await axiosRequest.post(`/selleraddremark/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}


export const orderAddressChange = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`/updateDeliveryAddress/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const addPaymentTerms = async (id, payload) => {
  try {
    const response = await axiosRequest.post(`/addpayterms/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}


export const addPaymentTermsSeller = async (id, payload) => {
  try {
    const response = await axiosRequest.post(`/selleraddpayterms/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const poList = async (payload) => {
  try {
    const response = await axiosRequest.get(`/sellerOrderPoList${payload}`,);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const poShipTo = async (id, data) => {
  try {
    const response = await axiosRequest.post(`/addShippingDetails/${id}`, data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

