import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Divider,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import {
  orderSellerDetails,
  orderSellerPO,
  OrderSellerPrice,
  OrderSellerStatusChange,
  sellerRemarkChange,
  addPaymentTermsSeller
} from "../../state/action/orderAction";
import { useSnackbar } from "notistack";
import moment from "moment";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "../../Utils/useForm";
import { Modal } from "antd";

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
const CssTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "yellow",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
}));

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const initialValues = {
  remark: "",
  paymentTerms: "",
  remarks: "",
};

function SellerOrderDetails() {
  const [cityDatas, setCityDatas] = useState([]);
  const [stateDatas, setStateDatas] = useState([]);
  const [pacakgeData, setPacakgeData] = useState([]);
  const [productData, setProductdata] = useState([]);
  const [sellerQuoteDatas, setSellerQuoteDatas] = useState([]);
  const [comapny, setComapny] = useState([]);
  const [detail, setDetails] = useState([]);
  const [isStatusEditing, setStatusEditing] = useState(false);
  const [isMainQtyEditing, setMainQtyEditing] = useState(false);
  const [isSellQtyEditing, setSellQtyEditing] = useState(false);
  const [statusMenu, setStatusMenu] = useState(true);
  const [mianQtyMenu, setMianQtyMenu] = useState(true);
  const [sellQtyMenu, setSellQtyMenu] = useState(true);
  const [save, setSave] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [notify, setNotify] = useState({});
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openremarkPopup, setOpenRemarkPopup] = useState(false);
  const [opentermPopup, setOpenTermPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sellerOrderId, setSellerOrderId] = useState();
  // const [isPO, setIsPO] = useState(false);
  const { enqueueSnackbar } = useSnackbar();


  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const openStatusMenu = () => {
    setStatusMenu(true);
  };

  const openMianQtyMenu = () => {
    setMianQtyMenu(true);
  };
  const openSellQtyMenu = () => {
    setSellQtyMenu(true);
  };
  const closeStatusMenu = () => {
    setStatusMenu(false);
  };

  const closeMianQtyMenu = () => {
    setMianQtyMenu(false);
  };

  const closeSellQtyMenu = () => {
    setSellQtyMenu(false);
  };

  const openRemarkBox = () => {
    setOpenRemarkPopup(true);
  };
  const closeRemarkBox = () => {
    setOpenRemarkPopup(false);
  };

  const openTermBox = () => {
    setOpenTermPopup(true);
  };
  const closeTermBox = () => {
    setOpenTermPopup(false);
  };

  const startStatusEditing = (id) => {
    setStatusEditing(id);
  };

  const startMainQtyEditing = (id) => {
    setMainQtyEditing(id);
  };

  const startSellQtyEditing = (id) => {
    setSellQtyEditing(id);
  };

  const handelTermsChange = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();

      const res = await addPaymentTermsSeller(sellerOrderId, {
        paymentTerms: values.paymentTerms,
      });
      if (res) {
        setOpenTermPopup(false);
        setLoading(false);
        getDetails(sellerOrderId)
        enqueueSnackbar("Terms updated successfully", {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
      else {
        setLoading(false);
        setOpenTermPopup(false);

        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 2000,
        });
      }

    }
    catch (error) {
      setLoading(false);
    }
  };


  const handelRemarksChange = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const res = await sellerRemarkChange(sellerOrderId, {
        remarks: values.remarks,
      });
      if (res) {
        setOpenRemarkPopup(false);
        setLoading(false);
        getDetails(sellerOrderId)
        enqueueSnackbar("Remarks updated successfully", {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
      else {
        setLoading(false);
        setOpenRemarkPopup(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 2000,
        });
      }

    }
    catch (error) {
      setLoading(false);
    }
  };


  const startStatusUpdate = (e, id) => {
    setSellerQuoteDatas({
      ...sellerQuoteDatas,
      price: e.target.value,
    });
    setSave(true);
  };

  const startMianQtyUpdate = (e, id) => {
    setSellerQuoteDatas({
      ...sellerQuoteDatas,
      totalQty: e.target.value,
    });
    setSave(true);
  };

  const startSellQtyUpdate = (e, id) => {
    setSellerQuoteDatas({
      ...sellerQuoteDatas,
      sellToQty: e.target.value,
    });
    setSave(true);
  };

  const stopStatusEditing = (id) => {
    setStatusEditing(id);
  };

  const stopMianQtyEditing = (id) => {
    setMainQtyEditing(id);
  };

  const stopSellQtyEditing = (id) => {
    setSellQtyEditing(id);
  };


  const statusList = [
    { id: 0, title: "Pending" },
    { id: 1, title: "Confirmed" },
    { id: 3, title: "Approved By Admin" },
  ];

  const navigate = useNavigate();

  const { state } = useLocation();

  const id = state?.id;
  const poButton = state?.poButton;
  const isPO = state?.isPO;

  const getDetails = async (id) => {
    let res = ""
    if (isPO) {
      res = await orderSellerPO(id);
    }
    else {
      res = await orderSellerDetails(id);
    }

    if (res && res.status) {
      setProductdata(res?.data?.productData);
      setCityDatas(res?.data?.cityData);
      setStateDatas(res?.data?.stateData);
      setPacakgeData(res?.data?.packageData);
      setSellerQuoteDatas(res?.data?.sellerQuoteData);
      setComapny(res.data.sellerCompanys);
      setDetails(res.data.sellerData);
    }
  };



  useEffect(() => {
    getDetails(id);
  }, [id]);

  const orderStatus = (orderstatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Confirmed",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      2: {
        key: "Approved By Admin",
        backgroundColor: "green",
        color: "#ede7dd",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[orderstatus]}
        label={statusColors[orderstatus] ? statusColors[orderstatus].key : ""}
      />
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      price: sellerQuoteDatas?.price,
      totalQty: sellerQuoteDatas?.totalQty,
      sellToQty: sellerQuoteDatas?.sellToQty,
    };
    const res = await OrderSellerPrice(id, payload);

    if (res && res.status === true) {
      // navigate("/sellerOrder");
      setSave(false)
      setRefresh(!refresh);
      getDetails(id);
      enqueueSnackbar("Order update successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);
      enqueueSnackbar("Failed to update order", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  const handleApproveStatus = async (e) => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);

    const payload = {
      status: e.target.value,
    };
    const res = await OrderSellerStatusChange(id, payload);

    if (res && res.status === true) {
      setSellerQuoteDatas({
        ...sellerQuoteDatas,
        status: e.target.value,
      });
      navigate("/sellerOrder");
    } else {
      setOpenBreakdrop(!openBackdrop);
      enqueueSnackbar("Failed to update status", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleRemarkStatus = async (e) => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      remark: values.remark,
    };
    const res = await sellerRemarkChange(id, payload);

    if (res && res.status === true) {
      navigate("/buyerOrder");
    } else {
      setOpenBreakdrop(!openBackdrop);
    }
  };

  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Typography
          sx={{
            paddingTop: "25px",
            paddingBottom: "15px",
            fontSize: "15px",
            fontWeight: "600",
            color: "#000",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/sellerOrder");
          }}
        >
          <KeyboardBackspaceIcon
            sx={{ verticalAlign: "top", marginRight: "5px" }}
          />
          Order
        </Typography>
        <Grid container alignItems="top" style={{ marginBottom: "20px" }}>
          <Grid item sm={7} xs={12}>
            <Box display="flex">
              <Box>
                <img
                  src={"../../assets/images/product-image.png"}
                  style={{ borderRadius: "5px" }}
                />
              </Box>
              <Box sx={{ marginLeft: "10px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                >
                  Order Detail
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    marginTop: "3px",
                  }}
                >
                  Detail
                </Typography>
                {save === true ? (
                  <Controls.Button
                    text="Save"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                      backgroundColor: "rgb(79, 70, 229)",
                      marginLeft: "800px",
                      textTransform: "none",
                    }}
                    onClick={handleSubmit}
                    variant="outlined"
                  />
                ) : null}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: "rgb(241, 245, 249)",
          overflow: "scroll",
          overflowX: "hidden",
          border: "1px solid #ccc",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderTopRightRadius: "25px",
            borderTopLeftRadius: "25px",
            border: 0,
            borderColor: "#eaeaea",
            paddingLeft: "0px",
            paddingTop: "10px",
          }}
        >
          <Box
            sx={{
              marginTop: "15px",
              marginLeft: { xs: "15px", md: "25px", lg: "25px" },
            }}
          >
            <Box
              sx={{
                maxWidth: {
                  md: "600px",
                  lg: "1200px",
                },
              }}
            >
              <Typography
                sx={{
                  marginBottom: "20px",
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#000",
                }}
              ></Typography>
              <Grid container spacing={8} style={{ marginBottom: "30px" }}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      marginBottom: "20px",
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Seller Detail :
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Seller Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {detail?.userName}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Company Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {comapny?.companyName}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Email :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {detail?.email}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Phone Number :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {detail?.mobile}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sm={6} xs={12}>

                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        State Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {stateDatas?.name}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        City Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {cityDatas?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                sx={{
                  borderTop: 0,
                  borderColor: "#eaeaea",
                  paddingTop: "10px",
                }}
              ></Divider>
              <Box
                gap={7}
                sx={{
                  marginBottom: "30px",
                  marginTop: "30px",
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  marginRight: "10px",
                }}
              >
                <Box
                  sx={{
                    maxWidth: {
                      md: "300px",
                      lg: "300px",
                    },
                    marginBottom: { xs: "15px", md: "0", lg: "0" },
                  }}
                >
                  <Typography
                    sx={{
                      paddingBottom: "3px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Address :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    {comapny?.address}, {cityDatas?.name},{stateDatas?.name}
                  </Typography>
                </Box>

                {/* <Box sx={{ marginBottom: { xs: "15px", md: "0", lg: "0" } }}>
                  <Typography
                    sx={{
                      paddingBottom: "3px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                      marginBottom: { xs: "5px", md: "10px", lg: "10px" },
                    }}
                  >
                    Order Status :
                  </Typography>
                  {sellerQuoteDatas?.status === 0 ? (
                    <Typography>
                      {orderStatus(sellerQuoteDatas?.status)}
                    </Typography>
                  ) : (
                    <Controls.SelectBox
                      text="Add New"
                      className="input"
                      fullWidth
                      id="status"
                      name="status"
                      value={parseInt(sellerQuoteDatas?.status)}
                      onChange={(e) => {
                        handleApproveStatus(e);
                      }}
                      color="primary"
                      options={statusList}
                      label="Status"
                    />
                  )}
                </Box> */}
                <Box>
                  <Typography
                    sx={{
                      paddingBottom: "3px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Start Date :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    {moment(sellerQuoteDatas.startDate).format(
                      "MMM DD, YYYY, h:mm:ss a"
                    )}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      paddingBottom: "3px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    end Date :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    {moment(sellerQuoteDatas.endDate).format(
                      "MMM DD, YYYY, h:mm:ss a"
                    )}
                  </Typography>
                </Box>

              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box>
                    <Typography
                      sx={{
                        paddingBottom: "3px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    >
                      {sellerQuoteDatas?.status == 0 ? (
                        ""
                      ) : (
                        <>
                          Payment Terms :
                          {
                            opentermPopup ?
                              null : (<Button
                                onClick={() => {
                                  setSellerOrderId(sellerQuoteDatas.id);
                                  openTermBox();
                                  setValues({
                                    ...values,
                                    paymentTerms: sellerQuoteDatas?.paymentTerms,
                                  });
                                }}
                                size="large"
                                endIcon={<EditIcon />}
                              >
                                {/* {ordersData?.remarks} */}
                              </Button>)
                          }
                        </>
                      )}



                    </Typography>


                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000c7",
                      }}
                    >
                      {sellerQuoteDatas?.paymentTerms}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box>
                    <Typography
                      sx={{
                        paddingBottom: "3px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#000",
                      }}
                    >
                      {sellerQuoteDatas?.status == 0 ? (
                        ""
                      ) : (
                        <>
                          Remarks :
                          {
                            openremarkPopup ?
                              null : (<Button
                                onClick={() => {
                                  setSellerOrderId(sellerQuoteDatas.id);
                                  openRemarkBox();
                                  setValues({
                                    ...values,
                                    remarks: sellerQuoteDatas?.remarks,
                                  });
                                }}
                                size="large"
                                endIcon={<EditIcon />}
                              >
                                {/* {ordersData?.remarks} */}
                              </Button>)
                          }
                        </>
                      )}



                    </Typography>


                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000c7",
                      }}
                    >
                      {sellerQuoteDatas?.remarks}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                maxWidth: {
                  lg: "1250px",
                },
              }}
            >
              <Box
                sx={{
                  marginTop: "40px",
                  marginBottom: "20px",
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",
                  border: 1,
                  borderColor: "#eaeaea",
                  marginRight: "20px",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: 0, paddingLeft: "0!important" }}
                >
                  <Table
                    aria-label="simple table"
                    sx={{ paddingLeft: "0!important" }}
                  >
                    <TableHead sx={{ paddingLeft: "0!important" }}>
                      <TableRow
                        sx={{
                          backgroundColor: "#f6f9fb",
                          paddingLeft: "0!important",
                        }}
                      >
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Sr.No
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Name
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Package Type
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Margin
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Price(per Kg)
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Min Quantity
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        backgroundColor: "#fff",
                        paddingLeft: "0!important",
                      }}
                    >
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          1
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {productData?.productName}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {pacakgeData?.type}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {/* {sellerQuoteDatas.totalQty} MT */}

                          {isMainQtyEditing ? (
                            <Box sx={{ marginTop: "15px" }}>
                              <CssTextField
                                sx={{
                                  input: { color: "black" },
                                  fieldset: { borderColor: "black" },
                                }}
                                size="small"
                                variant="outlined"
                                id="totalQty"
                                name="totalQty"
                                value={sellerQuoteDatas?.totalQty}
                                open={mianQtyMenu}
                                onOpen={openMianQtyMenu}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    stopMianQtyEditing();
                                    closeMianQtyMenu();
                                  }
                                }}
                                onChange={(event) =>
                                  startMianQtyUpdate(event, sellerQuoteDatas?.id)
                                }
                                onBlur={() => stopMianQtyEditing()}
                              >
                                {sellerQuoteDatas.totalQty}
                              </CssTextField>
                            </Box>
                          ) : (
                            <Button
                              onClick={() => {
                                startMainQtyEditing(sellerQuoteDatas.id);
                                openMianQtyMenu();
                              }}
                              size="large"
                              endIcon={<EditIcon />}
                            >
                              {sellerQuoteDatas?.totalQty
                                ? sellerQuoteDatas?.totalQty
                                : 0.0} MT
                            </Button>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {sellerQuoteDatas?.margin}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {isStatusEditing ? (
                            <Box sx={{ marginTop: "15px" }}>
                              <CssTextField
                                sx={{
                                  input: { color: "black" },
                                  fieldset: { borderColor: "black" },
                                }}
                                size="small"
                                variant="outlined"
                                id="price"
                                name="price"
                                value={sellerQuoteDatas?.price}
                                open={statusMenu}
                                onOpen={openStatusMenu}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    stopStatusEditing();
                                    closeStatusMenu();
                                  }
                                }}
                                onChange={(event) =>
                                  startStatusUpdate(event, sellerQuoteDatas?.id)
                                }
                                onBlur={() => stopStatusEditing()}
                              >
                                {sellerQuoteDatas.price}
                              </CssTextField>
                            </Box>
                          ) : (
                            <Button
                              onClick={() => {
                                startStatusEditing(sellerQuoteDatas.id);
                                openStatusMenu();
                              }}
                              size="large"
                              endIcon={<EditIcon />}
                            >
                              {sellerQuoteDatas?.price
                                ? sellerQuoteDatas?.price
                                : 0.0}
                            </Button>
                          )}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {/* {sellerQuoteDatas.sellToQty} MT */}

                          {isSellQtyEditing ? (
                            <Box sx={{ marginTop: "15px" }}>
                              <CssTextField
                                sx={{
                                  input: { color: "black" },
                                  fieldset: { borderColor: "black" },
                                }}
                                size="small"
                                variant="outlined"
                                id="totalQty"
                                name="totalQty"
                                value={sellerQuoteDatas?.sellToQty}
                                open={sellQtyMenu}
                                onOpen={openSellQtyMenu}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    stopSellQtyEditing();
                                    closeSellQtyMenu();
                                  }
                                }}
                                onChange={(event) =>
                                  startSellQtyUpdate(event, sellerQuoteDatas?.id)
                                }
                                onBlur={() => stopSellQtyEditing()}
                              >
                                {sellerQuoteDatas.sellToQty}
                              </CssTextField>
                            </Box>
                          ) : (
                            <Button
                              onClick={() => {
                                startSellQtyEditing(sellerQuoteDatas.id);
                                openSellQtyMenu();
                              }}
                              size="large"
                              endIcon={<EditIcon />}
                            >
                              {sellerQuoteDatas?.sellToQty
                                ? sellerQuoteDatas?.sellToQty
                                : 0.0} MT
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box
                sx={{
                  marginRight: "20px",
                  marginBottom: "20px",
                }}
              >
                <Box
                  sx={{
                    marginLeft: "auto",
                    maxWidth: "250px",
                  }}
                ></Box>
                {sellerQuoteDatas?.status === 1 && poButton == true ? (
                  <Box
                    sx={{
                      marginRight: "40px",
                      marginBottom: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        marginLeft: "auto",
                        maxWidth: "250px",
                      }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <Typography
                          sx={{
                            paddingBottom: "3px",
                            fontSize: "15px",
                            fontWeight: "500",
                            color: "#000",
                          }}
                        >
                          Amount
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#000000c7",
                          }}
                        >
                          INR {sellerQuoteDatas.amount}
                        </Typography>
                      </Box>
                      {sellerQuoteDatas?.sgst != 0 ? (
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            sx={{
                              paddingBottom: "3px",
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            SGST @ 9%
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                            }}
                          >
                            {sellerQuoteDatas?.sgst}
                          </Typography>
                        </Box>
                      ) : null}
                      {sellerQuoteDatas?.cgst != 0 ? (
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            sx={{
                              paddingBottom: "3px",
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            CGST @ 9%
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                            }}
                          >
                            {sellerQuoteDatas?.cgst}
                          </Typography>
                        </Box>
                      ) : null}
                      {sellerQuoteDatas?.igst != 0 ? (
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            sx={{
                              paddingBottom: "3px",
                              fontSize: "15px",
                              fontWeight: "500",
                              color: "#000",
                            }}
                          >
                            IGST @18%
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                            }}
                          >
                            {sellerQuoteDatas?.igst}
                          </Typography>
                        </Box>
                      ) : null}

                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        {sellerQuoteDatas?.tcs != "0" ? (
                          <>
                            <Typography
                              sx={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              TCS
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#000000c7",
                              }}
                            >
                              + {sellerQuoteDatas?.tcs}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography
                              sx={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#000",
                              }}
                            >
                              TDS
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#000000c7",
                              }}
                            >
                              - {sellerQuoteDatas?.tds}
                            </Typography>
                          </>
                        )}
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        sx={{
                          borderTop: 1,
                          borderColor: "#eaeaea",
                          paddingLeft: "2px",
                          paddingTop: "2px",
                          marginTop: "6px",
                        }}
                      >
                        <Typography
                          sx={{
                            paddingBottom: "3px",
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "#000",
                            marginTop: "7px",
                          }}
                        >
                          Total
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#000000c7",
                            marginTop: "7px",
                          }}
                        >
                          INR {sellerQuoteDatas?.total}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        // keepMounted
        open={openSwitchModel}
        onClose={handleCloseSwitch}
      >
        <Box sx={styles}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Payment Terms
          </Typography>

          <Grid container style={{ marginTop: "2rem" }}>
            <Box sx={{ marginTop: "-25px", width: "180%" }}>
              <Controls.Input
                text="Add New"
                variant="outlined"
                color="primary"
                label="Payment Terms"
                name="remark"
                value={values.remark}
                onChange={handleInputChange}
                fullWidth
              />
            </Box>

            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  marginTop: "10px",
                }}
                text="Cancel"
                onClick={handleCloseSwitch}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px", marginTop: "10px" }}
                text="Submit"
                onClick={() => handleRemarkStatus(id)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>






      <Dialog
        keepMounted
        open={openremarkPopup}
        onClose={closeRemarkBox}
        // TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogContent>

          <>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                Remarks
              </Typography>
              <IconButton onClick={closeRemarkBox}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Grid container style={{ marginTop: "1rem" }}>
              <Grid item xs={12} className="text-end">
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Remarks"
                  name="remarks"
                  value={values.remarks}
                  onChange={handleInputChange}
                  error={errors.remarks}
                  helperText={errors.remarks}
                  fullWidth
                />
              </Grid>

              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    marginTop: "20px",
                  }}
                  text="Cancel"
                  onClick={closeRemarkBox}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "0px", marginTop: "20px" }}
                  text="Submit"
                  onClick={(e) => handelRemarksChange(e)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </>

        </DialogContent>


      </Dialog>

      <Dialog
        keepMounted
        open={opentermPopup}
        onClose={closeTermBox}
        // TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
      >

        <DialogContent>

          <>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                Payment Terms
              </Typography>
              <IconButton onClick={closeRemarkBox}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Grid container style={{ marginTop: "1rem" }}>
              <Grid item xs={12} className="text-end">
                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Payment Terms"
                  name="paymentTerms"
                  value={values.paymentTerms}
                  onChange={handleInputChange}
                  error={errors.paymentTerms}
                  helperText={errors.paymentTerms}
                  fullWidth
                />
              </Grid>

              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#000",
                    marginTop: "20px",
                  }}
                  text="Cancel"
                  onClick={closeTermBox}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "0px", marginTop: "20px" }}
                  text="Submit"
                  onClick={(e) => handelTermsChange(e)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </>

        </DialogContent>


      </Dialog>
    </>
  );
}

export default SellerOrderDetails;
