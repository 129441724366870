import {
    Button,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useForm } from "../../Utils/useForm";
import {
    getSellerOrderList,
} from "../../state/action/orderAction";
import { useNavigate } from "react-router";
import moment from "moment";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { GrView } from "react-icons/gr";
import { poList } from "../../state/action/orderAction";

const initialValues = {
    productName: "",
    categoryId: "",
    fromquantity: "",
    toquantity: "",
    reason: "",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#f6f9fb",
        color: "#000",
        fontSize: 14,
        border: 0,
        fontWeight: 600,
        paddingTop: "15px",
        paddingBottom: "15px",
        paddingLeft: "25px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: "rgb(17, 24, 39)",
        border: 0,
        paddingLeft: "25px",
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&": {
        backgroundColor: "#fff",
        ":hover": { backgroundColor: "rgb(248 248 248)!important" },
    },
    "&:nth-of-type(even)": {
        backgroundColor: "rgb(250 250 250)!important",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const SellerOrderPOlist = (props) => {
    const {
        data,
        setSortFlag,
        sortFlag,
        page,
        setPage,
        dataShorting,
        flagName,
        search,
        refreshScreen,
    } = props;
    const [flagPrice, setflagPrice] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState([]);
    const [orderReqLists, setOrderReqList] = useState([]);
    const [openBackdrop, setOpenBreakdrop] = useState(false);
    const [pagePerSize, setPagePerSize] = useState(10);
    const [fieldName, setFieldName] = useState("");
    const [filterData, setFilterData] = useState(true);
    const [filter, setFilter] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        OrderLists(page, "", 0);
    }, [filterData, search, page, rowsPerPage]);

    const OrderLists = async (page, name = "", flag) => {
        setOpenBreakdrop(true);
        let string = "";
        let i = 0;
        for (let [key, value] of Object.entries(values)) {
            string += `&${key}=${value}`;
            i++;
        }
        let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}${string}&sortBy=${name}&sortFlag=${flag || 0}`;

        const res = await poList(queryString);

        if (res.status) {
            setOrderReqList(res.data);
            setTotalRecords(res.totalOrder);
            setTotalPages(Math.ceil(res.totalOrder / rowsPerPage));
            setSortFlag(!sortFlag);
            setOpenBreakdrop(false);
        } else {
            setOrderReqList([]);
            setOpenBreakdrop(false);
            setTotalRecords(0);
            setTotalPages(0);
        }
        setFilter(false);
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues);

    const handleChangePage = (e, value) => {
        if (fieldName == "productName") {
            OrderLists(value, fieldName, flagName);
        } else if (fieldName == "quantity") {
            OrderLists(value, fieldName, flagPrice);
        } else {
            OrderLists(value, "", "");
        }
        setPage(value);
    };

    return (
        <Box>
            <Box
                sx={{
                    backgroundColor: "#fff",
                    borderTopRightRadius: "20px",
                    borderTopLeftRadius: "20px",
                    border: 1,
                    borderColor: "#eaeaea",
                }}
            >
                <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    PO Number
                                </StyledTableCell>
                                <StyledTableCell onClick={() => dataShorting("productName")}>
                                    Product Name
                                    {flagName ? (
                                        <ArrowUpwardIcon
                                            sx={{
                                                color: "rgba(107, 114, 128, .5)",
                                                fontSize: "17px",
                                                marginLeft: "3px",
                                                position: "relative",
                                                top: "3px",
                                            }}
                                        />
                                    ) : (
                                        <ArrowDownwardIcon
                                            sx={{
                                                color: "rgba(107, 114, 128, .5)",
                                                fontSize: "17px",
                                                marginLeft: "3px",
                                                position: "relative",
                                                top: "3px",
                                            }}
                                        />
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>Quantity</StyledTableCell>
                                <StyledTableCell>Rate</StyledTableCell>
                                <StyledTableCell>
                                    <Button
                                        variant="text"
                                        sx={{
                                            ":hover": { bgcolor: "transparent" },
                                            paddingLeft: "0",
                                            color: "#000",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            textTransform: "capitalize",
                                            minWidth: "0",
                                        }}
                                    >
                                        Date
                                    </Button>
                                </StyledTableCell>

                                <StyledTableCell>
                                    <Button
                                        variant="text"
                                        sx={{
                                            ":hover": { bgcolor: "transparent" },
                                            paddingLeft: "0",
                                            color: "#000",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            textTransform: "capitalize",
                                            minWidth: "0",
                                        }}
                                    >
                                        Packing Type
                                    </Button>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Button
                                        variant="text"
                                        sx={{
                                            ":hover": { bgcolor: "transparent" },
                                            paddingLeft: "0",
                                            color: "#000",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            textTransform: "capitalize",
                                            minWidth: "0",
                                        }}
                                    >
                                        State
                                    </Button>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Button
                                        variant="text"
                                        sx={{
                                            ":hover": { bgcolor: "transparent" },
                                            paddingLeft: "0",
                                            color: "#000",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            textTransform: "capitalize",
                                            minWidth: "0",
                                        }}
                                    >
                                        City
                                    </Button>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Button
                                        variant="text"
                                        sx={{
                                            ":hover": { bgcolor: "transparent" },
                                            paddingLeft: "0",
                                            color: "#000",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            textTransform: "capitalize",
                                            minWidth: "0",
                                        }}
                                    >
                                        Action
                                    </Button>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderReqLists.map((row, index) => (
                                <StyledTableRow
                                    key={index}
                                    onClick={() => {
                                        navigate("/podetails", {
                                            state: {
                                                id: row.id,
                                                poButton: true,
                                                isPO: true
                                            }
                                        });
                                    }}
                                >
                                    <StyledTableCell component="th" scope="row" style={{ fontWeight: 700 }}>
                                        {row.ponumber}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.productName}
                                    </StyledTableCell>
                                    <StyledTableCell> {row.qty}MT</StyledTableCell>
                                    <StyledTableCell>{row?.price}</StyledTableCell>
                                    <StyledTableCell>
                                        {moment(row.createdAt).format("MM-DD-YYYY LT")}
                                    </StyledTableCell>
                                    <StyledTableCell>{row?.type}</StyledTableCell>
                                    <StyledTableCell>{row?.stateName}</StyledTableCell>
                                    <StyledTableCell>{row?.cityName}</StyledTableCell>
                                    <StyledTableCell onClick={(e) => e.stopPropagation()}>
                                        <Box display={"flex"} gap={1}>
                                            <Tooltip placement="bottom" title="View Order">
                                                <button
                                                    className="action-button view"
                                                    onClick={() => {
                                                        navigate("/podetails", {
                                                            state: {
                                                                id: row.id,
                                                                poButton: true,
                                                                isPO: true
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <GrView />
                                                </button>
                                            </Tooltip>
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {orderReqLists && orderReqLists.length === 0 ? (
                <div className="noDataFound"> No Data Found</div>
            ) : (
                <Box
                    sx={{
                        display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
                        marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
                        mt: 2,
                    }}
                >
                    <Box
                        sx={{
                            fontSize: "14px",
                            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                        }}
                    >
                        {" "}
                        Showing{" "}
                        {page * pagePerSize > totalRecords
                            ? totalRecords
                            : page * pagePerSize}{" "}
                        of {totalRecords} Results
                    </Box>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        sx={{
                            "& .MuiButtonBase-root ": {
                                color: "#000 !important",
                            },
                            "& .Mui-selected ": {
                                background: "#4b4b4e70 !important",
                            },
                            "& .Mui-disabled ": {
                                background: "#6c696966 !important",
                                color: "#000000 !important",
                                opacity: 1,
                            },
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default SellerOrderPOlist;
