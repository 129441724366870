import axiosRequest from "../axios/axios";


export const addUserMargin = async (id, payload) => {
    try {
        const response = await axiosRequest.post(`/addUserMargin/${id}`, payload);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const deleteCompany = async (id, payload) => {
    try {
        const response = await axiosRequest.patch(`/deletecompanybyadmin/${id}`, payload);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}
