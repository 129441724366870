import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  styled,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Controls from "../../Component/controls/Controls";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate } from "react-router";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Modal from "@mui/material/Modal";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import "./userDetails.css";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { updatePan, updateGST } from "../../state/action/userAction";
import { Spin } from "antd";



import {
  approveuser,
  documentStatus,
  getRoleList,
  getUserRequestDetails,
} from "../../state/action/userAction";
import {
  getCityUserLists,
  getStateUserList,
} from "../../state/action/locationAction";
import { getCategoryDropdown } from "../../state/action/categoryAction";
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../Utils/useForm";
import Multiselect from "multiselect-react-dropdown";
import Slide from "@mui/material/Slide";
import { upload } from "@testing-library/user-event/dist/upload";


const initialValues = {
  role: "",
  status: "",
  userLocation: "",
  category: "",
  categoryIds: [],
  gstCertificate: "",
};

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "220px",
  borderRadius: "10px",
  textAlign: "center",
}));
const style = {
  overflow: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  minHeight: "50px",
  maxHeight: "90vh",
  bgcolor: "#FFFFFF",
  padding: "20px",
  borderRadius: "10px",
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});
const StyledInput = styled("input")({
  display: "none",
});

function AddDynamicInput() {
  const [val, setVal] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState([]);
  const [role, SetRole] = useState([]);
  const [hideButton, setHideButton] = useState(false);
  const [hideButtons, setHideButtons] = useState(false);
  const [hideDocumentButton, setHideDocumentButtons] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [openImageModel, setOpenImageModel] = useState(false);
  const [image, setImage] = useState("");
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [images1, setImages1] = useState("");
  const [loading, setLoading] = useState(false);

  const [hideDocumentButtonStatus, setHideDocumentButtonsStatus] =
    useState(false);

  const [userLocations, setUserLocations] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenImage = () => {
    setOpenImageModel(true);
  };
  const handleCloseImage = (e) => {
    if (e) {
      e.preventDefault();
    }
    setOpenImageModel(false);
  };

  const handleImageItem1 = async (e) => {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["application/pdf"];

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setImages1(selected.name);
      setValues({ ...values, gstCertificate: selected });
      AddGstCertificate(selected);

    } else {
    }
  };

  const AddGstCertificate = async (selected) => {
    const data = new FormData();
    data.append("gstCertificate", selected);
    const response = await updateGST(companyId, data);
    if (response.status === true) {
      enqueueSnackbar("GST Certificate Updated Successfully", {
        variant: "success",
      });

      setValues((previousValues) => {
        var newValues = { ...previousValues };
        delete newValues["gstCertificate"];
        return newValues;
      });
      setImages1("");
      getDetails(id);

    }
    else {
      enqueueSnackbar(response.message, {
        variant: "error",
      });

    }
  };




  async function deleteImage1(index) {
    setImages1("");
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["gstCertificate"];
      return newValues;
    });
  }


  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image"];
      return newValues;
    });
  };
  const resetImage = () => {
    setImage("");
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("panImage", values.image);

    const response = await updatePan(companyId, data);
    // if (response.status === true) {
    enqueueSnackbar("Pan Image Updated Successfully", {
      variant: "success",
    });
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["image"];
      return newValues;
    });
    //call details api
    getDetails(id)
    handleCloseImage();
    // }
    // else {
    //   enqueueSnackbar("Something went wrong", {
    //     variant: "error",
    //   });
    // }


  };



  const companyId = userDetail?.company?.id;

  const navigate = useNavigate();

  const handleAdd = (e) => {
    e.preventDefault();
    const abc = [...val, []];
    setVal(abc);
  };

  const handleStateChange = (e, i) => {
    setUserLocations((prevState) => {
      const newState = [...prevState];
      newState[i] = {
        ...newState[i],
        stateId: e.target.value,
      };
      return newState;
    });
  };


  const handleDelete = (i) => {
    setUserLocations((prevState) => {
      const newState = [...prevState];
      newState.splice(i + 1, 1);
      return newState;
    }
    );
    setVal((prevState) => {
      const newState = [...prevState];
      newState.splice(i, 1);
      return newState;
    }
    );
  };


  async function handleImageItem(e) {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      const base64String = await getBase64(selected);
      setImage(base64String);
      setValues({ ...values, image: selected });
    } else {
    }
  }
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const validate = (fieldValues = values) => { };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const { state } = useLocation();

  const id = state;

  useEffect(() => {
    CityList();
  }, [1]);

  const CityList = async (value) => {
    const res = await getCityUserLists(value);
    if (res.status === true) {
      setCityList(res.data);
    } else {
      setCityList([]);
    }
  };

  //statelist
  useEffect(() => {
    StateList();
  }, []);

  const StateList = async () => {
    const res = await getStateUserList();
    if (res.status === true) {
      setStateList(res.data);
    } else {
      setStateList([]);
    }
  };

  //RoleList
  useEffect(() => {
    getRole();
  }, []);

  const getRole = async () => {
    const res = await getRoleList();
    if (res && res.status === true) {
      SetRole(res.data);
    } else {
      SetRole([]);
    }
  };

  //categoryList
  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    const res = await getCategoryDropdown();
    if (res && res.status === true) {
      setCategoryList(res.data);
    } else {
      setCategoryList([]);
    }
  };

  const getDetails = async (id) => {
    setLoading(true);
    const res = await getUserRequestDetails(id);
    if (res && res.status) {
      setUserDetail(res?.data);
      setLoading(false);
    }
    else {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetails(id);
  }, [id]);

  const { enqueueSnackbar } = useSnackbar();

  const handleCityChange = (e, i) => {

    setUserLocations((prevState) => {
      const newState = [...prevState];
      newState[i] = {
        ...newState[i],
        cityId: e.target.value,
      };
      return newState;
    });
  };

  const handleSubmit = async () => {
    const body = {
      role: values?.role,
      status: 1,
      category: values.categoryIds,
      userLocation: userLocations,
    };

    if (userDetail?.company?.panCardStatus == 0) {
      enqueueSnackbar("Please verify pan document", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }

    if (userDetail?.company?.gstCertificateStatus == 0) {
      enqueueSnackbar("Please verify gst document", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }



    if (values?.role == "") {
      enqueueSnackbar("Please select a role", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }


    if (userLocations?.length === 0 && values?.role == 1) {
      enqueueSnackbar("Please select location", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }


    if (values && values?.categoryIds && values?.categoryIds?.length == 0 && values?.role == 1) {
      enqueueSnackbar("Please select category", {
        variant: "error",
        autoHideDuration: 2000,
      });
      return;
    }




    const res = await approveuser(body, id);

    if (res && res.status === true) {


      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
      setButtonDisable(true);
      if (values?.role == 1) {
        navigate("/buyer");
      }
      if (values?.role == 2) {
        navigate("/seller");
      }
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  const handleDocumentStatusChange = async () => {
    const body = {
      isGstVerified: 1,
    };

    const res = await documentStatus(companyId, body);

    if (res && res.status === true) {
      setHideDocumentButtonsStatus(true);
      getDetails(id);
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  const handlePanStatusChange = async () => {
    const body = {
      isPanVerified: 1,
    };

    const res = await documentStatus(companyId, body);

    if (res && res.status === true) {
      setHideButton(true);
      // setRefresh(!refresh);
      getDetails(id);

      // navigate("/userRequest");
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  const handlePanImageStatusChangeReject = async () => {
    const body = {
      isPanVerified: 2,
    };

    const res = await documentStatus(companyId, body);

    if (res && res.status === true) {
      setHideButtons(true);
      getDetails(id);

      // setRefresh(!refresh);
      // navigate("/userRequest");
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  const handleDocumentStatusChangeReject = async () => {
    const body = {
      isGstVerified: 2,
    };

    const res = await documentStatus(companyId, body);
    getDetails(id);

    if (res && res.status === true) {
      setHideDocumentButtons(true);
      // navigate("/userRequest");
      // setRefresh(!refresh);
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
      handleCloseDelete();
    } else {
      enqueueSnackbar(res.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  return (
    <>
      {loading ? (
        <Spin />
      ) : (<> <Box>
        <Box
          sx={{
            px: 3,
            paddingBottom: "3px",
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Typography
            sx={{
              paddingTop: "25px",
              paddingBottom: "15px",
              fontSize: "15px",
              fontWeight: "600",
              color: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/userRequest");
            }}
          >
            <KeyboardBackspaceIcon
              sx={{ verticalAlign: "top", marginRight: "5px" }}
            />
            User
          </Typography>
          <Grid container alignItems="top" style={{ marginBottom: "25px" }}>
            <Grid item sm={7} xs={12}>
              <Box display="flex">
                <Box>
                  <img
                    src={"../../assets/images/product-image.png"}
                    style={{ borderRadius: "5px" }}
                  />
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                  >
                    User Details Module
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#000",
                      marginTop: "3px",
                    }}
                  >
                    Module
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Controls.Button
                  text=" Cancel"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "red",
                    ":hover": { bgcolor: "red" },
                    textTransform: "none",
                    borderRadius: "50px",
                    boxShadow: 0,
                  }}
                  onClick={() => {
                    navigate("/userRequest");
                  }}
                />
                <Controls.Button
                  text="Save"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "rgb(79, 70, 229)",
                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                    marginLeft: "10px",
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    textTransform: "none",
                    borderRadius: "50px",
                  }}
                  variant="outlined"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box >
        <Box sx={{ backgroundColor: "rgb(241, 245, 249)" }}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              border: 1,
              borderColor: "#eaeaea",
              paddingLeft: "0px",
              paddingTop: "10px",
            }}
          >
            <Form onSubmit={handleSubmit}>
              <Box sx={{ marginTop: "15px", marginLeft: "15px" }}>
                <Box
                  sx={{
                    marginLeft: "15px",
                    maxWidth: {
                      md: "600px",
                      lg: "900px",
                    },
                  }}
                >
                  <Grid container spacing={8} style={{ marginBottom: "30px" }}>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          marginBottom: "20px",
                          fontSize: "20px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Personal Detail :
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <Typography
                            sx={{
                              paddingBottom: "3px",
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            User Name :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                            }}
                          >
                            {userDetail?.userName}
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography
                            sx={{
                              paddingBottom: "3px",
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            Email :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                            }}
                          >
                            {userDetail?.email}
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography
                            sx={{
                              paddingBottom: "3px",
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            Phone Number :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                            }}
                          >
                            {userDetail?.mobile}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          marginBottom: "20px",
                          fontSize: "20px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Company Detail :
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <Typography
                            sx={{
                              paddingBottom: "3px",
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            Company Name :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                            }}
                          >
                            {userDetail?.company?.companyName}
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography
                            sx={{
                              paddingBottom: "3px",
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            Address :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                            }}
                          >
                            {userDetail?.company?.address}
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography
                            sx={{
                              paddingBottom: "3px",
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            GST Number :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                            }}
                          >
                            {userDetail?.company?.gstNumber}
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography
                            sx={{
                              paddingBottom: "3px",
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            Pan Number :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#000000c7",
                            }}
                          >
                            {userDetail?.company?.panNumber}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Box display="flex" gap={3} sx={{ marginTop: "25px" }}>
                    <Box>
                      <Box>
                        <div className="holder">
                          {userDetail?.company?.panCardStatus !== 2 ? (
                            <Typography
                              sx={{
                                paddingBottom: "3px",
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#000",
                              }}
                            >
                              PanImage
                            </Typography>
                          ) : null}
                          {userDetail?.company?.panCardStatus !== 2 ? (
                            <div style={{
                              display: "flex",
                              // justifyContent: "space-between",
                              // alignItems: "center",
                              width: "100%",
                              marginTop: "10px",
                              cursor: "pointer",
                            }} >

                              <img
                                width="150px"
                                height="150px"
                                src={userDetail?.company?.panImage}
                                // style={{ borderRadius: "5px" }}
                                alt=""
                                onClick={handleClickOpen}
                              />
                              <Tooltip placement="bottom" title="Edit Pan Image">

                                <EditIcon
                                  onClick={(e) => {
                                    handleClickOpenImage();
                                    setImage(userDetail?.company?.panImage);
                                  }}
                                />
                              </Tooltip>
                            </div>

                          ) : null}
                          < StyledIconWrapper
                            style={{
                              margin: "0",
                              display: "flex",
                              justifyContent: "center",
                              background: "none",
                              border: "none",
                              width: "auto",
                              height: "50px",
                            }}
                          >

                          </StyledIconWrapper>
                        </div>
                      </Box>

                      <Box display="flex" sx={{ marginTop: "10px" }}>
                        {!hideButton &&
                          userDetail?.company?.panCardStatus != 2 &&
                          userDetail?.company?.panCardStatus != 1 ? (
                          <>
                            <Controls.Button
                              text="Verify"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#fff",
                                backgroundColor: "rgb(79, 70, 229)",
                                ":hover": { bgcolor: "rgb(79, 70, 229)" },
                                textTransform: "none",
                                borderRadius: "50px",
                                boxShadow: 0,
                              }}
                              onClick={(e) => handlePanStatusChange(e)}
                            />
                          </>
                        ) : null}

                        {!hideButtons &&
                          userDetail?.company?.panCardStatus != 2 &&
                          userDetail?.company?.panCardStatus != 1 ? (
                          <>
                            <Controls.Button
                              text="Reject"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "rgba(0, 0, 0, .26)",
                                backgroundColor: "rgba(0, 0, 0, 0.12)",
                                marginLeft: "10px",
                                ":hover": {
                                  borderColor: "rgba(0, 0, 0, 0.12)",
                                },
                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                textTransform: "none",
                                borderRadius: "50px",
                              }}
                              variant="outlined"
                              onClick={(e) =>
                                handlePanImageStatusChangeReject(e)
                              }
                              value={values.status}
                            />
                          </>
                        ) : null}
                      </Box>
                    </Box>
                    <Box>
                      <Box>
                        {userDetail?.company?.gstCertificateStatus != 2 ? (
                          <Typography
                            sx={{
                              paddingBottom: "3px",
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            Gst Cetificate
                          </Typography>
                        ) : null}
                        {userDetail?.company?.gstCertificateStatus != 2 ? (
                          <Box sx={{ textAlign: "end" }}>
                            < StyledIconWrapper
                              style={{
                                margin: "0",
                                display: "flex",
                                justifyContent: "center",
                                background: "none",
                                border: "none",
                                width: "auto",
                                height: "50px",
                              }}
                            >
                            </StyledIconWrapper>
                            <StyledIconWrapper
                              style={{
                                // margin: "0",
                                display: "flex",
                                justifyContent: "center",
                                background: "none",
                                border: "none",
                                width: "auto",
                                height: "50px",
                                // marginTop:""
                              }}
                            >
                              <a
                                href={userDetail?.company?.gstCertificate}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Controls.Button
                                  text="PDF"
                                  sx={{
                                    fontSize: "11px",
                                    fontWeight: "500",
                                    color: "#fff",
                                    backgroundColor: "rgb(79, 70, 229)",
                                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                                    textTransform: "none",
                                    borderRadius: "50px",
                                    boxShadow: 0,
                                  }}
                                />
                              </a>
                              <Tooltip placement="bottom" title="Remove GST Certificate">

                                <CloseIcon
                                  onClick={(e) => {
                                    setDeleteId(userDetail?.company?.id);
                                    handleDeleteModel();
                                    // handleClickOpenImage();
                                    // setImage(userDetail?.company?.gstCertificate);
                                  }}
                                />
                              </Tooltip>
                            </StyledIconWrapper>
                          </Box>
                        ) : <Box display="flex">
                          <Box>
                            <Button
                              variant="contained"
                              component="label"
                              sx={{
                                ":hover": { bgcolor: "transparent" },
                                textTransform: "none",
                                fontSize: "16px",
                                backgroundColor: "#f6f9fb",
                                borderRadius: "15px",
                                padding: "40px",
                              }}
                            >
                              <DriveFolderUploadIcon
                                sx={{
                                  fontSize: "40px",
                                  color: "rgb(107, 114, 128)",
                                  display: "flex",
                                }}
                              />
                              <input
                                type="file"
                                hidden
                                name="gstCertificate"

                                onChange={(e) => {
                                  handleImageItem1(e)
                                }}
                                accept="application/pdf"
                              />
                            </Button>
                            <Typography
                              sx={{ fontSize: "13px", color: "#000", marginTop: "7px" }}
                            >
                              Gst Certificate
                            </Typography>
                          </Box>

                          <Box sx={{ textAlign: "end" }}>
                            {images1 && images1 !== "" && (
                              <StyledIconWrapper
                                style={{}}
                                sx={{
                                  position: "relative",
                                  margin: "25px 5px 5px 5px",
                                  width: "147px",
                                  height: "140px",
                                  background: "none !important",
                                  border: "none",
                                }}
                                onClick={() => {
                                  // setIconFlag(id);
                                }}
                              >
                                <StyledIconWrapper
                                  style={{
                                    margin: "0",
                                    display: "flex",
                                    justifyContent: "center",
                                    background: "none",
                                    border: "none",
                                    width: "auto",
                                    height: "50px",
                                  }}
                                >
                                  {/* <img style={{ marginTop: "13px" }} src={PictureAsPdfIcon} alt="Document" /> */}
                                  <PictureAsPdfIcon
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      color: "rgb(118 116 107)",
                                    }}
                                  />
                                </StyledIconWrapper>

                                <p style={{ fontSize: "12px", textAlign: "center" }}>
                                  {" "}
                                  {images1 !== "" && images1}
                                </p>
                                <IconButton
                                  size="large"
                                  sx={{
                                    position: "absolute",
                                    right: "2%",
                                    top: "3%",
                                    width: "20px",
                                    height: "20px",
                                    background: "rgba(15, 15, 15, 0.5)",
                                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                                    borderRadius: "10px",

                                    color: "white",
                                    "&:hover": {
                                      backgroundColor: "#FFC400",
                                    },
                                  }}
                                >
                                  <CloseIcon
                                    sx={{ width: "20px", height: "20px" }}
                                    onClick={() => {
                                      deleteImage1();
                                    }}
                                  />
                                </IconButton>
                              </StyledIconWrapper>
                            )}
                          </Box>
                        </Box>}
                      </Box>

                      <Box display="flex" sx={{ marginTop: "10px" }}>
                        {!hideDocumentButtonStatus &&
                          userDetail?.company?.gstCertificateStatus != 2 &&
                          userDetail?.company?.gstCertificateStatus != 1 ? (
                          <>
                            <Controls.Button
                              text="Verify"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#fff",
                                backgroundColor: "rgb(79, 70, 229)",
                                ":hover": { bgcolor: "rgb(79, 70, 229)" },
                                textTransform: "none",
                                borderRadius: "50px",
                                boxShadow: 0,
                                marginTop: "30px",
                                height: "30px",
                              }}
                              onClick={(e) => handleDocumentStatusChange(e)}
                              value={values.status}
                            />
                          </>
                        ) : null}

                        {!hideDocumentButton &&
                          userDetail?.company?.gstCertificateStatus != 2 &&
                          userDetail?.company?.gstCertificateStatus != 1 ? (
                          <>
                            <Controls.Button
                              text="Reject"
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "rgba(0, 0, 0, .26)",
                                backgroundColor: "rgba(0, 0, 0, 0.12)",
                                marginLeft: "10px",
                                ":hover": {
                                  borderColor: "rgba(0, 0, 0, 0.12)",
                                },
                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                textTransform: "none",
                                borderRadius: "50px",
                                marginTop: "30px",
                                height: "30px",
                              }}
                              onClick={(e) =>
                                handleDocumentStatusChangeReject(e)
                              }
                              variant="outlined"
                            />
                          </>
                        ) : (
                          null
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    maxWidth: {
                      xs: "450px",
                      sm: "450px",
                      md: "500px",
                      lg: "650px",
                    },
                    marginLeft: {
                      xs: "5px",
                      md: "5px",
                      lg: "15px",
                    },
                    marginTop: "25px",
                    paddingBottom: "20px",
                  }}
                >
                  {userDetail?.company?.gstCertificateStatus == 1 &&
                    userDetail?.company?.panCardStatus == 1 ? (
                    <Box sx={{ marginTop: "15px" }}>
                      <Controls.SelectBox
                        text="Add New"
                        className="input"
                        fullWidth
                        id="Role"
                        name="role"
                        value={values.role}
                        onChange={(e) => {
                          setUserLocations([]);
                          setValues({
                            ...values,
                            categoryIds: [],
                            role: e.target.value,
                          });
                        }}

                        color="primary"
                        options={role}
                        label="Role"
                      />
                    </Box>
                  ) : null}
                  <Box sx={{ marginTop: "15px" }}>
                    <>
                      {userDetail?.company?.gstCertificateStatus == 1 &&
                        values.role != 2 &&
                        values.role != "" ? (
                        <Box display="flex" sx={{ alignItems: "center" }}>
                          <Box
                            display="flex"
                            gap={2}
                            sx={{ width: "100%", alignItems: "center" }}
                          >
                            <Box sx={{ width: "100%" }}>
                              <select
                                text="Add New"
                                className="input"
                                fullWidth
                                id="State"
                                name="stateList"
                                value={userLocations[0]?.stateId}
                                onChange={(e) => {
                                  handleStateChange(e, 0);
                                  handleInputChange(e);
                                }}
                                color="primary"
                                label="State"
                              >
                                <option value="">Select State</option>
                                {stateList.map((data, i) => {
                                  return (
                                    <option value={data.id}>{data.name}</option>
                                  );
                                })}
                              </select>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                              <select
                                text="Add New"
                                className="input"
                                fullWidth
                                id="City"
                                name="cityList"
                                value={userLocations[0]?.cityId}
                                onChange={(e) => {
                                  handleCityChange(e, 0);
                                }}
                                color="primary"
                                label="City"
                              >
                                <option value="">Select City</option>
                                {cityList
                                  .filter((data) => {
                                    return (
                                      data.stateId == userLocations[0]?.stateId
                                    );
                                  })
                                  .map((data, i) => {
                                    return (
                                      <option value={data.id}>
                                        {data.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </Box>
                          </Box>
                          <button
                            onClick={(e) => handleAdd(e)}
                            className="add_button"
                          >
                            +
                          </button>
                        </Box>
                      ) : null}
                    </>
                  </Box>

                  {userLocations.map((data, i) => {
                    return (
                      <Box
                        display="flex"
                        sx={{ alignItems: "center", marginTop: "12px" }}
                      >
                        <Box
                          display="flex"
                          gap={2}
                          sx={{ width: "100%", alignItems: "center" }}
                        >
                          <Box sx={{ width: "100%" }}>
                            <select
                              text="Add New"
                              className="input"
                              fullWidth
                              id="State"
                              name="stateList"
                              value={userLocations[i + 1]?.stateId}
                              onChange={(e) => {
                                handleStateChange(e, i + 1);
                                handleInputChange(e);
                              }}
                              color="primary"
                              label="State"
                            >
                              <option value="">Select State</option>
                              {stateList.map((data, i) => {
                                return (
                                  <option value={data.id}>{data.name}</option>
                                );
                              })}
                            </select>
                          </Box>
                          <Box sx={{ width: "100%" }}>
                            <select
                              text="Add New"
                              className="input"
                              fullWidth
                              id="City"
                              name="cityList"
                              color="primary"
                              value={userLocations[i + 1]?.cityId}
                              label="City"
                              onChange={(e) => {
                                handleCityChange(e, i + 1);
                              }}
                            >
                              <option value="">Select City</option>
                              {cityList
                                .filter((data) => {
                                  return (
                                    data.stateId ==
                                    userLocations[i + 1]?.stateId
                                  );
                                })
                                .map((data, i) => {
                                  return (
                                    <option value={data.id}>{data.name}</option>
                                  );
                                })}
                            </select>
                          </Box>
                        </Box>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleDelete(i);
                          }}
                          className="adddelete"
                        >
                          <DeleteForeverIcon />
                        </button>
                      </Box>
                    );
                  })}

                  <Box sx={{ marginTop: "15px" }}>
                    <Box display="flex" sx={{ alignItems: "center" }}>
                      <>
                        {userDetail?.company?.gstCertificateStatus !== 2 &&
                          userDetail?.company?.panCardStatus !== 2 &&
                          values.role !== 2 &&
                          values.role !== "" ? (
                          <Box
                            display="flex"
                            gap={2}
                            sx={{ width: "100%", alignItems: "center" }}
                          >
                            <Box sx={{ width: "100%" }}>
                              <Multiselect
                                options={categoryList}
                                displayValue="name"
                                closeOnSelect={true}
                                showArrow={true}
                                selectedValues={values.category}
                                onSelect={(e) => {
                                  setValues({
                                    ...values,
                                    categoryIds: e.map((item) => item.id),
                                  });
                                }}
                                onRemove={(e) => {
                                  setValues({
                                    ...values,
                                    categoryIds: e.map((item) => item.id),
                                  });
                                }}
                                placeholder="Select Category"
                              />
                            </Box>
                          </Box>
                        ) : null}
                      </>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Form>
          </Box>
        </Box>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <img
              width="40%"
              height="28%"
              style={{ marginLeft: "158px" }}
              src={userDetail?.company?.panImage}
              alt=""
            // onClick={handleClickOpen}
            />
          </DialogContent>
        </Dialog>
        <Modal
          open={openImageModel}
          onClose={setOpenImageModel}
        >
          <Box sx={style}>
            <AiOutlineCloseCircle
              className="close-button"
              onClick={handleCloseImage}
            />

            <p className="modal-title">
              Add Image
            </p>

            <form
              onSubmit={submitHandler}
              sx={{
                "& .MuiTextField-root": { my: 1 },
              }}
              autoComplete="off"
            >

              <Grid container spacing={2}>
                <Grid
                  item={true}
                  md={12}
                  container
                  xs={12}
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <Grid item={true} md={8} xs={12}>
                    <StyledImageUploadWrapper>
                      <label htmlFor="image-upload">
                        <StyledInput
                          accept="image/*"
                          id="image-upload"
                          type="file"
                          onChange={handleImageItem}
                          onClick={(event) => {
                            event.currentTarget.value = null;
                          }}
                        />
                        <StyledIconWrapper
                          sx={
                            image == ""
                              ? {}
                              : {
                                backgroundImage: `url(${image})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                              }
                          }
                        >
                          {image === "" && <DriveFolderUploadIcon
                            sx={{
                              fontSize: "40px",
                              color: "rgb(107, 114, 128)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",

                            }}
                          />}
                        </StyledIconWrapper>
                      </label>
                      <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                        Add Photo
                      </Typography>
                      <Typography
                        sx={{
                          color: "rgba(15, 15, 15, 0.5)",
                          mx: 2,
                          fontSize: "12px",
                        }}
                      >
                        Allowed *.jpeg, *.jpg, *.png
                      </Typography>
                      {image != "" && (
                        <StyledRemoveButton
                          onClick={(e) => {
                            e.preventDefault();
                            removeImage();
                          }}
                        >
                          Remove
                        </StyledRemoveButton>
                      )}
                    </StyledImageUploadWrapper>
                  </Grid>


                </Grid>

                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  container
                  style={{ alignItems: "center", justifyContent: "center" }}
                >


                  <Grid item xs={12} className="form-button-container">
                    <button
                      className="form-action-button"
                      onClick={(e) => { handleCloseImage(e) }}
                    >
                      Cancel
                    </button>
                    &nbsp;&nbsp;&nbsp;

                    <button className="form-action-button-submit"
                      // onClick={(e) => { handleCloseImage(e) }}
                      type="submit"

                      disabled={values.image == "" || values.image == null || values.image == undefined ? true : false}
                      //if button is disabled then color is grey and  font color is light grey
                      style={values.image == "" || values.image == null || values.image == undefined ? { backgroundColor: "#e0e0e0", color: "#9e9e9e" } : { backgroundColor: "#3f51b5", color: "white" }}
                    >
                      Save
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <label className="fontLight">
              Are you sure to want to delete this GST Certificate?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseDelete} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Delete"
                  style={{ backgroundColor: "red" }}
                  onClick={() => handleDocumentStatusChangeReject()}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>


      </Box ></>)}

    </>
  );
}
export default AddDynamicInput;
