import React, { useEffect, useState } from "react";
import {
  Typography,
  InputBase,
  Pagination,
  Tooltip,
  Divider,
  Drawer,
  Stack,
  styled,
  FormLabel,
  TableRow,
  tableCellClasses,
  TextField,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Modal,
  Slider,
  Autocomplete,
} from "@mui/material";
import Controls from "../../Component/controls/Controls";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate } from "react-router";

import {
  getProduct,
  productStatusChange,
  deleteProduct,
  getProductList,
} from "../../state/action/productAction";
import { useForm } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { GrView } from "react-icons/gr";
import { Space } from "antd";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import { getCategoryDropdown } from "../../state/action/categoryAction";
import Search from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";

const initialValues = {
  productName: "",
  status: "",
  margin: "",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 14,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const CssTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "yellow",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
}));

const TdsTcs = () => {
  const [value, setValue] = useState("1");
  const [deleteValue, setDeleteValue] = useState(false);
  const [switchId, setSwitchId] = useState(null);
  const [checked, setChecked] = useState(true);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [status, setStatus] = useState();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [search, setSearch] = useState("");
  const [flagName, setflagName] = useState(false);
  const [flagPrice, setflagPrice] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState([]);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [productList, setProductList] = useState([]);
  const [sortFlag, setSortFlag] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [notify, setNotify] = useState({});
  const [fieldName, setFieldName] = useState("");
  const [range, setRange] = useState([10, 57]);
  const [categoryList, setCategoryList] = useState([]);
  const [items, setItems] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    const res = await getCategoryDropdown();
    if (res && res.status === true) {
      setCategoryList(res.data);
      // setcategoryName(res.data);
    } else {
      setCategoryList([]);
    }
  };
  const [isStatusEditing, setStatusEditing] = useState(false);
  const [statusMenu, setStatusMenu] = useState(true);
  const [save, setSave] = useState(false);

  const openStatusMenu = () => {
    setStatusMenu(true);
  };
  const closeStatusMenu = () => {
    setStatusMenu(false);
  };

  const startStatusEditing = (id) => {
    setStatusEditing(id);
  };

  const startStatusUpdate = (e, id) => {
    // setOrdersdata({
    //   ...ordersData,
    //   productPrice: e.target.value,
    // });
    setSave(true);
  };

  const stopStatusEditing = (id) => {
    setStatusEditing(id);
  };

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangess = (event, newValue) => {
    setRange(newValue);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const { state } = useLocation();
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const handleChangePage = (e, value) => {
    if (fieldName == "name") {
      ProductList(value, fieldName, flagName);
    } else if (fieldName == "margin") {
      ProductList(value, fieldName, flagPrice);
    } else {
      ProductList(value, "", "");
    }
    setPage(value);
  };

  useEffect(() => {
    ProductList(page, "", "");
  }, [filterData, search, refresh]);

  const ProductList = async (page, name = "", flag) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let queryString = `?page=${page}&limit=${rowsPerPage}&search=${search}${string}&sortBy=${name}&sortFlag=${flag}`;

    const res = await getProductList(queryString);

    if (res.status === true) {
      setProductList(res.data);
      setTotalRecords(res.totalProduct);
      setTotalPages(Math.ceil(res.totalProduct / rowsPerPage));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setProductList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const dataShorting = (name) => {
    if (name === "name") {
      setflagName(!flagName);
      ProductList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    if (name === "margin") {
      setflagPrice(!flagPrice);
      ProductList(page, name, !flagPrice);
    } else {
      setflagPrice(false);
      setSortFlag(!sortFlag);
    }
    setFieldName(name);
  };

  const handledelete = () => {
    setDeleteValue(true);
  };
  const closedelete = () => {
    setDeleteValue(false);
  };

  const handleChangeStatus = async () => {
    // setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      status: checked ? 1 : 0,
    };
    const res = await productStatusChange(switchId, payload);

    if (res && res.status === true) {
      if (fieldName == "name") {
        ProductList(page, fieldName, flagName);
      } else if (fieldName == "margin") {
        ProductList(page, fieldName, flagPrice);
      } else {
        ProductList(page, "", "");
      }
      handleCloseSwitch();
      // setRefresh(!refresh);
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={7} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                TDS/TCS
              </Typography>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "block",
                    sm: "flex-end",
                    md: "flex-end",
                    lg: "flex-end",
                  },
                  marginTop: { xs: "20px", md: "0", lg: "0" },
                }}
              >
                {save === true ? (
                  <Controls.Button
                    text="Save"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                      backgroundColor: "rgb(79, 70, 229)",
                      marginLeft: "800px",
                      textTransform: "none",
                    }}
                    // onClick={handleSubmit}
                    variant="outlined"
                  />
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "rgb(241, 245, 249)",
            }}
          >
            <Box
              sx={{
                paddingTop: "10px",
                paddingLeft: "5px",
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                border: 1,
                borderColor: "#eaeaea",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            ></Box>
          </Box>

          <Box
            sx={{
              backgroundColor: "#fff",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              border: 1,
              borderColor: "#eaeaea",
            }}
          >
            <div>
              <Box
                sx={{ width: "100%", backgroundColor: "rgb(241, 245, 249)" }}
              >
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderTopRightRadius: "20px",
                    borderTopLeftRadius: "20px",
                    border: 1,
                    borderColor: "#eaeaea",
                    maxHeight: "485px",
                    overflow: "scroll",
                  }}
                >
                  <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell onClick={() => dataShorting("name")}>
                            Name
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            onClick={() => dataShorting("margin")}
                          >
                            Margin
                            {flagPrice ? (
                              <ArrowUpwardIcon
                                sx={{
                                  color: "rgba(107, 114, 128, .5)",
                                  fontSize: "17px",
                                  marginLeft: "3px",
                                  position: "relative",
                                  top: "3px",
                                }}
                              />
                            ) : (
                              <ArrowDownwardIcon
                                sx={{
                                  color: "rgba(107, 114, 128, .5)",
                                  fontSize: "17px",
                                  marginLeft: "3px",
                                  position: "relative",
                                  top: "3px",
                                }}
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell>Date</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {productList &&
                          productList.length > 0 &&
                          productList.map((row) => (
                            <StyledTableRow
                              key={row.name}
                              hover
                              style={{ cursor: "pointer" }}
                            >
                              <StyledTableCell component="th" scope="row">
                                {row.productName}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {isStatusEditing ? (
                                  <Box sx={{ marginTop: "15px" }}>
                                    <CssTextField
                                      sx={{
                                        input: { color: "black" },
                                        fieldset: { borderColor: "black" },
                                      }}
                                      size="small"
                                      variant="outlined"
                                      id="productPrice"
                                      name="productPrice"
                                      // value={ordersData?.productPrice}
                                      open={statusMenu}
                                      onOpen={openStatusMenu}
                                      onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                          stopStatusEditing();
                                          closeStatusMenu();
                                        }
                                      }}
                                      onChange={(event) =>
                                        startStatusUpdate(event)
                                      }
                                      onBlur={() => stopStatusEditing()}
                                    ></CssTextField>
                                  </Box>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      startStatusEditing();
                                      openStatusMenu();
                                    }}
                                    size="large"
                                    endIcon={<EditIcon />}
                                  ></Button>
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                                {moment(row.createdAt).format("MM-DD-YYYY LT")}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
              {productList && productList.length === 0 ? (
                <div className="noDataFound"> No Data Found</div>
              ) : (
                <Box
                  sx={{
                    display: {
                      xs: "block",
                      sm: "flex",
                      md: "flex",
                      lg: "flex",
                    },
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: {
                      xs: "0",
                      sm: "20px",
                      md: "20px",
                      lg: "20px",
                    },
                    marginRight: {
                      xs: "0",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                    },
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "14px",
                      marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                    }}
                  >
                    {" "}
                    Showing{" "}
                    {page * rowsPerPage > totalRecords
                      ? totalRecords
                      : page * rowsPerPage}{" "}
                    of {totalRecords} Results
                  </Box>
                  <Box>
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handleChangePage}
                      variant="outlined"
                      shape="rounded"
                      sx={{
                        "& .MuiButtonBase-root ": {
                          color: "#000 !important",
                        },
                        "& .Mui-selected ": {
                          background: "#4b4b4e70 !important",
                        },
                        "& .Mui-disabled ": {
                          background: "#6c696966 !important",
                          color: "#000000 !important",
                          opacity: 1,
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}
            </div>
          </Box>
        </TabContext>
      </Box>
    </>
  );
};

export default TdsTcs;
