import React, { useState, useEffect } from "react";
// import Controls from "../../Component/controls/Controls";
import {
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { useForm, Form } from "../../Utils/useForm";
import { useSnackbar } from "notistack";
import { Space, Spin, Skeleton } from 'antd';
import { termsUpdate, termsDetails } from "../../state/action/termsAction";
import Controls from "../../Component/controls/Controls";

const initialValues = {
  name: "",
  category: "",
  role: 1,
  description1: "",
  description2: "",
  description3: "",
  description4: "",
  description5: "",
};

function AddTerms() {
  const [editorState, setEditorState] = useState();
  const [questionContent, setQuestionContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const validate = (fieldValues = values) => {
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const getTermsDetails = async (id) => {
    setLoading1(true);
    console.log("id", id);
    if (id === "" || id === null || id === undefined) {

      setTimeout(() => {
        setLoading1(false);
      }, 500);
      setValues({
        ...values,
        description1: "",
        description2: "",
        description3: "",
        description4: "",
        description5: "",
        role: id
      });

      return;
    }

    const response = await termsDetails(id);

    if (response.status === true) {
      setValues({
        ...values,
        description1: response.data.description1,
        description2: response.data.description2,
        description3: response.data.description3,
        description4: response.data.description4,
        description5: response.data.description5,
        role: id
      });
      setLoading1(false);
    }
    else {
      setLoading1(false);
      enqueueSnackbar(response.message, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getTermsDetails(values.role);
  }, []);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (values.role === "" || values.role === null || values.role === undefined) {
      enqueueSnackbar("Please Select User", {
        variant: "error",
      });
      setLoading(false);
      return;
    }
    const data = {
      description1: values.description1,
      description2: values.description2,
      description3: values.description3,
      description4: values.description4,
      description5: values.description5,
    };

    const response = await termsUpdate(values.role, data);
    if (response.status === true) {
      setLoading(false);
      enqueueSnackbar("Terms & Conditions Add Successfully", {
        variant: "success",
      });
      setEditorState(null);
      setValues({
        ...values,
        role: "",
      });
    } else {
      setLoading(false);
      enqueueSnackbar(response.message, {
        variant: "error",
      });
    }
  };

  const user = [
    {
      id: 1,
      name: "buyer",
    },
    {
      id: 2,
      name: "seller",
    },
  ];


  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Typography
          sx={{
            paddingTop: "25px",
            paddingBottom: "15px",
            fontSize: "15px",
            fontWeight: "600",
            color: "#000",
            cursor: "pointer",
          }}
        ></Typography>
        <Grid container alignItems="top" style={{ marginBottom: "25px" }}>
          <Grid item sm={7} xs={12}>
            <Box display="flex">
              <Box>
                <img
                  src={"../../assets/images/product-image.png"}
                  style={{ borderRadius: "5px" }}
                />
              </Box>
              <Box sx={{ marginLeft: "10px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                >
                  Terms & Conditions
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    marginTop: "3px",
                  }}
                >
                  Terms & Conditions
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item sm={5} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Controls.Button
                text="Save"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#fff",
                  backgroundColor: "rgb(79, 70, 229)",
                  ":hover": { bgcolor: "rgb(79, 70, 229)" },
                  marginLeft: "10px",
                  textTransform: "none",
                  borderRadius: "50px",
                  boxShadow: 0,
                }}
                variant="outlined"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {loading1 ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
          <Spin size="large" tip="Loading..." />
        </div>
      ) : (
        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              border: 1,
              borderColor: "#eaeaea",
              paddingLeft: "0px",
              paddingTop: "10px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  width: {
                    md: "100%",
                    lg: "650px",
                  },
                }}
              >
                <Form onSubmit={handleSubmit}>
                  <Box sx={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}>
                    {loading ? (
                      <Skeleton.Input style={{ width: "600px", height: "35px" }} active />
                    ) : (
                      <Controls.SelectBox
                        name="role"
                        onChange={(e) => {
                          // handleInputChange(e);
                          getTermsDetails(e.target.value);

                        }}
                        id="role"
                        value={values.role}
                        error={errors.role}
                        label="User"
                        options={user}
                      />
                    )}
                    {Boolean(errors.category) ? (
                      <p
                        style={{
                          color: "rgb(255 0 0)",
                          marginTop: "4px",
                          marginBottom: "4px",
                          fontSize: "14px",
                        }}
                      >
                        {errors.category}
                      </p>
                    ) : (
                      ""
                    )}
                  </Box>

                </Form>

              </Box>
              <div
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  marginLeft: "20px",
                  marginRight: "20px",
                  width: "56%",
                }}
              >
                {/* <Skeleton loading={loading || loading1} active> */}
                {/* <Editor
                  editorState={editorState}
                  onEditorStateChange={handleQuestionChange}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  //fontFamily remove
                  toolbar={{
                    fontFamily: {
                      //disable fontFamily dropdown in toolbar
                      options: ["Montserrat, Inter"],
                    },
                  }}
                /> */}
                <Controls.Input
                  name="description1"
                  label="Terms & Conditions 1"
                  value={values.description1}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  style={{ marginTop: "20px" }}
                  error={errors.description1}
                />

                <Controls.Input
                  name="description2"
                  label="Terms & Conditions 2"
                  value={values.description2}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  style={{ marginTop: "20px" }}

                  error={errors.description2}
                />

                <Controls.Input
                  name="description3"
                  label="Terms & Conditions 3"
                  value={values.description3}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  style={{ marginTop: "20px" }}

                  error={errors.description3}
                />

                <Controls.Input
                  name="description4"
                  label="Terms & Conditions 4"
                  value={values.description4}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  style={{ marginTop: "20px" }}

                  error={errors.description4}
                />
                <Controls.Input
                  name="description5"
                  label="Terms & Conditions 5"
                  value={values.description5}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  style={{ marginTop: "20px" }}

                  error={errors.description4}
                />


                {/* </Skeleton> */}
              </div>
            </Box>
          </Box>
        </Box >
      )}
      {/* )}/ */}
    </>
  );
}

export default AddTerms;
