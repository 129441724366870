import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Typography,
  InputBase,
  Pagination,
  Tooltip,
  Divider,
  Chip,
  TextField,
  Slider,
  Autocomplete,
} from "@mui/material";
import Controls from "../../Component/controls/Controls";
import { Drawer, FormLabel } from "@mui/material";
import { useForm } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Box, Grid } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Space } from "antd";
import { GrView } from "react-icons/gr";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import BuyerorderPendingList from "./BuyerorderPendingList";
import { getOrderList } from "../../state/action/orderAction";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import { getCompany } from "../../state/action/userAction";
import Search from "@mui/icons-material/Search";
import { getproductDropdown } from "../../state/action/productAction";

const initialValues = {
  // product: "",
  company: "",
  quantity: "",
  productPrice: "",
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiTextField-root {
    padding-top: 20px;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiOutlinedInput-input {
    padding: 10px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;
    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #000;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    color: #4f46e5 !important;
    border-color: #4f46e5;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 14,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },
  "&:nth-of-type(even)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

function createData(
  Productname,
  companyname,
  quantity,
  price,
  OrderStatus,
  action
) {
  return { Productname, companyname, quantity, price, OrderStatus, action };
}

const rows = [
  createData("70d4d7d0", "Canvas Print", "32000.0", "23/01/2023"),
  createData("70d4d7d0", "Canvas Print", "32000.0", "23/01/2023"),
];

export default function CustomizedTables() {
  const [deleteValue, setDeleteValue] = useState(false);
  const [switchId, setSwitchId] = useState(null);
  const [checked, setChecked] = useState(true);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [status, setStatus] = useState();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [search, setSearch] = useState("");
  const [flagName, setflagName] = useState(false);
  const [flagCompanyName, setflagCompanyName] = useState(false);
  const [flagPrice, setflagPrice] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [sortFlag, setSortFlag] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [notify, setNotify] = useState({});
  const [value, setValue] = useState("");
  const [pagePerSize, setPagePerSize] = useState(10);
  const [range, setRange] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [company, setCompanyName] = useState([]);
  const [items, setItems] = useState([]);
  const [itemss, setItemss] = useState([]);
  const [companyId, setCompanyId] = useState([]);
  const [productId, setProductId] = useState([]);
  const [fieldName, setFieldName] = useState("");
  const [productDropdown, setProductDropdown] = useState([]);

  const refreshScreen = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    ProdcutList();
  }, []);

  const ProdcutList = async () => {
    const res = await getproductDropdown();
    if (res.status === true) {
      setProductDropdown(res.data);
    } else {
      setProductDropdown([]);
    }
  };

  const handleChangePage = (event, value) => {
    if (fieldName == "productName") {
      OrderList(value, fieldName, flagName);
    } else if (fieldName == "companyName") {
      OrderList(value, fieldName, flagCompanyName);
    } else if (fieldName == "quantity") {
      OrderList(value, fieldName, flagPrice);
    } else {
      OrderList(value, "", "");
    }
    setPage(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (event, newValue) => {
    localStorage.setItem("Orders_tabValue", newValue);
    setValue(newValue);
    setPage(1);
  };


  useEffect(() => {
    const tabValue = localStorage.getItem("Orders_tabValue");
    setValue(tabValue ? tabValue : "1");
    OrderList(page, "", "");
  }, []);

  const handleChanges = (event, newValue) => {
    setRanges(newValue);
  };

  const navigate = useNavigate();

  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleSubmit = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      fromRate: ranges[0],
      toRate: ranges[1],
      product: productId,
      company: companyId,
    });
    setFilterData(!filterData);
  };

  const CompanyList = async () => {
    const res = await getCompany(0);
    if (res.status === true) {
      setCompanyName(res.data);
    } else {
      setCompanyName([]);
    }
  };

  //companylist
  useEffect(() => {
    CompanyList();
  }, []);

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };

  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  useEffect(() => {
    OrderList(page, "", "");
  }, [filterData, search, page, rowsPerPage, value, refresh]);

  const OrderList = async (page, name = "", flag) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    const tabvalue = value || localStorage.getItem("Orders_tabValue");
    let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}${string}&sortBy=${name}&sortFlag=${flag}&status=${tabvalue == 2 ? 0 : "1,3"
      }`;

    // let queryString = `?page=${page}&pagePerSize=${pagePerSize}&search=${search}${string}&sortBy=${name}&sortFlag=${flag}&status=${value == 2 ? 0 : "1,3"
    // }`;

    const res = await getOrderList(queryString);

    if (res.status === true) {
      setOrderList(res.data);
      setTotalRecords(res.totalOrder);
      setRange(res.max);
      setRanges([0, res.max]);
      setTotalPages(Math.ceil(res.totalOrder / rowsPerPage));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setOrderList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  const orderStatus = (orderstatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Confirmed",
        backgroundColor: "green",
        color: "#ede7dd",
      },
      3: {
        key: "Approved By Admin",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[orderstatus]}
        label={statusColors[orderstatus] ? statusColors[orderstatus].key : ""}
      />
    );
  };

  const dataShorting = (name) => {
    if (name === "productName") {
      setflagName(!flagName);
      OrderList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    if (name === "companyName") {
      setflagCompanyName(!flagCompanyName);
      OrderList(page, name, !flagCompanyName);
    } else {
      setflagCompanyName(false);
      setSortFlag(!sortFlag);
    }

    setFieldName(name);
  };

  const getSelectedItem = (data) => {
    if (data) {
      const selectedItem = {
        company: data?.companyName,
      };
      items.push(selectedItem);
      const neItems = [...items];
      setItems(neItems);
      setCompanyId(data?.id);
    }
  };

  //for product
  const getSelectedItems = (data) => {
    if (data) {
      const selectedItem = {
        product: data?.productName,
      };
      itemss.push(selectedItem);
      const neItems = [...itemss];
      setItemss(neItems);
      setProductId(data?.id);
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={7} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Buyer Orders
              </Typography>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "block",
                    sm: "flex-end",
                    md: "flex-end",
                    lg: "flex-end",
                  },
                  marginTop: { xs: "20px", md: "0", lg: "0" },
                }}
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      setPage(1);
                    }}
                  />
                </Box>
                <Button
                  variant="outlined"
                  startIcon={<FilterAltIcon />}
                  onClick={openFilterDrawer}
                  sx={{
                    marginLeft: "15px",
                    borderRadius: "50px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    color: "#fff",
                    ":hover": { bgcolor: "rgb(30, 41, 59)" },
                    background: "rgb(30, 41, 59)",
                    fontSize: "16px",
                    fontWeight: "500",
                    textTransform: "none",
                  }}
                >
                  Filter
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "rgb(241, 245, 249)",
            }}
          >
            <Box
              sx={{
                paddingTop: "10px",
                paddingLeft: "5px",
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                border: 1,
                borderColor: "#eaeaea",
                overflow: "scroll",
                overflowX: "hidden",
                overflowY: "hidden",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label=" Orders List"
                  value="1"
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
                <Tab
                  label="Order Requests"
                  value="2"
                  sx={{
                    color: "rgb(107, 114, 128)",
                    fontSize: "14px",
                    fontWeight: "500",
                    textTransform: "none",
                    paddingBottom: "20px",
                  }}
                />
              </TabList>
            </Box>
          </Box>
          <TabPanel value="1">
            <Box
              sx={{
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                border: 1,
                borderColor: "#eaeaea",
              }}
            >
              <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell onClick={() => dataShorting("productName")}>
                        Order Number
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() => dataShorting("productName")}
                      >
                        Product Name
                        {flagName ? (
                          <ArrowUpwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                              position: "relative",
                              top: "3px",
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                              position: "relative",
                              top: "3px",
                            }}
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() => dataShorting("companyName")}
                      >
                        Company Name
                        {flagCompanyName ? (
                          <ArrowUpwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                              position: "relative",
                              top: "3px",
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                              position: "relative",
                              top: "3px",
                            }}
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => dataShorting("quantity")}>
                        Quantity
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          variant="text"
                          sx={{
                            ":hover": { bgcolor: "transparent" },
                            paddingLeft: "0",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            minWidth: "0",
                          }}
                        >
                          Price
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          variant="text"
                          sx={{
                            ":hover": { bgcolor: "transparent" },
                            paddingLeft: "0",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "500",
                            textTransform: "capitalize",
                            minWidth: "0",
                          }}
                        >
                          Date
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          variant="text"
                          sx={{
                            ":hover": { bgcolor: "transparent" },
                            paddingLeft: "0",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "capitalize",
                            minWidth: "0",
                          }}
                        >
                          Order Status
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          variant="text"
                          sx={{
                            ":hover": { bgcolor: "transparent" },
                            paddingLeft: "0",
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "capitalize",
                            minWidth: "0",
                          }}
                        >
                          Action
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderList.map((row) => (
                      <StyledTableRow
                        key={row.Productname}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/buyerOrderDetail", {
                            state: row.id,
                          });
                        }}
                      >
                        <StyledTableCell component="th" scope="row" style={{ fontWeight: 700 }}>
                          {row.orderNumber}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.productName}
                        </StyledTableCell>
                        <StyledTableCell>{row.companyName}</StyledTableCell>
                        <StyledTableCell>{row.quantity} MT</StyledTableCell>
                        <StyledTableCell>{row.productPrice}</StyledTableCell>

                        <StyledTableCell>
                          {moment(row.createdAt).format("MM-DD-YYYY LT")}
                        </StyledTableCell>
                        <StyledTableCell>{orderStatus(row.status)}</StyledTableCell>
                        <StyledTableCell onClick={(e) => e.stopPropagation()}>
                          <Space size="middle">
                            <Tooltip placement="bottom" title="View Order">
                              <button
                                className="action-button view"
                                onClick={() => {
                                  navigate("/buyerOrderDetail", {
                                    state: row.id,
                                    // value : 
                                  });
                                }}
                              >
                                <GrView />
                              </button>
                            </Tooltip>
                            <Tooltip placement="bottom" title="Edit Order">
                              <button
                                className="action-button edit"
                                onClick={() => {
                                  navigate("/buyerOrderDetail", {
                                    state: row.id,
                                  });
                                }}
                              >
                                <RiEditLine />
                              </button>
                            </Tooltip>
                          </Space>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {orderList && orderList.length === 0 ? (
              <div className="noDataFound"> No Data Found</div>
            ) : (
              <Box
                sx={{
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: {
                    xs: "10px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                  },
                  marginRight: {
                    xs: "10px",
                    sm: "15px",
                    md: "15px",
                    lg: "15px",
                  },
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    fontSize: "14px",
                    marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
                  }}
                >
                  {" "}
                  Showing{" "}
                  {page * pagePerSize > totalRecords
                    ? totalRecords
                    : page * pagePerSize}{" "}
                  of {totalRecords} Results
                </Box>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handleChangePage}
                  variant="outlined"
                  shape="rounded"
                  sx={{
                    "& .MuiButtonBase-root ": {
                      color: "#000 !important",
                    },
                    "& .Mui-selected ": {
                      background: "#4b4b4e70 !important",
                    },
                    "& .Mui-disabled ": {
                      background: "#6c696966 !important",
                      color: "#000000 !important",
                      opacity: 1,
                    },
                  }}
                />
              </Box>
            )}
          </TabPanel>

          <TabPanel value="2">
            <Box
              sx={{
                backgroundColor: "#fff",
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                border: 1,
                borderColor: "#eaeaea",
              }}
            >
              <BuyerorderPendingList
                data={orderList}
                dataShorting={dataShorting}
                flagName={flagName}
                flagCompanyName={flagCompanyName}
                refreshScreen={refreshScreen}
              />
            </Box>
          </TabPanel>
        </TabContext>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  // marginLeft: "8px",
                  marginTop: "8px",

                  // marginRight: "8px",
                }}
              >
                <Divider sx={{ mt: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />
                //{" "}
                <StyledFormLabel htmlFor="name">Product Name</StyledFormLabel>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    disablePortal
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    labelId="category-input"
                    options={productDropdown}
                    sx={{
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
                        color: "#000",
                      },
                      width: "100%",
                      "& .MuiAutocomplete-popupIndicator": {
                        transform: "none",
                      },
                    }}
                    openOnFocus={true}
                    onChange={(event, value) => getSelectedItems(value)}
                    getOptionLabel={(option) =>
                      option ? option.productName : ""
                    }
                    renderInput={(params) => (
                      <Controls.Input fullWidth {...params} />
                    )}
                    popupIcon={<Search />}
                  />
                </Grid>
                <StyledFormLabel htmlFor="name">Company Name</StyledFormLabel>
                <Box sx={{ width: "100%" }}>
                  <Autocomplete
                    disablePortal
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    labelId="category-input"
                    options={company}
                    openOnFocus={true}
                    onChange={(event, value) => getSelectedItem(value)}
                    getOptionLabel={(option) =>
                      option ? option.companyName : ""
                    }
                    sx={{
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
                        color: "#000",
                      },
                      width: "100%",
                      "& .MuiAutocomplete-popupIndicator": {
                        transform: "none",
                      },
                    }}
                    renderInput={(params) => (
                      <Controls.Input fullWidth {...params} />
                    )}
                    popupIcon={<Search />}
                  />
                </Box>
                <StyledFormLabel htmlFor="hsnCode">Price</StyledFormLabel>
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={ranges}
                  min={0}
                  step={1}
                  max={range}
                  onChange={handleChanges}
                  valueLabelDisplay="auto"
                />
              </Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button className="order-filter-button" type="submit">
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>
      </Box>
    </>
  );
}
