import { Box } from "@mui/system";
import React from "react";
import SellerList from "../Seller/SellerList";

const Seller = () => {
  return (
    <Box>
      <SellerList />
    </Box>
  );
};

export default Seller;


