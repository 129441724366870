import React, { useState, useEffect } from "react";
import Controls from "../../Component/controls/Controls";
import {
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { useForm, Form } from "../../Utils/useForm";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Close as CloseIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import BackdropComponent from "../../Component/BackDrop/BackDrop";
import { createProduct, productRemove } from "../../state/action/productAction";
import { getCategoryDropdown } from "../../state/action/categoryAction";
import {
  productDetails,
  updateProduct,
} from "../../state/action/productAction";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSnackbar } from "notistack";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const initialValues = {
  name: "",
  margin: "",
  hsn: "",
  category: "",
  productDocument: "",
  productImage: "",
};

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

function ProductAdd() {
  const [isEdit, setIsEdit] = useState(false);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [image, setImage] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [images1, setImages1] = useState();
  const [productId, setProductId] = useState([]);
  const [productDocument, setProductDocument] = useState([]);
  const [productImage, setProductIamge] = useState([]);

  const navigate = useNavigate();
  const { state } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (state && state.product) {
      setIsEdit(true);
      getProductDetails(state.product);
    } else {
      setIsEdit(false);
      setOpenBackdrop(true);
      setTimeout(() => {
        setOpenBackdrop(false);
      }, 300);
    }
  }, [state]);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    const res = await getCategoryDropdown();
    if (res && res.status === true) {
      setCategoryList(res.data);
    } else {
      setCategoryList([]);
    }
  };

  const getProductDetails = async (id) => {
    const res = await productDetails(state?.product);
    if (res && res.status === true) {
      setValues({
        ...values,
        name: res.data.product.productName,
        margin: res.data.product.productMargin,
        hsn: res.data.product.hsn,
        category: res.data.product?.categoryId?.id,
        productDocument: images1,
      });
      setFiles(res.data.productImage);
      setImages1(res.data.productDocument[0]?.image);
      setProductId(res?.data?.product?.id);
      setProductDocument(res?.data?.productDocument[0]);
      setProductIamge(res?.data?.productImage);
    }
  };

  //for Prodcut Image
  async function handleImageItem(e) {
    const selectedFiles = e.target.files;
    const EXPECTED_SIZE = 2e6;
    const fileSize = selectedFiles[0].size;
    if (fileSize <= EXPECTED_SIZE) {

      var reader = new FileReader();
      reader.readAsDataURL(selectedFiles[0]);
      reader.onload = async function () {
        const base64String = reader.result;

        var localFiles = [...Array.from(selectedFiles)];
        setImages((preval) => {
          return [...preval, ...localFiles];
        });
        let newImagearr = localFiles.map((file) => ({
          file: file,
          image: base64String,
        }));
        setFiles([...files, ...newImagearr]);
      };
    } else {
    }
  }

  const handleImageItem1 = async (e) => {
    const selected = e.target.files[0];
    const ALLOWED_TYPES = ["application/pdf"];

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      setImages1(selected.name);
      setValues({ ...values, productDocument: selected });
    } else {
      setImages1("");
      setValues({ ...values, productDocument: "" });
    }
  };
  async function deleteImage1(index, id) {
    setImages1("");
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["images1"];
      return newValues;
    });

    const productDocumentPayload = {
      productId: productId,
      imageType: 2,
      imageId: productDocument?.id,
    }

    if (isEdit && productDocument && productDocument?.id) {
      const res = await productRemove(productDocumentPayload);

      if (res && res.status === true) {
        enqueueSnackbar(res.message, {
          variant: "success",
          autoHideDuration: 2000,
        });
        setProductDocument([])
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
    else {
      setProductDocument([])
      enqueueSnackbar("Product Document successfully removed", {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
  }


  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("margin" in fieldValues) {
      let reg = /^[+-]?([0-9]*[.])?[0-9]+$/;
      if (fieldValues.margin === "") {
        temp.margin = "This field is required.";
      } else if (!reg.test(fieldValues.margin)) {
        temp.margin = "Please enter valid margin.";
      } else {
        temp.margin = "";
      }
    }
    if ("category" in fieldValues)
      temp.category = fieldValues.category ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const formData = new FormData();
      formData.append("productName", values.name);
      formData.append("price", values.margin);
      formData.append("hsn", values.hsn);
      formData.append("categoryId", values.category);
      formData.append("productDocument", values.productDocument);
      formData.append("productImage", values.productImage);

      if (images.length > 0) {
        images.forEach((val, key) => {
          formData.append("productImage", val);
        });
      }

      if (isEdit) {
        const res = await updateProduct(state.product, formData);
        if (res && res.status === true) {
          navigate("/product");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        const res = await createProduct(formData);
        if (res && res.status === true) {
          navigate("/product");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRemoveDocument = async (index, id) => {

    if (isEdit && id) {
      const payload = {
        productId: productId,
        imageType: 1,
        imageId: id,
      };

      const newFiles = files.filter((file, i) => i !== index);
      setFiles(newFiles);

      const res = await productRemove(payload);

      if (res && res.status === true) {
        enqueueSnackbar(res.message || "Image removed successfully", {
          variant: "success",
          autoHideDuration: 2000,
        });
      } else {
        enqueueSnackbar(res.message || "Something went wrong", {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    } else {
      const newFiles = files.filter((file, i) => i !== index);
      setFiles(newFiles);
      enqueueSnackbar("Image removed successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
  };

  return (
    <>
      <BackdropComponent open={openBackdrop} />

      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Typography
          sx={{
            paddingTop: "25px",
            paddingBottom: "15px",
            fontSize: "15px",
            fontWeight: "600",
            color: "#000",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/product");
          }}
        >
          <KeyboardBackspaceIcon
            sx={{ verticalAlign: "top", marginRight: "5px" }}
          />
          Product
        </Typography>
        <Grid container alignItems="top" style={{ marginBottom: "25px" }}>
          <Grid item sm={7} xs={12}>
            <Box display="flex">
              <Box>
                <img
                  src={"../../assets/images/product-image.png"}
                  style={{ borderRadius: "5px" }}
                />
              </Box>
              <Box sx={{ marginLeft: "10px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                >
                  {isEdit ? "Edit Product" : "Add Product"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    marginTop: "3px",
                  }}
                >
                  Product
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item sm={5} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Controls.Button
                // startIcon={<DeleteIcon />}
                text="Cancel"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#fff",
                  backgroundColor: "red",
                  ":hover": { bgcolor: "red" },
                  textTransform: "none",
                  borderRadius: "50px",
                  boxShadow: 0,
                }}
                onClick={() => {
                  navigate("/product");
                }}
              />
              <Controls.Button
                text="Save"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#fff",
                  backgroundColor: "rgb(79, 70, 229)",
                  ":hover": { bgcolor: "rgb(79, 70, 229)" },
                  marginLeft: "10px",
                  textTransform: "none",
                  borderRadius: "50px",
                  boxShadow: 0,
                }}
                variant="outlined"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderTopRightRadius: "20px",
            borderTopLeftRadius: "20px",
            border: 1,
            borderColor: "#eaeaea",
            paddingLeft: "0px",
            paddingTop: "10px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "rgba(107, 114, 128, .4)",
                  marginBottom: "0px",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Basic Info"
                    value="1"
                    sx={{
                      color: "rgb(107, 114, 128)",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "none",
                      paddingBottom: "20px",
                    }}
                  />
                  <Tab
                    label="Upload Img"
                    value="2"
                    sx={{
                      color: "rgb(107, 114, 128)",
                      fontSize: "14px",
                      fontWeight: "500",
                      textTransform: "none",
                      paddingBottom: "20px",
                    }}
                  />
                </TabList>
              </Box>

              <TabPanel value="1">
                <Box
                  sx={{
                    width: {
                      md: "100%",
                      lg: "650px",
                    },
                  }}
                >
                  <Form onSubmit={handleSubmit}>
                    <Controls.Input
                      name="name"
                      onChange={handleInputChange}
                      value={values.name}
                      error={errors.name}
                      label="Product Name"
                      inputProps={{ maxLength: 100 }}
                      maxLength={200}
                    />{" "}
                    <Box sx={{ marginTop: "22px" }}>
                      <Controls.SelectBox
                        name="category"
                        onChange={handleInputChange}
                        value={values.category}
                        error={errors.category}
                        label="Category"
                        options={categoryList}
                      />
                      {Boolean(errors.category) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            marginTop: "4px",
                            marginBottom: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {errors.category}
                        </p>
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box sx={{ marginTop: "15px" }}>
                      <Controls.Input
                        name="margin"
                        onChange={handleInputChange}
                        value={values.margin}
                        error={errors.margin}
                        label="Margin"
                        className="seq-input"
                        inputProps={{ maxLength: 10 }}
                      />
                    </Box>
                    <Box sx={{ marginTop: "15px" }}>
                      <Controls.Input
                        name="hsn"
                        onChange={handleInputChange}
                        value={values.hsn}
                        error={errors.hsn}
                        label="HSN/SAC"
                        className="seq-input"
                        inputProps={{ maxLength: 8 }}
                      />
                    </Box>
                  </Form>
                </Box>
              </TabPanel>

              <TabPanel value="2">
                <Box display="flex">
                  <Box>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        ":hover": { bgcolor: "transparent" },
                        textTransform: "none",
                        fontSize: "16px",
                        backgroundColor: "#f6f9fb",
                        borderRadius: "15px",
                        padding: "40px",
                      }}
                    >
                      <DriveFolderUploadIcon
                        sx={{
                          fontSize: "40px",
                          color: "rgb(107, 114, 128)",
                          display: "flex",
                        }}
                      />
                      <input type="file" hidden onChange={handleImageItem} />
                    </Button>
                    <Typography
                      sx={{ fontSize: "13px", color: "#000", marginTop: "7px" }}
                    >
                      Product Image
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: "end" }}>
                    {files.map((file, i) => {
                      return (
                        <StyledIconWrapper
                          sx={{
                            position: "relative",
                            margin: "25px 5px 5px 5px",
                            width: "147px",
                            height: "140px",
                            backgroundImage: `url(${file.image})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            marginTop: "-10px",
                            marginLeft: "27px",
                          }}
                          key={i + 1}
                          onClick={() => {
                            // setIconFlag(id);
                          }}
                        >
                          <IconButton
                            size="large"
                            sx={{
                              position: "absolute",
                              right: "2%",
                              top: "3%",
                              width: "36px",
                              height: "36px",
                              background: "rgba(15, 15, 15, 0.5)",
                              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                              borderRadius: "10px",

                              color: "white",
                              "&:hover": {
                                backgroundColor: "#0957DD",
                              },
                            }}
                          >
                            <CloseIcon
                              sx={{ width: "20px", height: "20px" }}
                              onClick={() => {
                                handleRemoveDocument(i, file.id);
                              }}
                            />
                          </IconButton>
                        </StyledIconWrapper>
                      );
                    })}
                  </Box>
                </Box>

                <Box display="flex">
                  <Box>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        ":hover": { bgcolor: "transparent" },
                        textTransform: "none",
                        fontSize: "16px",
                        backgroundColor: "#f6f9fb",
                        borderRadius: "15px",
                        padding: "40px",
                      }}
                    >
                      <DriveFolderUploadIcon
                        sx={{
                          fontSize: "40px",
                          color: "rgb(107, 114, 128)",
                          display: "flex",
                        }}
                      />
                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          handleImageItem1(e);
                        }}
                        accept="application/pdf"
                      />
                    </Button>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "#000",
                        marginTop: "7px",
                      }}
                    >
                      Data Sheet
                    </Typography>
                  </Box>

                  <Box sx={{ textAlign: "end" }}>
                    {images1 && images1 !== "" && (
                      <StyledIconWrapper
                        style={{}}
                        sx={{
                          position: "relative",
                          margin: "25px 5px 5px 5px",
                          width: "147px",
                          height: "140px",
                          background: "none !important",
                          border: "none",
                        }}
                        onClick={() => {
                          // setIconFlag(id);
                        }}
                      >
                        <StyledIconWrapper
                          style={{
                            margin: "0",
                            display: "flex",
                            justifyContent: "center",
                            background: "none",
                            border: "none",
                            width: "auto",
                            height: "50px",
                          }}
                        >
                          {/* <img style={{ marginTop: "13px" }} src={PictureAsPdfIcon} alt="Document" /> */}
                          <PictureAsPdfIcon
                            style={{
                              width: "40px",
                              height: "40px",
                              color: "rgb(118 116 107)",
                            }}
                          />
                        </StyledIconWrapper>

                        <p style={{ fontSize: "12px", textAlign: "center" }}>
                          {" "}
                          {images1 !== "" && images1}
                        </p>
                        <IconButton
                          size="large"
                          sx={{
                            position: "absolute",
                            right: "2%",
                            top: "3%",
                            width: "20px",
                            height: "20px",
                            background: "rgba(15, 15, 15, 0.5)",
                            boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                            borderRadius: "10px",

                            color: "white",
                            "&:hover": {
                              backgroundColor: "#FFC400",
                            },
                          }}
                        >
                          <CloseIcon
                            sx={{ width: "20px", height: "20px" }}
                            onClick={() => {
                              deleteImage1();
                            }}
                          />
                        </IconButton>
                      </StyledIconWrapper>
                    )}
                  </Box>
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ProductAdd;
