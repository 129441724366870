import axiosRequest from "../axios/axios";


export const termsUpdate = async (id, data) => {
    try {
        const response = await axiosRequest.put(`/udaptetc/${id}`, data);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const termsDetails = async (id) => {
    try {
        const response = await axiosRequest.get(`/getDetail/${id}`);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}


