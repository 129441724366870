import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Modal,
  styled,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Controls from "../../Component/controls/Controls";
import { useLocation, useNavigate } from "react-router";
import {
  orderDetails,
  OrderBuyerPrice,
  OrderStatusChange,
  remarkChange,
  addPaymentTerms,
  orderAddressChange,
} from "../../state/action/orderAction";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "notistack";
import { useForm } from "../../Utils/useForm";
import { Spin } from "antd";


const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));
const CssTextField = styled(TextField)(() => ({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "yellow",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
}));



const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const initialValues = {
  remarks: "",
  // ShippingAddress: "",
  shippingline1: "",
  shippingline2: "",
  shippingline3: "",
  shippingcity: "",
  shippingstate: "",
  shippingpincode: ""
};

function CategoryModule() {
  const [value, setValue] = useState("1");
  const [orderDetail, setOrderDetail] = useState([]);
  const [sellerOrderDetail, setSellerOrderDetail] = useState([]);
  const [productData, setProductdata] = useState([]);
  const [ordersData, setOrdersdata] = useState([]);
  const [sellercomapny, setSellerComapny] = useState([]);
  const [buyercomapny, setBuyerComapny] = useState([]);
  const [isStatusEditing, setStatusEditing] = useState(false);
  const [isQtyEditing, setQtyEditing] = useState(false);
  const [statusMenu, setStatusMenu] = useState(true);
  const [qtyMenu, setQtyMenu] = useState(true);
  const [save, setSave] = useState(false);
  const [saves, setSaves] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [ShippingAddresssOrderId, setShippingAddresssOrderId] = useState();
  const [remarkEditOrderId, setRemarkEditOrderId] = useState();
  const [loading, setLoading] = useState(false);
  const [openremarkPopup, setOpenRemarkPopup] = useState(false);
  const [openShippingPopup, setOpenShippingPopup] = useState(false);
  const [openPaymentTermPopup, setOpenPaymentTermPopup] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("shippingpincode" in fieldValues) {
      //only numbers allow regex
      var regex = /^[0-9]+$/;
      if (!regex.test(fieldValues.shippingpincode)) {
        temp.shippingpincode = " Only numbers are allowed";
      } else {
        temp.shippingpincode = "";
      }
    }


    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handelRemarksChange = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      const res = await remarkChange(ShippingAddresssOrderId, {
        remarks: values.remarks,
      });
      if (res) {
        setOpenRemarkPopup(false);
        setLoading(false);
        getDetails(ShippingAddresssOrderId)
        enqueueSnackbar("Remarks updated successfully", {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
      else {
        setLoading(false);
        setOpenRemarkPopup(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 2000,
        });
      }

    }
    catch (error) {
      setLoading(false);
    }
  };

  const handelAddressChange = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      // if (validate()) {
      const res = await orderAddressChange(ShippingAddresssOrderId, {
        shippingline1: values.shippingline1,
        shippingline2: values.shippingline2,
        shippingline3: values.shippingline3,
        shippingcity: values.shippingcity,
        shippingstate: values.shippingstate,
        shippingpincode: values.shippingpincode
      });
      if (res) {
        setOpenShippingPopup(false);
        setLoading(false);
        getDetails(ShippingAddresssOrderId)
        enqueueSnackbar("Shipping address updated successfully", {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
      else {
        setLoading(false);
        setOpenRemarkPopup(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
    // }
    catch (error) {
      setLoading(false);
    }
  }

  const handelPaymentTermsChange = async (e) => {
    // Update payment terms

    const payload = {
      paymentTerms: values.paymentTerms,
    };
    try {
      setLoading(true);
      e.preventDefault();
      const res = await addPaymentTerms(id, payload);
      if (res) {
        setOpenPaymentTermPopup(false);
        setLoading(false);
        getDetails(id)
        enqueueSnackbar("Payment terms updated successfully", {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
      else {
        setLoading(false);
        setOpenRemarkPopup(false);
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          autoHideDuration: 2000,
        });
      }

    }
    catch (error) {
      setLoading(false);
    }
  }

  const openStatusMenu = () => {
    setStatusMenu(true);
  };
  const openQtyMenu = () => {
    setQtyMenu(true);
  };
  const closeStatusMenu = () => {
    setStatusMenu(false);
  };
  const closeQtyMenu = () => {
    setQtyMenu(false);
  };

  const startStatusEditing = (id) => {
    setStatusEditing(id);
  };

  const startQtyEditing = (id) => {
    setQtyEditing(id);
  };

  const startStatusUpdate = (e, id) => {
    setOrdersdata({
      ...ordersData,
      productPrice: e.target.value,
    });
    setSave(true);
  };

  const startQtyUpdate = (e, id) => {
    setOrdersdata({
      ...ordersData,
      quantity: e.target.value,
    });
    setSave(true);
  };

  const stopStatusEditing = (id) => {
    setStatusEditing(id);
  };
  const stopQtyEditing = (id) => {
    setQtyEditing(id);
  };

  const openRemarkBox = () => {
    setOpenRemarkPopup(true);
  };
  const closeRemarkBox = () => {
    setOpenRemarkPopup(false);
  };

  const openShippingkBox = () => {
    setOpenShippingPopup(true);
  };
  const closeShippingBox = () => {
    setOpenShippingPopup(false);
  };

  const openPaymentRemarkkBox = () => {
    setOpenPaymentTermPopup(true);
  };
  const closePaymentRemarkBox = () => {
    setOpenPaymentTermPopup(false);
  };


  const statusList = [
    { id: 1, title: "Confirmed" },
    { id: 3, title: "Approved By Admin" },
  ];

  const navigate = useNavigate();

  const { state } = useLocation();

  const id = state;

  const getDetails = async (id) => {
    const res = await orderDetails(id);
    if (res && res.status) {
      setOrderDetail(res?.data?.userData);
      setSellerOrderDetail(res?.data?.sellerData);
      setProductdata(res?.data?.productData);
      setOrdersdata(res?.data?.orderData);
      setSellerComapny(res?.data?.sellerCompanyDatas);
      setBuyerComapny(res?.data?.usercompanyData);
      setValues({
        ...values,
        ShippingAddress: res?.data?.orderData?.deliveryAddress,
        remarks: res?.data?.orderData?.remarks,
        paymentTerms: res?.data?.orderData?.paymentTerms,
        shippingline1: res?.data?.orderData?.shippingline1,
        shippingline2: res?.data?.orderData?.shippingline2,
        shippingline3: res?.data?.orderData?.shippingline3,
        shippingcity: res?.data?.orderData?.shippingcity,
        shippingstate: res?.data?.orderData?.shippingstate,
        shippingpincode: res?.data?.orderData?.shippingpincode,
      });
    }
  };



  useEffect(() => {
    getDetails(id);
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      price: ordersData?.productPrice,
      quantity: ordersData?.quantity
    };

    const res = await OrderBuyerPrice(id, payload);

    if (res && res.status === true) {
      // navigate("/buyerOrder");
      getDetails(id)
      setRefresh(!refresh);
      setSave(false)
      enqueueSnackbar("Order has been update successfully", {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);
      enqueueSnackbar("Failed to update order ", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };
  const orderStatus = (orderstatus) => {
    const statusColors = {
      0: {
        key: "Pending",
        backgroundColor: "#ede7dd",
        color: "#fc7e00",
      },
      1: {
        key: "Confirm Order",
        backgroundColor: "green",
        color: "white",
      },
    };

    return (
      <StyledChip
        style={{
          height: "30px",
          fontSize: "15px",
        }}
        sx={statusColors[orderstatus]}
        label={statusColors[orderstatus] ? statusColors[orderstatus].key : ""}
      />
    );
  };

  const handleApproveStatus = async (e) => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      status: e.target.value,
    };

    const res = await OrderStatusChange(id, payload);
    if (res && res.status == true) {
      getDetails(id);

      // setOrdersdata({
      //   ...ordersData,
      //   status: e.target.value,
      // });
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else {
      setOpenBreakdrop(!openBackdrop);
      enqueueSnackbar(res.response.data.message, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handlePaymentTermsStatus = async (e) => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      paymentTerms: values.paymentTerms,
    };
    const res = await addPaymentTerms(id, payload);

    if (res && res.status === true) {
      enqueueSnackbar(res.message, {
        variant: "success",
        autoHideDuration: 2000,
      });
      setOpenBreakdrop(!openBackdrop);
      window.scrollTo(0, 1000);

    } else {
      setOpenBreakdrop(!openBackdrop);
    }
  };


  return (
    <>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Typography
          sx={{
            paddingTop: "25px",
            paddingBottom: "15px",
            fontSize: "15px",
            fontWeight: "600",
            color: "#000",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/buyerOrder");
          }}
        >
          <KeyboardBackspaceIcon
            sx={{ verticalAlign: "top", marginRight: "5px" }}
          />
          Order
        </Typography>
        <Grid container alignItems="top" style={{ marginBottom: "20px" }}>
          <Grid item sm={7} xs={12}>
            <Box display="flex">
              <Box>
                <img
                  src={"../../assets/images/product-image.png"}
                  style={{ borderRadius: "5px" }}
                />
              </Box>
              <Box sx={{ marginLeft: "10px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                >
                  Order Detail
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    marginTop: "3px",
                  }}
                >
                  Detail
                </Typography>
                {save === true ? (
                  <Controls.Button
                    text="Save"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                      backgroundColor: "rgb(79, 70, 229)",
                      marginLeft: "800px",
                      textTransform: "none",
                    }}
                    onClick={handleSubmit}
                    variant="outlined"
                  />
                ) : null}

                {saves === true ? (
                  <Controls.Button
                    text="Save"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                      backgroundColor: "rgb(79, 70, 229)",
                      marginLeft: "800px",
                      textTransform: "none",
                    }}
                    onClick={handlePaymentTermsStatus}
                    variant="outlined"
                  />
                ) : null}
              </Box>
            </Box>
          </Grid>
          {ordersData?.status === 1 ? (
            <Grid item sm={5} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Controls.Button
                  text="Invoice Generate"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    backgroundColor: "rgb(79, 70, 229)",
                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                    textTransform: "none",
                    borderRadius: "50px",
                    boxShadow: 0,
                    display: "grid",
                    marginBottom: "17px",
                  }}
                  onClick={() => {
                    navigate("/BuyerInvoice", {
                      state: id,
                    });
                  }}
                />
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: "rgb(241, 245, 249)",
          overflow: "scroll",
          overflowX: "hidden",
          border: "1px solid #ccc",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderTopRightRadius: "25px",
            borderTopLeftRadius: "25px",
            border: 0,
            borderColor: "#eaeaea",
            paddingLeft: "0px",
            paddingTop: "10px",
          }}
        >
          <Box
            sx={{
              marginTop: "15px",
              marginLeft: { xs: "15px", md: "25px", lg: "25px" },
            }}
          >
            <Box
              sx={{
                maxWidth: {
                  md: "600px",
                  lg: "1200px",
                },
              }}
            >
              <Typography
                sx={{
                  marginBottom: "20px",
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                Order Number: {ordersData?.orderNumber}
              </Typography>
              <Grid container spacing={8} style={{ marginBottom: "30px" }}>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      marginBottom: "20px",
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Buyer Detail :
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Buyer Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {orderDetail?.userName}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Company Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {buyercomapny?.companyName}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Pan Number:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {buyercomapny?.panNumber}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Gst Number :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {buyercomapny?.gstNumber}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Email :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {orderDetail?.email}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Phone Number :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {orderDetail?.mobile}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Ship to Address :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {buyercomapny?.address}
                      </Typography>
                    </Grid>


                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Order for :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {ordersData?.stateName}, {ordersData?.cityName}
                      </Typography>
                    </Grid>


                  </Grid>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Typography
                    sx={{
                      marginBottom: "20px",
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Seller Detail :
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Seller Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {sellerOrderDetail?.userName}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Company Name :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {sellercomapny?.companyName}
                      </Typography>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Pan Number:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {sellercomapny?.panNumber}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Gst Number :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {sellercomapny?.gstNumber}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Email :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {sellerOrderDetail?.email}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Phone Number :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {sellerOrderDetail?.mobile}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        Bill to Address :
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        {sellercomapny?.address}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                sx={{
                  borderTop: 0,
                  borderColor: "#eaeaea",
                  paddingTop: "10px",
                }}
              ></Divider>
              <Box
                gap={7}
                sx={{
                  marginBottom: "30px",
                  marginTop: "30px",
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  marginRight: "10px",
                }}
              >
                <Box
                  sx={{
                    maxWidth: {
                      md: "300px",
                      lg: "300px",
                    },
                    marginBottom: { xs: "15px", md: "0", lg: "0" },
                  }}
                >
                  <Typography
                    sx={{
                      paddingBottom: "3px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Shipping Address :
                    {openShippingPopup ?
                      null : (<Button
                        onClick={() => {
                          setShippingAddresssOrderId(ordersData.id);
                          openShippingkBox();
                        }}
                        size="large"
                        endIcon={<EditIcon />}
                      >
                        {/* {ordersData?.remarks} */}
                      </Button>)
                    }


                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    {ordersData?.shippingline1}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    {ordersData?.shippingline2}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    {ordersData?.shippingline3}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    {ordersData?.shippingcity}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    {ordersData?.shippingstate}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    {ordersData?.shippingpincode}
                  </Typography>
                </Box>

                <Box sx={{ marginBottom: { xs: "15px", md: "0", lg: "0" } }}>
                  <Typography
                    sx={{
                      paddingBottom: "3px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                      marginBottom: { xs: "5px", md: "10px", lg: "10px" },
                    }}
                  >
                    Order Status :
                  </Typography>

                  {ordersData?.status === 0 || ordersData?.status === 1 ? (
                    <Typography>{orderStatus(ordersData?.status)}</Typography>
                  ) : (
                    <Controls.SelectBox
                      text="Add New"
                      className="input"
                      fullWidth
                      id="status"
                      name="status"
                      value={Number(ordersData?.status)}
                      onChange={(e) => handleApproveStatus(e)}
                      color="primary"
                      options={statusList}
                      label="Status"
                    />
                  )}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      paddingBottom: "3px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Date :
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    {moment(ordersData.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </Box>
                <Box>
                  <Box sx={{ marginTop: "15px" }}>
                    <Typography
                      sx={{
                        paddingBottom: "3px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#000",
                        marginTop: "-19px",
                      }}
                    >
                      Payment Terms :

                      {openPaymentTermPopup ?
                        null : (<Button
                          onClick={() => {
                            setRemarkEditOrderId(ordersData.id);
                            openPaymentRemarkkBox();
                          }}
                          size="large"
                          endIcon={<EditIcon />}
                        >
                          {/* {ordersData?.remarks} */}
                        </Button>)
                      }
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000c7",
                      }}
                    >
                      {ordersData?.paymentTerms}
                    </Typography>

                    {/* {isPaymentEditing ? (
                      <Box sx={{ marginTop: "15px" }}>
                        <Controls.Input
                          sx={{
                            input: { color: "black" },
                            fieldset: { borderColor: "black" },
                          }}
                          size="small"
                          variant="outlined"
                          id="paymentTerms"
                          name="paymentTerms"
                          value={ordersData?.paymentTerms}
                          open={statusMenus}
                          onOpen={openStatusMenus}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              stopStatusEditingss();
                              closeStatusMenus();
                            }
                          }}
                          onChange={(event) => {
                            startStatusUpdatess(event, ordersData);
                            handleInputChange(event);
                          }}
                          onBlur={() => stopStatusEditingss()}
                        >

                        </Controls.Input>
                      </Box>
                    ) : (
                      <Button
                        onClick={() => {
                          startStatusEditingss(ordersData.id);
                          openStatusMenus();
                        }}
                        size="large"
                        endIcon={<EditIcon />}
                      >
                        {ordersData?.paymentTerms}
                      </Button>
                    )} */}
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      paddingBottom: "3px",
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#000",
                    }}
                  >
                    Remarks :
                    {openremarkPopup ?
                      null : (<Button
                        onClick={() => {
                          setShippingAddresssOrderId(ordersData.id);
                          openRemarkBox();
                        }}
                        size="large"
                        endIcon={<EditIcon />}
                      >
                        {/* {ordersData?.remarks} */}
                      </Button>)
                    }


                  </Typography>


                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000c7",
                    }}
                  >
                    {ordersData?.remarks}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                maxWidth: {
                  lg: "1250px",
                },
              }}
            >
              <Box
                sx={{
                  marginTop: "40px",
                  marginBottom: "20px",
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",
                  border: 1,
                  borderColor: "#eaeaea",
                  marginRight: "20px",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: 0, paddingLeft: "0!important" }}
                >
                  <Table
                    aria-label="simple table"
                    sx={{ paddingLeft: "0!important" }}
                  >
                    <TableHead sx={{ paddingLeft: "0!important" }}>
                      <TableRow
                        sx={{
                          backgroundColor: "#f6f9fb",
                          paddingLeft: "0!important",
                        }}
                      >
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Sr.No
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Name
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Pacakage Type
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Quantity
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Margin
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            borderColor: "rgba(107, 114, 128, .4)",
                          }}
                        >
                          Price(per Kg)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        backgroundColor: "#fff",
                        paddingLeft: "0!important",
                      }}
                    >
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          1
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {productData?.productName}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {ordersData?.packageType}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {/* {ordersData?.quantity} MT */}

                          {isQtyEditing ? (
                            <Box sx={{ marginTop: "15px" }}>
                              <CssTextField
                                sx={{
                                  input: { color: "black" },
                                  fieldset: { borderColor: "black" },
                                }}
                                size="small"
                                variant="outlined"
                                id="quantity"
                                name="quantity"
                                value={ordersData?.quantity}
                                open={qtyMenu}
                                onOpen={openQtyMenu}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    stopQtyEditing();
                                    closeQtyMenu();
                                  }
                                }}
                                onChange={(event) =>
                                  startQtyUpdate(event, ordersData)
                                }
                                onBlur={() => stopQtyEditing()}
                              >
                                {ordersData?.quantity}
                              </CssTextField>
                            </Box>
                          ) : (
                            <Button
                              onClick={() => {
                                startQtyEditing(ordersData.id);
                                openQtyMenu();
                              }}
                              size="large"
                              endIcon={<EditIcon />}
                            >
                              {ordersData?.quantity
                                ? ordersData?.quantity
                                : 0.0} MT
                            </Button>
                          )}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {productData?.productMargin}
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontWeight: "400",
                            textTransform: "none",
                          }}
                        >
                          {isStatusEditing ? (
                            <Box sx={{ marginTop: "15px" }}>
                              <CssTextField
                                sx={{
                                  input: { color: "black" },
                                  fieldset: { borderColor: "black" },
                                }}
                                size="small"
                                variant="outlined"
                                id="productPrice"
                                name="productPrice"
                                value={ordersData?.productPrice}
                                open={statusMenu}
                                onOpen={openStatusMenu}
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") {
                                    stopStatusEditing();
                                    closeStatusMenu();
                                  }
                                }}
                                onChange={(event) =>
                                  startStatusUpdate(event, ordersData)
                                }
                                onBlur={() => stopStatusEditing()}
                              >
                                {ordersData?.productPrice}
                              </CssTextField>
                            </Box>
                          ) : (
                            <Button
                              onClick={() => {
                                startStatusEditing(ordersData.id);
                                openStatusMenu();
                              }}
                              size="large"
                              endIcon={<EditIcon />}
                            >
                              {ordersData?.productPrice
                                ? ordersData?.productPrice
                                : 0.0}
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box
                sx={{
                  marginRight: "20px",
                  marginBottom: "20px",
                }}
              >
                <Box
                  sx={{
                    marginLeft: "auto",
                    maxWidth: "250px",
                  }}
                >
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      sx={{
                        paddingBottom: "3px",
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "#000",
                      }}
                    >
                      Amount
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000c7",
                      }}
                    >
                      &#8377; {ordersData?.amount}
                    </Typography>
                  </Box>

                  {ordersData?.IGST != 0 ? (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        IGST
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        &#8377; {ordersData?.IGST}
                      </Typography>
                    </Box>
                  ) : null}
                  {ordersData?.CGST != 0 ? (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        CGST
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        &#8377; {ordersData?.CGST}
                      </Typography>
                    </Box>
                  ) : null}
                  {ordersData?.SGST != 0 ? (
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography
                        sx={{
                          paddingBottom: "3px",
                          fontSize: "15px",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        SGST
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#000000c7",
                        }}
                      >
                        &#8377; {ordersData?.SGST}
                      </Typography>
                    </Box>
                  ) : null}
                  <Box display={"flex"} justifyContent={"space-between"}>
                    {ordersData?.tcs != "0" ? (
                      <>
                        <Typography
                          sx={{
                            paddingBottom: "3px",
                            fontSize: "15px",
                            fontWeight: "500",
                            color: "#000",
                          }}
                        >
                          TCS
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#000000c7",
                          }}
                        >
                          + {ordersData?.tcs}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            paddingBottom: "3px",
                            fontSize: "15px",
                            fontWeight: "500",
                            color: "#000",
                          }}
                        >
                          TDS
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#000000c7",
                          }}
                        >
                          - {ordersData?.tds}
                        </Typography>
                      </>
                    )}
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    sx={{
                      borderTop: 1,
                      borderColor: "#eaeaea",
                      paddingLeft: "2px",
                      paddingTop: "2px",
                      marginTop: "6px",
                    }}
                  >
                    <Typography
                      sx={{
                        paddingBottom: "3px",
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "#000",
                        marginTop: "7px",
                      }}
                    >
                      Total
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000000c7",
                        marginTop: "7px",
                      }}
                    >
                      &#8377; {ordersData?.total}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>


      {openremarkPopup && loading == false && (

        <Modal
          open={openremarkPopup}
          onClose={closeRemarkBox}
        >

          {/* //create design for address edit  */}
          <Box sx={styles}>
            <Spin spinning={loading} size="large" >

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#000",
                  }}
                >
                  Remarks
                </Typography>
                <IconButton onClick={closeRemarkBox}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Grid container style={{ marginTop: "1rem" }}>
                <Grid item xs={12} className="text-end">
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Remarks"
                    name="remarks"
                    value={values.remarks}
                    onChange={handleInputChange}
                    error={errors.remarks}
                    helperText={errors.remarks}
                    fullWidth
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  />
                </Grid>

                <Grid item xs={9} className="text-end">
                  <Controls.Button
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      marginTop: "20px",
                    }}
                    text="Cancel"
                    onClick={closeRemarkBox}
                  />
                </Grid>
                <Grid item xs={3} className="text-end">
                  <Controls.Button
                    style={{ marginRight: "0px", marginTop: "20px" }}
                    text="Submit"
                    onClick={(e) => handelRemarksChange(e)}
                    type="submit"
                  />
                </Grid>
              </Grid>
            </Spin>

          </Box>

        </Modal>
      )}

      {openShippingPopup && loading == false && (

        <Modal
          open={openShippingPopup}
          onClose={closeShippingBox}
        >

          {/* //create design for address edit  */}
          <Box sx={styles}>
            <Spin spinning={loading} size="large" >

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#000",
                  }}
                >
                  Shipping Address
                </Typography>
                <IconButton onClick={closeShippingBox}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Grid container style={{ marginTop: "1rem" }}>
                <Grid item xs={12} className="text-end">
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Address Line 1"
                    name="shippingline1"
                    value={values.shippingline1}
                    onChange={handleInputChange}
                    error={errors.shippingline1}
                    helperText={errors.shippingline1}
                    fullWidth
                  />
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Address Line 2"
                    name="shippingline2"
                    value={values.shippingline2}
                    onChange={handleInputChange}
                    error={errors.shippingline2}
                    helperText={errors.shippingline2}
                    fullWidth
                  />
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Address Line 3"
                    name="shippingline3"
                    value={values.shippingline3}
                    onChange={handleInputChange}
                    error={errors.shippingline3}
                    helperText={errors.shippingline3}
                    fullWidth
                  />
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="City"
                    name="shippingcity"
                    value={values.shippingcity}
                    onChange={handleInputChange}
                    error={errors.shippingcity}
                    helperText={errors.shippingcity}
                    fullWidth
                  />
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="State"
                    name="shippingstate"
                    value={values.shippingstate}
                    onChange={handleInputChange}
                    error={errors.shippingstate}
                    helperText={errors.shippingstate}
                    fullWidth
                  />

                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Pin Code"
                    name="shippingpincode"
                    value={values.shippingpincode}
                    onChange={handleInputChange}
                    error={errors.shippingpincode}
                    helperText={errors.shippingpincode}
                    fullWidth
                    inputProps={{ maxLength: 6 }}
                  />

                </Grid>

                <Grid item xs={9} className="text-end">
                  <Controls.Button
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      marginTop: "20px",
                    }}
                    text="Cancel"
                    onClick={closeShippingBox}
                  />
                </Grid>
                <Grid item xs={3} className="text-end">
                  <Controls.Button
                    style={{ marginRight: "0px", marginTop: "20px" }}
                    text="Submit"
                    onClick={(e) => handelAddressChange(e)}
                    type="submit"
                  />
                </Grid>
              </Grid>
            </Spin>

          </Box>

        </Modal>
      )}

      {openPaymentTermPopup && loading == false && (

        <Modal
          open={openPaymentTermPopup}
          onClose={closePaymentRemarkBox}
        >

          {/* //create design for address edit  */}
          <Box sx={styles}>
            <Spin spinning={loading} size="large" >

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#000",
                  }}
                >
                  Payment Terms
                </Typography>
                <IconButton onClick={closePaymentRemarkBox}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Grid container style={{ marginTop: "1rem" }}>
                <Grid item xs={12} className="text-end">
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="payment Terms"
                    name="paymentTerms"
                    value={values?.paymentTerms}
                    onChange={handleInputChange}
                    error={errors.ShippingAddress}
                    helperText={errors.ShippingAddress}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={9} className="text-end">
                  <Controls.Button
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      marginTop: "20px",
                    }}
                    text="Cancel"
                    onClick={closePaymentRemarkBox}
                  />
                </Grid>
                <Grid item xs={3} className="text-end">
                  <Controls.Button
                    style={{ marginRight: "0px", marginTop: "20px" }}
                    text="Submit"
                    onClick={(e) => handelPaymentTermsChange(e)}
                    type="submit"
                  />
                </Grid>
              </Grid>
            </Spin>

          </Box>

        </Modal>
      )}



    </>
  );
}

export default CategoryModule;
