import axiosRequest from "../axios/axios";

export const UserList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/userList${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const incompletedList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/incompleted${queryString}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteIncomplateUser = async (id) => {
  try {
    const response = await axiosRequest.delete(`/incompleteddelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};



export const getUser = async (id) => {
  try {
    const response = await axiosRequest.get(`/user/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const createUser = async (user) => {
  try {
    const response = await axiosRequest.post(`/users`, user);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateUser = async (id, user) => {
  try {
    const response = await axiosRequest.patch(`/buyerUpdate/${id}`, user);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axiosRequest.delete(`/user/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//userStatus change in buyer
export const buyerStatusChange = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/userStatusChange/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Add Buyer
export const AddUser = async (user) => {
  try {
    const response = await axiosRequest.post(`/addUser`, user);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//userRequest list
export const getUserRequestList = async (queryString) => {
  if (localStorage.token) {
    axiosRequest.defaults.headers.common[
      "x-auth-token"
    ] = `${localStorage.token}`;
  }
  try {
    const response = await axiosRequest.get(`/userRequestList` + queryString);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//get Buyer List
export const getBuyerList = async (queryString) => {
  try {
    // const response = await axiosRequest.get(`/userList` + queryString);
    const response = await axiosRequest.get(`/userbased` + queryString);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getUserRequestDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/requestUserDetails/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// Add Company By Buyer Id 
export const AddCompanyByBuyerId = async (id, data) => {
  try {
    const response = await axiosRequest.post(`/createcompbyadmin/${id}`, data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

// Edit Company By Buyer Id 
export const EditCompanyByBuyerId = async (id, cId, data) => {
  try {
    const response = await axiosRequest.patch(`/updatecompbyadmin/${id}/${cId}`, data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

//get Seller List
export const getSellerList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`/sellerList` + queryString);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Add Seller
export const AddSeller = async (user) => {
  try {
    const response = await axiosRequest.post(`/addSeller`, user);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//userStatus change in seller
export const sellerStatusChange = async (id, payload) => {
  try {
    const response = await axiosRequest.post(
      `/sellerStatusUpdate/${id}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//delete seller

export const deleteSeller = async (id) => {
  try {
    const response = await axiosRequest.delete(`/deleteSeller/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//delete buyer
export const deleteBuyer = async (id) => {
  try {
    const response = await axiosRequest.delete(`/deleteUser/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//update Buyer
export const updateBuyer = async (buyer) => {
  if (localStorage.token) {
    axiosRequest.defaults.headers.common[
      "x-auth-token"
    ] = `${localStorage.token}`;
  }
  try {
    const response = await axiosRequest.patch(`/updateUser`, buyer);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//userDetails buyer

export const buyerDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/userDetails/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//userdetails seller
export const sellerDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/sellerDetails/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//reject

export const deleteUserRequest = async (id) => {
  try {
    const response = await axiosRequest.delete(`/deleteUser/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//approve

export const approveuser = async (user, id) => {
  try {
    const response = await axiosRequest.post(`/statusChange/${id}`, user);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//DocumentStatus

export const documentStatus = async (id, user) => {
  try {
    const response = await axiosRequest.post(`/DocumentStatus/${id}`, user);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// get role list

export const getRoleList = async () => {
  try {
    const response = await axiosRequest.get(`/roleList`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//update Seller
export const updateSeller = async (id, seller) => {
  try {
    const response = await axiosRequest.post(`/updateSeller/${id}`, seller);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const CompanyAssign = async (payload) => {
  try {
    const response = await axiosRequest.post(`/assignCompany`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getCompany = async (id) => {
  try {
    const response = await axiosRequest.get(`/allCompanyDropdown/${id}`);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.data) {
      return error.response.data;
    } else {
      return error;
    }
    // return error.response.data;
  }

};

export const deleteUserLocation = async (id) => {
  try {
    const response = await axiosRequest.delete(`/userLocationDelete/${id}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const taxStatusChange = async (id, payload) => {
  try {
    const response = await axiosRequest.post(`/updatetax/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updatePan = async (id, data) => {
  try {
    const response = await axiosRequest.patch(`/uploadpan/${id}`, data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export const updateGST = async (id, data) => {
  try {
    const response = await axiosRequest.patch(`/uploadGst/${id}`, data);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

