import axiosRequest from "../axios/axios";

export const profileDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/adminDetailsById/${id}`);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const updateProfile = async (id, payload) => {
  try {
    const response = await axiosRequest.post(`adminUpdate/${id}`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};

export const changePassword = async (payload) => {
  try {
    const response = await axiosRequest.post(`/api/v1/changePassword`, payload);
    return response.data;
  } catch (e) {
    if (e.request.status === 401) {
      window.location.href = "/login";
    }
    return e.response.data;
  }
};
