import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Controls from "../../Component/controls/Controls";
import { useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useForm, Form } from "../../Utils/useForm";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation } from "react-router";
import {
  addTransport,
  getCityList,
  getStateList,
  getTransport,
  updateTransport,
} from "../../state/action/locationAction";

const initialValues = {
  startStateId: "",
  startCityId: "",
  endStateId: "",
  endCityId: "",
  rent: "",
};

function AddTranport() {
  const theme = useTheme();
  const [fromCityList, setFromCityList] = useState([]);
  const [toCityList, setToCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isEdit, setIsEdit] = useState(false);


  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("startStateId" in fieldValues)
      temp.startStateId = fieldValues.startStateId
        ? ""
        : "This field is required.";

    if ("rent" in fieldValues) {
      let reg = /^[+-]?([0-9]*[.])?[0-9]+$/;
      if (fieldValues.rent === "") {
        temp.rent = "This field is required.";
      } else if (!reg.test(fieldValues.rent)) {
        temp.rent = "Please enter valid rent.";
      } else {
        temp.rent = "";
      }
    }

    if ("startCityId" in fieldValues)
      temp.startCityId = fieldValues.startCityId
        ? ""
        : "This field is required.";

    if ("endStateId" in fieldValues)
      temp.endStateId = fieldValues.endStateId ? "" : "This field is required.";

    if ("endCityId" in fieldValues)
      temp.endCityId = fieldValues.endCityId ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        startStateId: values.startStateId,
        startCityId: values.startCityId,
        endStateId: values.endStateId,
        endCityId: values.endCityId,
        rent: values.rent,
      };
      if (isEdit) {
        // updateProduct(formData, state.product._id);
        const res = await updateTransport(state.id, payload);
        if (res && res.status === true) {
          navigate("/transport");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        const res = await addTransport(payload);
        if (res && res.status === true) {
          navigate("/transport");
          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };

  const getTransportDetails = async (id) => {
    const res = await getTransport(id);

    if (res.status === true) {
      setValues({
        ...values,
        startStateId: res.data.startStateId,
        startCityId: res.data.startCityId,
        endStateId: res.data.endStateId,
        endCityId: res.data.endCityId,
        rent: res.data.rent,
      });
      FromCityList(res.data.startStateId);
      ToCityList(res.data.endStateId);
    }
  };

  const FromCityList = async (id) => {
    const res = await getCityList(id);
    if (res.status === true) {
      setFromCityList(res.data);
    } else {
      setFromCityList([]);
    }
  };
  const ToCityList = async (id) => {
    const res = await getCityList(id);
    if (res.status === true) {
      setToCityList(res.data);
    } else {
      setToCityList([]);
    }
  };

  //statelist
  useEffect(() => {
    StateList();
  }, []);


  const StateList = async () => {
    const res = await getStateList();
    if (res.status === true) {
      setStateList(res.data);
    } else {
      setStateList([]);
    }
  };

  useEffect(() => {
    if (state?.id != "" && state?.id != null && state?.id != undefined) {
      setIsEdit(true);
      getTransportDetails(state.id);
    }
  }, [state]);
  return (
    <Box>
      <Box
        sx={{
          px: 3,
          paddingBottom: "3px",
          backgroundColor: "rgb(241, 245, 249)",
        }}
      >
        <Typography
          sx={{
            paddingTop: "25px",
            paddingBottom: "15px",
            fontSize: "15px",
            fontWeight: "600",
            color: "#000",
            cursor: "pointer",
          }}
          onClick={() => navigate("/transport")}
        >
          <KeyboardBackspaceIcon
            sx={{ verticalAlign: "top", marginRight: "5px" }}
          />
          Transport
        </Typography>
        <Grid container alignItems="top" style={{ marginBottom: "20px" }}>
          <Grid item sm={6} xs={12}>
            <Box display="flex">
              <Box>
                <img
                  src={"../../assets/images/product-image.png"}
                  style={{ borderRadius: "5px" }}
                />
              </Box>
              <Box sx={{ marginLeft: "10px" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "600", color: "#000" }}
                >
                  Add Transport
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#000",
                    marginTop: "3px",
                  }}
                >
                  Transport
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box
              display="flex"
              sx={{
                justifyContent: {
                  xs: "block",
                  sm: "flex-end",
                  md: "flex-end",
                  lg: "flex-end",
                },
                marginTop: { xs: "20px", md: "0", lg: "0" },
              }}
            >
              <Controls.Button
                text="Cancel"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#fff",
                  backgroundColor: "red",
                  ":hover": { bgcolor: "red" },
                  textTransform: "none",
                  borderRadius: "50px",
                  boxShadow: 0,
                }}
                onClick={() => {
                  navigate("/transport");
                }}
              />
              <Controls.Button
                text="Save"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  backgroundColor: "rgb(79, 70, 229)",
                  ":hover": { bgcolor: "rgb(79, 70, 229)" },
                  marginLeft: "10px",
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  textTransform: "none",
                  borderRadius: "50px",
                  color: "#fff",
                }}
                variant="outlined"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ backgroundColor: "rgb(241, 245, 249)" }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderTopRightRadius: "20px",
            borderTopLeftRadius: "20px",
            border: 1,
            borderColor: "#eaeaea",
            paddingLeft: "0px",
            paddingTop: "10px",
          }}
        >
          <Box
            sx={{
              marginTop: "15px",
              marginLeft: "15px",
              marginRight: "15px",
              marginBottom: "15px",
            }}
          >
            <Form onSubmit={handleSubmit}>
              <Box
                sx={{
                  maxWidth: {
                    md: "500px",
                    lg: "700px",
                  },
                  marginLeft: {
                    xs: "5px",
                    md: "5px",
                    lg: "15px",
                  },
                  paddingBottom: "20px",
                }}
              >
                <Box sx={{ marginTop: "15px" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#000",
                      marginBottom: "7px",
                    }}
                  >
                    From
                  </Typography>
                  <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item sm={6} xs={12}>
                      {/* <Controls.Input
                        text="Add New"
                        variant="outlined"
                        color="primary"
                        label="State"
                        // defaultValue="Delhi"
                        name="startStateId"
                        onChange={handleInputChange}
                        value={values.startStateId}
                        error={errors.startStateId}
                        inputProps={{ maxLength: 100 }}
                        maxLength={200}
                      /> */}

                      <Controls.SelectBox
                        text="Add New"
                        className="input"
                        fullWidth
                        id="State"
                        name="startStateId"
                        value={values.startStateId}
                        error={errors.startStateId}
                        onChange={(e) => {
                          handleInputChange(e);
                          FromCityList(e.target.value);
                        }}
                        color="primary"
                        options={stateList}
                        label="State"
                      />
                      {Boolean(errors.startStateId) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            marginTop: "4px",
                            marginBottom: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {errors.startStateId}
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Controls.SelectBox
                        text="Add New"
                        className="input"
                        fullWidth
                        id="startCityId"
                        name="startCityId"
                        value={values.startCityId}
                        error={errors.startCityId}
                        onChange={handleInputChange}
                        color="primary"
                        options={fromCityList}
                        label="City"
                      />
                      {Boolean(errors.startCityId) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            marginTop: "4px",
                            marginBottom: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {errors.startCityId}
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ marginTop: "15px" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "600",
                      color: "#000",
                      marginBottom: "7px",
                    }}
                  >
                    To
                  </Typography>
                  <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                    <Grid item sm={6} xs={12}>
                      <Controls.SelectBox
                        text="Add New"
                        className="input"
                        fullWidth
                        id="endStateId"
                        name="endStateId"
                        value={values.endStateId}
                        error={errors.endStateId}
                        onChange={(e) => {
                          handleInputChange(e);
                          ToCityList(e.target.value);
                        }}
                        color="primary"
                        options={stateList}
                        label="State"
                      />

                      {Boolean(errors.endStateId) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            marginTop: "4px",
                            marginBottom: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {errors.endStateId}
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Controls.SelectBox
                        text="Add New"
                        className="input"
                        fullWidth
                        id="endCityId"
                        name="endCityId"
                        value={values.endCityId}
                        error={errors.endCityId}
                        onChange={handleInputChange}
                        color="primary"
                        options={toCityList}
                        label="City"
                      />
                      {Boolean(errors.endCityId) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            marginTop: "4px",
                            marginBottom: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {errors.endCityId}
                        </p>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Box>

                <Controls.Input
                  text="Add New"
                  variant="outlined"
                  color="primary"
                  label="Freight"
                  name="rent"
                  onChange={handleInputChange}
                  value={values.rent}
                  error={errors.rent}
                  inputProps={{ maxLength: 5 }}
                />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AddTranport;
