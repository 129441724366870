import React, { useState, useEffect } from "react";
import {
  useTheme,
  Box,
  Grid,
  IconButton,
  InputBase,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import Link from "@mui/material/Link";
import LoadingButton from "@mui/lab/LoadingButton";
import Controls from "../../Component/controls/Controls";
import Cookies from "universal-cookie";
import CardMedia from "@mui/material/CardMedia";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, Form } from "../../Utils/useForm";
import Notification from "../../Utils/Notification";
import { loginUserApi } from "../../state/action/authAction";
import { LineWeight } from "@mui/icons-material";
import Icon from "../../assets/InvoiceLogo.png";

const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiInputBase-root {
    padding: 17.5px 14px !important;
    border-radius: 7px;
  }
  & .MuiInputLabel-root {
    line-height: 2.7;
    overflow: visible;
  }
  & .MuiInputLabel-root {
    color: rgb(92 91 91 / 70%) !important;

    font-weight: 500 !important;
    font-size: 14px;
    height: 20px;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: rgba(107, 114, 128, 0.5);
  }
  & label.Mui-focused {
    color: #4f46e5 !important;
    border-color: black;
  }

  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
  & .MuiSvgIcon-root {
    color: black;
  }
`;

const MuiLoadingButton = styled(LoadingButton)`
  & .MuiLoadingButton-root.Mui-disabled {
    color: #fff;
    backgroundcolor: #000;
  }
`;
const initialValues = { email: "", password: "" };

function Login() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const Navigate = useNavigate();

  const cookies = new Cookies();
  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const dispatch = useDispatch();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  if (isAuthenticated) {
    Navigate("/");
  }

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        if (!/$^|.+@.+..+/.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("password" in fieldValues) {
      if (fieldValues.password !== "" && fieldValues.password !== undefined) {
        if (fieldValues.password.length < 8) {
          temp.password = "Password  must be 8 charecter";
        } else {
          if (!/[0-9]/.test(fieldValues.password)) {
            temp.password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp.password = "";
              }
            }
          }
        }
      } else {
        temp.password = "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoadingButton(!loadingButton);
      const LoginResponse = await dispatch(
        loginUserApi(
          {
            email: values.email,
            password: values.password,
          },
          rememberMe
        )
      );
      if (LoginResponse && LoginResponse.status === true) {
        setLoadingButton(!loadingButton);
        setNotify({
          isOpen: true,
          message: LoginResponse.message || "Login Successfully.",
          type: "success",
        });
        setTimeout(() => {
          Navigate("/");
        }, 2000);
      } else {
        setLoadingButton(false);
        setNotify({
          isOpen: true,
          message: LoginResponse.message || "Login Fail.",
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    const userEmail = cookies.get("email");
    const userPassword = cookies.get("password");
    if (userEmail !== "" && userPassword !== "") {
      setValues({
        ...values,
        email: userEmail,
        password: userPassword,
      });
    }
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: "#1e293b",
        height: { xs: "100%", sm: "100vh", md: "100vh" },
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "900px",
          margin: "0 auto",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Grid
          container
          item
          columns={{ xs: 4, sm: 12, md: 12 }}
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                padding: { xs: "20px", md: "40px" },
              }}
            >
              <CardMedia
                sx={{ maxWidth: "100px", marginBottom: "20px" }}
                component="img"
                image={Icon}
                alt="Paella dish"
              />
              <Typography
                sx={{ fontSize: "35px", fontWeight: "bold", color: "#000" }}
              >
                Sign in
              </Typography>

              <Form onSubmit={handleSubmit}>
                <Box sx={{ marginBottom: "10px" }}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                  />
                </Box>
                {Boolean(errors.email) ? (
                  <p
                    style={{
                      color: "rgb(255 0 0)",
                      marginTop: "4px",
                      marginBottom: "4px",
                      fontSize: "14px",
                    }}
                  >
                    {errors.email}
                  </p>
                ) : (
                  ""
                )}
                <FormControl
                  sx={{ marginTop: "10px", width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    sx={{
                      "& .MuiInputBase-input": {
                        padding: "12px 14px !important",
                      },

                      "& .MuiOutlinedInput-notchedOutline": {
                        paddingTop: "10px!important",
                        borderColor: "rgba(107, 114, 128, .5)",

                        borderRadius: "7px!important",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "blue",
                      },
                      paddingTop: "0px",
                      color: "#000",
                    }}
                    id="outlined-adornment-password"
                    name="password"
                    value={values.password}
                    onChange={handleInputChange}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ color: "#000" }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {Boolean(errors.password) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.password}
                    </p>
                  ) : (
                    ""
                  )}
                </FormControl>

                <Grid
                  container
                  item
                  xs={12}
                  spacing={1}
                  style={{ marginTop: "1rem", alignItems: "center" }}
                >
                  <Grid item xs={6}>
                    <Controls.Checkbox
                      sx={{ fontSize: "20px" }}
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Remember Me"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Link
                      href="#"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "medium",
                        color: "blue",
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
                <LoadingButton
                  sx={{
                    ":hover": { bgcolor: "rgb(79, 70, 229)" },
                    marginTop: "20px",
                    textTransform: "none",
                    fontSize: "16px",
                    color: "#fff",
                    width: "100%",
                    backgroundColor: "rgb(79, 70, 229)",
                    borderRadius: "15px",
                    padding: "10px 40px",
                    "&.Mui-disabled": {
                      backgroundColor: "rgb(79, 70, 229)",
                      color: "#fff",
                    },
                  }}
                  // loading={loadingButton}
                  // loadingPosition="start"
                  // startIcon={<LoginIcon />}
                  // variant="contained"
                  type="submit"
                >
                  Sign In
                </LoadingButton>
              </Form>
            </Box>
          </Grid>

          <Grid item xs={6} md={6}>
            <Box className="login_img">
              <CardMedia
                sx={{ maxWidth: "420px", marginLeft: "auto", padding: "10px" }}
                component="img"
                image="../../assets/images/login-img.jpg"
                alt="Paella dish"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Notification notify={notify} setNotify={setNotify} />
    </Box>
  );
}

export default Login;
