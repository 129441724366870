import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { InputBase } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid, Tooltip, Pagination } from "@mui/material";
import { useForm, Form } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Stack from "@mui/material/Stack";
import { Drawer, FormLabel } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Controls from "../../Component/controls/Controls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { styled } from "@mui/material/styles";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router";
import { Space } from "antd";
import BackdropComponent from "../../Component/BackDrop/BackDrop";
import {
  getTransportList,
  transportStatusChange,
  deleteTransport,
  getCityList,
  getStateList,
} from "../../state/action/locationAction";
import Notification from "../../Utils/Notification";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
const initialValues = {
  startStateId: "",
  startCityId: "",
  endStateId: "",
  endCityId: "",
  startRent: "",
  endRent: "",
  status: "",
};

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 14,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function createData(
  name,
  FromState,
  FromCity,
  ToState,
  ToCity,
  Rent,
  Status,
  Action
) {
  return { name, FromState, FromCity, ToState, ToCity, Rent, Status, Action };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs() {
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [tranportList, setTranportList] = useState([]);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [status, setStatus] = useState();
  const [switchId, setSwitchId] = useState(null);
  const [notify, setNotify] = useState({});
  const [checked, setChecked] = useState(true);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [search, setSearch] = useState("");
  const [fromCityList, setFromCityList] = useState([]);
  const [toCityList, setToCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [range, setRange] = useState([]);
  const [ranges, setRanges] = useState([]);

  const [flagFromState, setFlagFromState] = useState(false);
  const [flagFromCity, setFlagFromCity] = useState(false);
  const [flagEndState, setFlagEndState] = useState(false);
  const [flagEndCity, setFlagEndCity] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [sortFlag, setSortFlag] = useState(false);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setRange(newValue);
  };

  const [pagePerSize, setPagePerSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const handleChangePage = (event, newPage) => {
    if (fieldName == "startStateId") {
      TransportList(newPage, fieldName, flagFromState);
    } else if (fieldName == "startCityId") {
      TransportList(newPage, fieldName, flagFromCity);
    } else if (fieldName == "endStateId") {
      TransportList(newPage, fieldName, flagEndState);
    } else if (fieldName == "endCityId") {
      TransportList(newPage, fieldName, flagEndCity);
    } else {
      TransportList(newPage, "", "");
    }
    setPage(newPage);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const handleSubmit = (e) => {
    setPage(1);
    e.preventDefault();
    setValues({
      ...values,
      startRange: ranges[0],
      endRange: ranges[1],
    });
    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const statusList = [
    { id: "1", title: "Active" },
    { id: "0", title: "Inactive" },
    { id: "2", title: "Delete" },
  ];

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleChanges = (event, newValue) => {
    setRanges(newValue);
  };

  useEffect(() => {
    TransportList(page, "", "");
  }, [search, filterData]);

  const TransportList = async (page, name = "", sortFlag) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }

    const queryString = `?page=${page}&search=${search}${string}&sortBy=${name}&sortFlag=${sortFlag}`;

    const res = await getTransportList(queryString);

    if (res.status === true) {
      setTranportList(res?.data);
      setTotalRecords(res.totalRecord);
      setTotalPages(Math.ceil(res.totalRecord / res.perPageSize));
      setRange(res.max);
      setRanges([0, res.max]);
      setFilter(false);
      setOpenBreakdrop(false);
    } else {
      setTranportList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
      // setTotalRecords(0);
      // setTotalPages(0);
      setFilter(false);
    }
  };

  const handleChangeStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      status: checked ? 1 : 0,
    };
    const res = await transportStatusChange(switchId, payload);

    if (res && res.status === true) {
      if (fieldName == "startStateId") {
        TransportList(page, fieldName, flagFromState);
      } else if (fieldName == "startCityId") {
        TransportList(page, fieldName, flagFromCity);
      } else if (fieldName == "endStateId") {
        TransportList(page, fieldName, flagEndState);
      } else if (fieldName == "endCityId") {
        TransportList(page, fieldName, flagEndCity);
      } else {
        TransportList(page, "", "");
      }
      handleCloseSwitch();
      setOpenBreakdrop(false);
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteTransport(id);
    setOpenBreakdrop(true);
    if (res && res.status === true) {
      if (fieldName == "startStateId") {
        TransportList(page, fieldName, flagFromState);
      } else if (fieldName == "startCityId") {
        TransportList(page, fieldName, flagFromCity);
      } else if (fieldName == "endStateId") {
        TransportList(page, fieldName, flagEndState);
      } else if (fieldName == "endCityId") {
        TransportList(page, fieldName, flagEndCity);
      } else {
        TransportList(page, "", "");
      }
      handleCloseDelete();
      setNotify({
        isOpen: true,
        message: res.message || "Delete Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const FromCityList = async (id) => {
    const res = await getCityList(id);
    if (res.status === true) {
      setFromCityList(res.data);
    } else {
      setFromCityList([]);
    }
  };
  const ToCityList = async (id) => {
    const res = await getCityList(id);
    if (res.status === true) {
      setToCityList(res.data);
    } else {
      setToCityList([]);
    }
  };

  //statelist
  useEffect(() => {
    StateList();
  }, []);

  const StateList = async () => {
    const res = await getStateList();
    if (res.status === true) {
      setStateList(res.data);
    } else {
      setStateList([]);
    }
  };

  const dataShorting = (name) => {
    if (name == "startStateId") {
      setFlagFromState(!flagFromState);
      TransportList(page, name, !flagFromState);
    } else {
      setFlagFromState(false);
      setSortFlag(false);
    }

    if (name == "startCityId") {
      setFlagFromCity(!flagFromCity);
      TransportList(page, name, !flagFromCity);
    } else {
      setFlagFromCity(false);
      setSortFlag(false);
    }

    if (name == "endStateId") {
      setFlagEndState(!flagEndState);
      TransportList(page, name, !flagEndState);
    } else {
      setFlagEndState(false);
      setSortFlag(false);
    }
    if (name == "endCityId") {
      setFlagEndCity(!flagEndCity);
      TransportList(page, name, !flagEndCity);
    } else {
      setFlagEndCity(false);
      setSortFlag(false);
    }
    setFieldName(name);
  };
  return (
    <>
      <Box sx={{ backgroundColor: "rgb(241, 245, 249)" }}>
        <BackdropComponent open={openBackdrop} />

        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                Transport
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box
                sx={{
                  display: { xs: "block", md: "flex", lg: "flex" },
                  marginTop: { xs: "20px", md: "0", lg: "0" },
                }}
                justifyContent="flex-end"
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setTimeout(() => {
                        setSearch(e.target.value);
                      }, 800);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "20px", md: "0", lg: "0" } }}
                >
                  <Controls.Button
                    startIcon={<AddIcon />}
                    text="Add"
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "50px",
                      border: "0",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(79, 70, 229)", border: "0" },
                      backgroundColor: "rgb(79, 70, 229)",
                      marginLeft: "15px",
                      textTransform: "none",
                    }}
                    variant="outlined"
                    onClick={() => navigate("/addtransport")}
                  />
                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            borderTopRightRadius: "20px",
            borderTopLeftRadius: "20px",
            border: 1,
            borderColor: "#eaeaea",
          }}
        >
          <Box>
            <TableContainer component={Paper} sx={{ boxShadow: "0" }}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow hover>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                        onClick={() => dataShorting("startStateId")}
                      >
                        From State
                        {flagFromState ? (
                          <ArrowUpwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                            }}
                          />
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                        onClick={() => dataShorting("startCityId")}
                      >
                        From City
                        {flagFromCity ? (
                          <ArrowUpwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                            }}
                          />
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                        onClick={() => dataShorting("endStateId")}
                      >
                        To State
                        {flagEndState ? (
                          <ArrowUpwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                            }}
                          />
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                        onClick={() => dataShorting("endCityId")}
                      >
                        To City
                        {flagEndCity ? (
                          <ArrowUpwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            sx={{
                              color: "rgba(107, 114, 128, .5)",
                              fontSize: "17px",
                              marginLeft: "3px",
                            }}
                          />
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          minWidth: "auto!important",
                        }}
                      >
                        Freight
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          minWidth: "auto!important",
                        }}
                      >
                        Status
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        variant="text"
                        sx={{
                          ":hover": { bgcolor: "transparent" },
                          paddingLeft: "0",
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                        }}
                      >
                        Action
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tranportList.map((row, index) => (
                    <StyledTableRow
                      hover
                      key={index}
                      style={{ cursor: "pointer" }}
                    >
                      <StyledTableCell>{row?.startStateName}</StyledTableCell>
                      <StyledTableCell>{row.startCityName}</StyledTableCell>
                      <StyledTableCell>{row.endStateName}</StyledTableCell>

                      <StyledTableCell>{row.endCityName}</StyledTableCell>
                      <StyledTableCell>{row.rent}</StyledTableCell>
                      <StyledTableCell>
                        <Controls.Switch
                          checked={row.status == "1" ? true : false}
                          onChange={(e) => {
                            handleSwitchModel();
                            setStatus(
                              e.target.checked == true ? "Active" : "InActive"
                            );
                            setSwitchId(row.id);
                            setChecked(e.target.checked);
                          }}
                          name="checkedB"
                          color="primary"
                          sx={{ marginLeft: "40px" }}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Space size="middle">
                          {/* <Tooltip placement="bottom" title="View Transport">
                            <button className="action-button view">
                              <GrView />
                            </button>
                          </Tooltip> */}
                          <Tooltip
                            placement="bottom"
                            className="cursor-pointer"
                            title="Edit Transport"
                          >
                            <button
                              className="action-button edit "
                              onClick={() =>
                                navigate("/addtransport", {
                                  state: {
                                    id: row?.id,
                                  },
                                })
                              }
                            >
                              <RiEditLine />
                            </button>
                          </Tooltip>
                          <Tooltip placement="bottom" title="Delete Transport">
                            <button
                              className="action-button delete-btn"
                              onClick={() => {
                                // setId(record.id);
                                setDeleteId(row?.id);
                                handleDeleteModel();
                              }}
                            >
                              <RiDeleteBinLine />
                            </button>
                          </Tooltip>
                        </Space>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          mx: 3,
          mt: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "14px",
            marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
          }}
        >
          {" "}
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </Box>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          sx={{
            "& .MuiButtonBase-root ": {
              color: "#000 !important",
            },
            "& .Mui-selected ": {
              background: "#4b4b4e70 !important",
            },
            "& .Mui-disabled ": {
              background: "#6c696966 !important",
              color: "#000000 !important",
              opacity: 1,
            },
          }}
        />

        <Modal
          keepMounted
          open={openSwitchModel}
          onClose={handleCloseSwitch}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#000",
                marginBottom: "15px",
              }}
            >
              Status Change
            </Typography>
            <label className="fontLight">
              Are you sure change status to {status} ?
            </label>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{ backgroundColor: "#fff", color: "#000" }}
                  text="Cancel"
                  onClick={handleCloseSwitch}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Change"
                  onClick={() => handleChangeStatus(switchId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Modal
          keepMounted
          open={openDeleteModel}
          onClose={handleCloseDelete}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={styles}>
            <label className="fontLight">
              Are you sure to want to delete this record?
            </label>
            <Grid container gap={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={8} className="text-end">
                <Controls.Button text="Cancel" onClick={handleCloseDelete} />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  text="Delete"
                  style={{ backgroundColor: "red" }}
                  onClick={() => handleDelete(deleteId)}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <Form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                width: 300,
                margin: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  // marginLeft: "8px",
                  marginTop: "8px",

                  // marginRight: "8px",
                }}
              >
                <Divider sx={{ mt: 1, bgcolor: "rgba(107, 114, 128, .4)" }} />

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <StyledFormLabel htmlFor="name">From State</StyledFormLabel>

                    <Controls.SelectBox
                      text="Add New"
                      className="input"
                      fullWidth
                      id="State"
                      name="startStateId"
                      value={values.startStateId}
                      error={errors.startStateId}
                      onChange={(e) => {
                        handleInputChange(e);
                        FromCityList(e.target.value);
                      }}
                      color="primary"
                      options={stateList}
                      label="State"
                    />
                  </Box>
                  <Box>
                    <StyledFormLabel htmlFor="name">From City</StyledFormLabel>
                    <Controls.SelectBox
                      text="Add New"
                      className="input"
                      fullWidth
                      id="startCityId"
                      name="startCityId"
                      value={values.startCityId}
                      error={errors.startCityId}
                      onChange={handleInputChange}
                      color="primary"
                      options={fromCityList}
                      label="City"
                    />
                  </Box>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <StyledFormLabel htmlFor="name">To State</StyledFormLabel>

                    <Controls.SelectBox
                      text="Add New"
                      className="input"
                      fullWidth
                      id="endStateId"
                      name="endStateId"
                      value={values.endStateId}
                      error={errors.endStateId}
                      onChange={(e) => {
                        handleInputChange(e);
                        ToCityList(e.target.value);
                      }}
                      color="primary"
                      options={stateList}
                      label="State"
                    />
                  </Box>
                  <Box>
                    <StyledFormLabel htmlFor="name">To City</StyledFormLabel>
                    <Controls.SelectBox
                      text="Add New"
                      className="input"
                      fullWidth
                      id="endCityId"
                      name="endCityId"
                      value={values.endCityId}
                      error={errors.endCityId}
                      onChange={handleInputChange}
                      color="primary"
                      options={toCityList}
                      label="City"
                    />
                  </Box>
                </Box>
              </Box>

              <Box>
                <StyledFormLabel htmlFor="name">Rent</StyledFormLabel>

                {/* <Controls.Input
                  className="input"
                  // placeholder="Enter Product Name"
                  fullWidth
                  id="rent"
                  name="rent"
                  value={values.rent}
                  onChange={handleInputChange}
                  sx={{ mt: 0 }}
                /> */}
                <Slider
                  getAriaLabel={() => "Temperature range"}
                  value={ranges}
                  min={0}
                  step={1}
                  max={range}
                  onChange={handleChanges}
                  valueLabelDisplay="auto"
                />

                <StyledFormLabel htmlFor="hsnCode">Status</StyledFormLabel>

                <Controls.SelectBox
                  className="input"
                  color="primary"
                  fullWidth
                  id="status"
                  name="status"
                  value={values.status}
                  onChange={handleInputChange}
                  options={statusList}
                  label="Status"
                />
              </Box>
              <Box></Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button className="order-filter-button" type="submit">
                Filter
              </button>
            </Stack>
          </Form>
        </Drawer>
      </Box>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
