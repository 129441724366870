import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router";
import { GrView } from "react-icons/gr";
import { Space } from "antd";
import { AiOutlineCloseCircle } from "react-icons/ai";

import {
  getProduct,
  productStatusChange,
  deleteProduct,
  getProductList,
} from "../../state/action/productAction";
// import { Space } from "antd";
import {
  Typography,
  Stack,
  Pagination,
  Alert,
  Chip,
  Drawer,
  Box,
  FormLabel,
  Tooltip,
  Grid,
  Modal,
  Divider,
} from "@mui/material";
import Controls from "../../Component/controls/Controls";
import { useForm } from "../../Utils/useForm";
import moment from "moment";

const initialValues = {
  productName: "",
  categoryId: "",
  fromPrice: "",
  toPrice: "",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 14,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

function ProductLists() {
  const [deleteValue, setDeleteValue] = useState(false);
  const [switchId, setSwitchId] = useState(null);
  const [checked, setChecked] = useState(true);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [status, setStatus] = useState();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [search, setSearch] = useState("");
  const [flagName, setflagName] = useState(false);
  const [flagPrice, setflagPrice] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState([]);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [productList, setProductList] = useState([]);
  const [sortFlag, setSortFlag] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [notify, setNotify] = useState({});
  const [fieldName, setFieldName] = useState("");

  const { state } = useLocation();
  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);
  const [value, setValue] = useState("1");

  const handleChangePage = (e, value) => {
    if (fieldName == "name") {
      ProductList(value, fieldName, flagName);
    } else if (fieldName == "margin") {
      ProductList(value, fieldName, flagPrice);
    } else {
      ProductList(value, "", "");
    }
    setPage(value);
  };

  useEffect(() => {
    ProductList(page, "", "");
  }, [filterData, search, refresh, value]);

  const ProductList = async (page, name = "", flag) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let queryString = `?page=${page}&limit=${rowsPerPage}&search=${search}${string}&sortBy=${name}&sortFlag=${flag}`;

    const res = await getProductList(queryString);

    if (res.status === true) {
      setProductList(res.data);
      setTotalRecords(res.totalProduct);
      setTotalPages(Math.ceil(res.totalProduct / rowsPerPage));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setProductList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  const navigate = useNavigate();

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  const dataShorting = (name) => {
    if (name === "name") {
      setflagName(!flagName);
      ProductList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    if (name === "margin") {
      setflagPrice(!flagPrice);
      ProductList(page, name, !flagPrice);
    } else {
      setflagPrice(false);
      setSortFlag(!sortFlag);
    }
    setFieldName(name);
  };

  const handledelete = () => {
    setDeleteValue(true);
  };
  const closedelete = () => {
    setDeleteValue(false);
  };

  const handleChangeStatus = async () => {
    // setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      status: checked ? 1 : 0,
    };
    const res = await productStatusChange(switchId, payload);

    if (res && res.status === true) {
      if (fieldName == "name") {
        ProductList(page, fieldName, flagName);
      } else if (fieldName == "margin") {
        ProductList(page, fieldName, flagPrice);
      } else {
        ProductList(value, "", "");
      }
      handleCloseSwitch();
      // setRefresh(!refresh);
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteProduct(id);
    setOpenBreakdrop(!openBackdrop);
    if (res && res.status === true) {
      if (fieldName == "name") {
        ProductList(page, fieldName, flagName);
      } else if (fieldName == "margin") {
        ProductList(page, fieldName, flagPrice);
      } else {
        ProductList(page, "", "");
      }
      handleCloseDelete();
      // setRefresh(!refresh);
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };


  return (
    <div>
      <Box sx={{ width: "100%", backgroundColor: "rgb(241, 245, 249)" }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderTopRightRadius: "20px",
            borderTopLeftRadius: "20px",
            border: 1,
            borderColor: "#eaeaea",
            maxHeight: "485px",
            overflow: "scroll",
          }}
        >
          <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell onClick={() => dataShorting("name")}>
                    Name
                    {flagName ? (
                      <ArrowUpwardIcon
                        sx={{
                          color: "rgba(107, 114, 128, .5)",
                          fontSize: "17px",
                          marginLeft: "3px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                    ) : (
                      <ArrowDownwardIcon
                        sx={{
                          color: "rgba(107, 114, 128, .5)",
                          fontSize: "17px",
                          marginLeft: "3px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>Category</StyledTableCell>
                  <StyledTableCell
                    align="center"
                    onClick={() => dataShorting("margin")}
                  >
                    Margin
                    {flagPrice ? (
                      <ArrowUpwardIcon
                        sx={{
                          color: "rgba(107, 114, 128, .5)",
                          fontSize: "17px",
                          marginLeft: "3px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                    ) : (
                      <ArrowDownwardIcon
                        sx={{
                          color: "rgba(107, 114, 128, .5)",
                          fontSize: "17px",
                          marginLeft: "3px",
                          position: "relative",
                          top: "3px",
                        }}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">Active</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productList &&
                  productList.length > 0 &&
                  productList.map((row) => (
                    <StyledTableRow
                      key={row.name}
                      hover
                      sx={{ cursor: "pointer" }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.productName}
                      </StyledTableCell>
                      <StyledTableCell>{row.categroyname}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.productMargin}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Controls.Switch
                          checked={row.status == "1" ? true : false}
                          onChange={(e) => {
                            handleSwitchModel();
                            setStatus(
                              e.target.checked == true ? "Active" : "InActive"
                            );
                            setSwitchId(row.id);
                            setChecked(e.target.checked);
                          }}
                          name="checkedB"
                          color="primary"
                          sx={{ marginLeft: "40px" }}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Space size="middle">
                          <Tooltip placement="bottom" title="View Product">
                            <button
                              className="action-button view"
                              onClick={() => {
                                navigate("/ProductDetail", {
                                  state: row.id,
                                });
                              }}
                            >
                              {" "}
                              <GrView />{" "}
                            </button>
                          </Tooltip>
                          <Tooltip placement="bottom" title="Edit Product">
                            <button
                              className="action-button edit"
                              onClick={(e) => {
                                navigate("/productEdit", {
                                  state: {
                                    product: row,
                                  },
                                });
                              }}
                            >
                              {" "}
                              <RiEditLine />{" "}
                            </button>
                          </Tooltip>
                          <Tooltip placement="bottom" title="Delete Product">
                            <button
                              className="action-button delete-btn"
                              onClick={() => {
                                // setId(record.id);
                                setDeleteId(row.id);
                                handleDeleteModel();
                              }}
                            >
                              {" "}
                              <RiDeleteBinLine />{" "}
                            </button>
                          </Tooltip>
                        </Space>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {productList && productList.length === 0 ? (
        <div className="noDataFound"> No Data Found</div>
      ) : (
        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "0", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "0", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            }}
          >
            {" "}
            Showing{" "}
            {page * rowsPerPage > totalRecords
              ? totalRecords
              : page * rowsPerPage}{" "}
            of {totalRecords} Results
          </Box>
          <Box>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              sx={{
                "& .MuiButtonBase-root ": {
                  color: "#000 !important",
                },
                "& .Mui-selected ": {
                  background: "#4b4b4e70 !important",
                },
                "& .Mui-disabled ": {
                  background: "#6c696966 !important",
                  color: "#000000 !important",
                  opacity: 1,
                },
              }}
            />
          </Box>
        </Box>
      )}

      <Modal
        keepMounted
        open={openSwitchModel}
        onClose={handleCloseSwitch}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#000",
              marginBottom: "15px",
            }}
          >
            Status Change
          </Typography>
          <label className="fontLight">
            Are you sure change status to {status} ?
          </label>
          <Grid container style={{ marginTop: "2rem" }}>
            <Grid item xs={9} className="text-end">
              <Controls.Button
                style={{ backgroundColor: "#fff", color: "#000" }}
                text="Cancel"
                onClick={handleCloseSwitch}
              />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                style={{ marginRight: "7px" }}
                text="Change"
                onClick={() => handleChangeStatus(switchId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <label className="fontLight">
            Are you sure to want to delete this record?
          </label>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseDelete} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Delete"
                style={{ backgroundColor: "red" }}
                onClick={() => handleDelete(deleteId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default ProductLists;
