
import { Box } from "@mui/system";
import React from "react";
import StateList from "../Location/StateList";

const State = () => {
  return (
    <Box>
      <StateList />
    </Box>
  );
};

export default State;
