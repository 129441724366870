import Button from "@mui/material/Button";

const Buttons = (props) => {
  const {
    variant,
    text,
    color,
    size,
    onClick,
    startIcon = "",
    endIcon = "",
    ...other
  } = props;

  return (
    <Button
      variant={variant || "contained"}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      color={color || "primary"}
      size={size || "medium"}
      {...other}
    >
      {text}
    </Button>
  );
};

export default Buttons;
