import { Box, Pagination } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { styled } from "@mui/material/styles";
import {
  Drawer,
  FormLabel,
  Stack,
  Divider,
  Tooltip,
  Button,
  Grid,
  Modal,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { GrView } from "react-icons/gr";
import { Space } from "antd";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material";
import { useForm } from "../../Utils/useForm";
import {
  getProductRequestList,
  productApprove,
} from "../../state/action/productAction";
import Controls from "../../Component/controls/Controls";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";

const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
}));

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  border: "0",
  outline: "0",
  bgcolor: "#fff",
  borderRadius: "10px",
  p: 3,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f6f9fb",
    color: "#000",
    fontSize: 14,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(250 250 250)!important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const initialValues = {
  productName: "",
  categoryId: "",
  fromPrice: "",
  toPrice: "",
  productMargin: "",
};

const ProductRequestList = (props) => {
  const { refresh, setRefresh } = props;
  const [switchId, setSwitchId] = useState(null);
  const [openSwitchModel, setOpenSwitchModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState([]);
  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [productList, setProductList] = useState([]);
  const [productRequestList, setProductRequestList] = useState([]);
  const [sortFlag, setSortFlag] = useState(false);
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [notify, setNotify] = useState({});
  const [flagName, setflagName] = useState(false);
  const [flagPrice, setflagPrice] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [value, setValue] = useState("1");

  const navigate = useNavigate();

  const handleChangePage = (e, value) => {
    if (fieldName == "name") {
      ProductRequestList(value, fieldName, flagName);
    } else if (fieldName == "margin") {
      ProductRequestList(value, fieldName, flagPrice);
    } else {
      ProductRequestList(value, "", "");
    }
    setPage(value);
  };

  const handleSwitchModel = () => setOpenSwitchModel(true);
  const handleCloseSwitch = () => setOpenSwitchModel(false);
  const handleCloseDelete = () => setOpenDeleteModel(false);
  const handleDeleteModel = () => setOpenDeleteModel(true);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    ProductRequestList(page);
  }, [filterData, search, refresh, page, rowsPerPage]);

  const ProductRequestList = async (page, name = "", flag) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let queryString = `?page=${page}&limit=${rowsPerPage}&search=${search}${string}&sortBy=${name}&sortFlag=${flag}`;

    const res = await getProductRequestList(queryString);

    if (res.status === true) {
      setProductRequestList(res.data);
      setTotalRecords(res.totalProduct);
      setTotalPages(Math.ceil(res.totalProduct / rowsPerPage));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setProductRequestList([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  const handleApproveStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      status: 1,
      productMargin: values?.productMargin,
    };
    const res = await productApprove(switchId, payload);

    if (res && res.status === true) {
      navigate("/product");
      // ProductList();
      handleCloseSwitch();
      setRefresh(!refresh);

      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);
      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const handleRejectStatus = async () => {
    setOpenBreakdrop(true);
    setOpenBreakdrop(!openBackdrop);
    const payload = {
      status: 4,
      // productMargin: values?.productMargin,
    };
    const res = await productApprove(deleteId, payload);

    if (res && res.status === true) {
      navigate("/product");
      handleCloseDelete();
      setRefresh(!refresh);
      setNotify({
        isOpen: true,
        message: res.message || "Status changed Successfully.",
        type: "success",
      });
    } else {
      setOpenBreakdrop(!openBackdrop);

      setNotify({
        isOpen: true,
        message: res.message || "Something went wrong.",
        type: "error",
      });
    }
  };

  const dataShorting = (name) => {
    if (name === "name") {
      setflagName(!flagName);
      ProductRequestList(page, name, !flagName);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    setFieldName(name);
  };

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#fff",
          border: 1,
          borderColor: "#eaeaea",
          maxHeight: "485px",
          overflow: "scroll",
        }}
      >
        <TableContainer component={Paper} sx={{ boxShadow: "0" }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow hover>
                <StyledTableCell onClick={() => dataShorting("name")}>
                  Name
                  {flagName ? (
                    <ArrowUpwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                        position: "relative",
                        top: "3px",
                      }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      sx={{
                        color: "rgba(107, 114, 128, .5)",
                        fontSize: "17px",
                        marginLeft: "3px",
                        position: "relative",
                        top: "3px",
                      }}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell>Category</StyledTableCell>
                <StyledTableCell>Seller Name</StyledTableCell>

                <StyledTableCell>Date</StyledTableCell>

                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productRequestList?.map((row) => (
                <StyledTableRow
                  hover
                  key={row.name}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/productReqDetail", {
                      state: row.id,
                    });
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    {row.productName}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    {row.categoryName}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.sellerName}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    {moment(row.createdAt).format("MM-DD-YYYY LT")}
                  </StyledTableCell>

                  <StyledTableCell
                    align="center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Space size="middle">
                      <Tooltip placement="bottom" title="View Product">
                        <button
                          className="action-button view"
                          onClick={() => {
                            navigate("/productReqDetail", {
                              state: row.id,
                            });
                          }}
                        >
                          {" "}
                          <GrView />{" "}
                        </button>
                      </Tooltip>
                      <Controls.Button
                        text="Accept"
                        sx={{
                          fontSize: "11px",
                          fontWeight: "500",
                          color: "#fff",
                          backgroundColor: "rgb(0,128,0)",
                          ":hover": { bgcolor: "rgb(0,128,0)" },
                          textTransform: "none",
                          borderRadius: "50px",
                          boxShadow: 0,
                        }}
                        onClick={(e) => {
                          handleSwitchModel();
                          setSwitchId(row.id);
                        }}
                      />
                      <Controls.Button
                        text="Reject"
                        sx={{
                          fontSize: "11px",
                          fontWeight: "500",
                          color: "#fff",
                          backgroundColor: "rgb(239, 83, 80)",
                          ":hover": { bgcolor: "rgb(239, 83, 80)" },
                          textTransform: "none",
                          borderRadius: "50px",
                          boxShadow: 0,
                        }}
                        onClick={(e) => {
                          handleDeleteModel();
                          setDeleteId(row.id);
                        }}
                      />
                    </Space>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {productRequestList && productRequestList.length === 0 ? (
        <div className="noDataFound"> No Data Found</div>
      ) : (
        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "0", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "0", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
            }}
          >
            {" "}
            Showing{" "}
            {page * rowsPerPage > totalRecords
              ? totalRecords
              : page * rowsPerPage}{" "}
            of {totalRecords} Results
          </Box>
          <Box>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              sx={{
                "& .MuiButtonBase-root ": {
                  color: "#000 !important",
                },
                "& .Mui-selected ": {
                  background: "#4b4b4e70 !important",
                },
                "& .Mui-disabled ": {
                  background: "#6c696966 !important",
                  color: "#000000 !important",
                  opacity: 1,
                },
              }}
            />
          </Box>
        </Box>
      )}

      <Modal
        keepMounted
        open={openSwitchModel}
        onClose={handleCloseSwitch}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styles}>
          <Box sx={{ marginTop: "-25px" }}>
            <Controls.Input
              text="Add New"
              variant="outlined"
              color="primary"
              label="Product Margin"
              name="productMargin"
              value={values.productMargin}
              onChange={handleInputChange}
              error={errors.productMargin}
              helperText={errors.productMargin}
              fullWidth
            />
          </Box>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseSwitch} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Approve"
                style={{ backgroundColor: "red" }}
                onClick={() => handleApproveStatus(switchId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openDeleteModel}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styles}>
          <label className="fontLight">
            Are you sure to want to Reject this record?
          </label>
          <Grid container gap={1} style={{ marginTop: "1rem" }}>
            <Grid item xs={8} className="text-end">
              <Controls.Button text="Cancel" onClick={handleCloseDelete} />
            </Grid>
            <Grid item xs={3} className="text-end">
              <Controls.Button
                text="Reject"
                style={{ backgroundColor: "red" }}
                onClick={() => handleRejectStatus(deleteId)}
                type="submit"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProductRequestList;
