import React from "react";

//Switch
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";


export default function Switches(props) {
    const { name, label, value, onChange, checked } = props;
    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={onChange}
                    name={name}
                    color="primary"
                    
                    defaultChecked
                />
            }
            label={label}
        />

    )


}