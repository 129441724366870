import React, { useState, useEffect } from "react";
// import ResetPassword from "./ResetPassword";
import {
  Grid,
  Box,
  TextField,
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import Modal from "@mui/material/Modal";
// import ToastNotification from "../../Utils/toastNotification";
import { Form, useForm } from "../../Utils/useForm";
import { store } from "../../state/store";
// import { profileDetails, updateProfile } from "../../state/actions/profile";
import { useNavigate } from "react-router-dom";
import Controls from "../../Component/controls/Controls";
import { profileDetails } from "../../state/action/profile";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--main-bg)",
  borderRadius: "10px",
  boxShadow: 24,
  minHeight: "50px",
  overflowY: "scroll",
  transform: "translate(-50%, -50%)",
  maxHeight: "100%",
  p: 2,
};
const WhiteBorderTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: black;
  }
  & .MuiInputLabel-root.Mui-error {
    color: #fc9494;
  }
  & .MuiInputLabel-root {
    color: black;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & label.Mui-focused {
    color: black;
  }
  & .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc9494 !important;
  }
`;
const initialValues = {
  name: "",
  profile: "",
  email: "",
};

export default function Profile() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [ResetPasswordOpen, setResetPasswordOpen] = useState(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate() && isEdit) {
      // const res = await updateProfile(values);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userPlanData, setUserPlanData] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const ResetPasswordClose = () => {
    setResetPasswordOpen(false);
  };

  const users = useSelector((store) => store?.auth?.user);

  const getProfile = async () => {
    setOpenBreakdrop(true);
    const res = await profileDetails(users?.id);
    if (res && res.status === true) {
      setOpenBreakdrop(false);
      setValues({
        ...values,
        fname: res.data.fname,
        lname: res.data.lname,
        email: res.data.email,
      });
    } else {
      setOpenBreakdrop(false);
    }
  };
  useEffect(() => {
    getProfile(users?.id);
  }, [users?.id]);

  return (
    <>
      <Grid container>
        <Grid item container xs={12}>
          <Grid sm={6} xs={6}>
            <h2 className="title">Profile</h2>
          </Grid>
          <Grid sm={6} xs={6}>
            <Box
              style={{
                float: "right",
                marginTop: "20px",
              }}
            >
              <Controls.Button
                text={isEdit ? "Save" : "Edit"}
                onClick={(e) => {
                  setIsEdit(!isEdit);
                  handleSubmit(e);
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <div
          style={{
            borderRadius: "10px",
            color: "black",
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              container
              spacing={1}
              style={{
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <Table>
                <TableBody>
                  <TableRow key={"name"}>
                    <TableCell
                      sx={{
                        pl: 2,
                        width: "15%",
                        color: "black",
                        fontSize: { xs: "14px", md: "16px", lg: "16px" },
                      }}
                    >
                      Name
                    </TableCell>
                    {isEdit ? (
                      <TableCell>
                        <WhiteBorderTextField
                          name="name"
                          label="Name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={handleInputChange}
                          value={values.fname + "" + values.lname}
                          inputProps={{ maxLength: 100 }}
                          error={Boolean(errors.name)}
                          helperText={errors.name}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          pl: 2,
                          color: "black",
                          fontSize: { xs: "14px", md: "16px", lg: "16px" },
                        }}
                      >
                        {values.fname + "" + values.lname || ""}
                      </TableCell>
                    )}
                  </TableRow>

                  <TableRow key={"email"}>
                    <TableCell
                      sx={{
                        pl: 2,
                        color: "black",
                        fontSize: { xs: "14px", md: "16px", lg: "16px" },
                      }}
                    >
                      Email
                    </TableCell>

                    <TableCell
                      sx={{
                        pl: 2,
                        color: "black",
                        fontSize: { xs: "14px", md: "16px", lg: "16px" },
                      }}
                    >
                      {values.email}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell
                      sx={{
                        pl: 2,
                        color: "black",
                        fontSize: { xs: "14px", md: "16px", lg: "16px" },
                      }}
                    >
                      Password
                    </TableCell>
                    <TableCell
                      sx={{
                        pl: 2,
                        color: "black",
                        fontSize: { xs: "14px", md: "16px", lg: "16px" },
                      }}
                    >
                      ***********
                      {!isEdit ? (
                        <Button
                          variant="contained"
                          sx={{
                            color: "black",
                            backgroundColor: "#0957DD",
                            cursor: "pointer",
                            ml: 2,
                            minWidth: "56px!important",
                          }}
                          onClick={() => {
                            setResetPasswordOpen(true);
                          }}
                        >
                          <EditIcon />
                        </Button>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Form>
        </div>
      </Grid>
      <div>
        <Form onSubmit={handleSubmit}></Form>
      </div>
    </>
  );
}
