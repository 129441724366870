import { Box } from "@mui/system";
import React from "react";
import CategoryList from "./CategoryList";

const Category = () => {
  return (
    <Box>
      <CategoryList />
    </Box>
  );
};

export default Category;
